const listeners = {};
let listenerId = 0;


function offTransitionEnd(id) {
  if (!listeners[id]) {
    return;
  }

  const listener = listeners[id];
  const { timeoutT, node, done } = listener;
  clearTimeout(timeoutT);
  node && node.removeEventListener('transitionend', done);
  delete listeners[id];
}

function onTransitionEnd(node, cb, dur = 1000) {
  const id = listenerId++; // eslint-disable-line

  let isDone = false;

  function done() {
    if (isDone) {
      return;
    }
    isDone = true;
    cb();
    offTransitionEnd(id);
  }
  
  node.addEventListener('transitionend', done);
  const timeoutT = setTimeout(done, dur);

  listeners[id] = { timeoutT, node, done };
  return id;
}


export { onTransitionEnd, offTransitionEnd };
