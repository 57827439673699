import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon';

class Action extends React.Component {
  static propTypes = {
    prefixClass: PropTypes.string,
    disabled: PropTypes.bool,
    onAction: PropTypes.func,
    text: PropTypes.array
  };

  static defaultProps = {
    text: ['', '']
  };

  render() {
    const { prefixClass, text, disabled, onAction, selectedKeys } = this.props;

    const prefixClassName = `${prefixClass}__action`;
    const className = classNames(prefixClassName);

    const joinDisabled = disabled || !selectedKeys[0].length;
    const removeDisable = disabled || !selectedKeys[1].length;

    const joinBtnClass = classNames(`${prefixClassName}_button`, {
      [`${prefixClassName}_button--disabled`]: joinDisabled
    });

    const removeBtnClass = classNames(`${prefixClassName}_button`, {
      [`${prefixClassName}_button--disabled`]: removeDisable
    });

    return (
      <div className={className}>
        <div className={joinBtnClass} onClick={joinDisabled ? undefined : () => onAction('join')}>
          <Icon type="right" />
          {text[0] && <span>{text[0]}</span>}
        </div>
        <div className={removeBtnClass} onClick={removeDisable ? undefined : () => onAction('remove')}>
          <Icon type="left" />
          {text[1] && <span>{text[1]}</span>}
        </div>
      </div>
    );
  }
}

export default Action;
