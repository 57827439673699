import fade from './fade';
import translate from './translate';
import slidedown from './slidedown';
import classname from './classname';

const typeFactories = {};

function getAnimateTypeFactory(name) {
  return typeFactories[name];
}

function addAnimateType(name, typeFactory) {
  typeFactories[name] = typeFactory;
}

function parseDefaultParams(params = {}) {
  const defaultParams = { enter: {}, exit: {} };
  if (params.enter) {
    defaultParams.enter = params.enter;
    defaultParams.exit = params.exit;
  } else {
    defaultParams.enter = params || {};
    defaultParams.exit = params || {};
  }
  return defaultParams;
}

function getParams(defaultParams, customParams, step = 'enter') {
  const base = { dur: 200, easing: 'ease-in-out' };
  

  const defaults = parseDefaultParams(defaultParams)[step];
  const params = { ...base, ...defaults, ...customParams };

  params.dur = params.dur < 10 ? params.dur * 1000 : params.dur;
  return params;
}


function getAnimateTypeAndParams(type) {
  let typeObj = null;

  function getAnimateTypeObj({
    enterFactory,
    exitFactory,
    enterParams,
    exitParams
  }) {
    if (!enterFactory && !exitFactory) {
      return null;
    }

    if (enterFactory ? !exitFactory : exitFactory) {
      // xor
      exitFactory = enterFactory || exitFactory;
      enterFactory = exitFactory;
    }

    const enterType = enterFactory();
    const exitType = enterFactory === exitFactory ? enterType : exitFactory();

    const aniTypeObj = {
      enter: {
        type: enterType,
        params: enterParams || {}
      },
      exit: {
        type: exitType,
        params: exitParams || {}
      }
    };

    aniTypeObj.enter.params = getParams(aniTypeObj.enter.type.defaultParams, aniTypeObj.enter.params, 'enter');
    aniTypeObj.exit.params = getParams(aniTypeObj.exit.type.defaultParams, aniTypeObj.exit.params, 'exit');
    return aniTypeObj;
  }

  if (typeof type === 'string') {
    const animateTypeFactory = getAnimateTypeFactory(type);
    typeObj = getAnimateTypeObj({
      enterFactory: animateTypeFactory,
      exitFactory: animateTypeFactory
    });
  } else if (typeof type === 'object' && !type.enter) {
    const animateTypeFactory = getAnimateTypeFactory(type.name);

    typeObj = getAnimateTypeObj({
      enterFactory: animateTypeFactory,
      exitFactory: animateTypeFactory,
      enterParams: type.params,
      exitParams: type.params
    });
  } else if (
    typeof type === 'object' && 
    typeof type.enter === 'object' && 
    typeof type.exit === 'object'
  ) {
    const enterFactory = getAnimateTypeFactory(type.enter.name);
    const exitFactory = getAnimateTypeFactory(type.exit.name);

    typeObj = getAnimateTypeObj({
      enterFactory,
      exitFactory,
      enterParams: type.enter.params,
      exitParams: type.exit.params
    });
  }
  return typeObj;
}

addAnimateType('fade', fade);
addAnimateType('translate', translate);
addAnimateType('slidedown', slidedown);
addAnimateType('class', classname);

export { getAnimateTypeAndParams, addAnimateType };
