import ResizeObserver from 'resize-observer-polyfill';

const isServer = typeof window === 'undefined';

const resizeHandler = function(entries) {
  for (const entry of entries) {
    const listeners = entry.target.__resizeListeners__ || [];
    if (listeners.length) {
      listeners.forEach(fn => {
        fn();
      });
    }
  }
};

export const addResizeListener = function(el, callback) {
  if (isServer) return;
  if (!el.__resizeListeners__) {
    el.__resizeListeners__ = [];
    el.__ro__ = new ResizeObserver(resizeHandler);
    el.__ro__.observe(el);
  }
  el.__resizeListeners__.push(callback);
};

export const removeResizeListener = function(el, callback) {
  if (!el || !el.__resizeListeners__) return;
  el.__resizeListeners__.splice(el.__resizeListeners__.indexOf(callback), 1);
  if (!el.__resizeListeners__.length) {
    el.__ro__.disconnect();
  }
};
