import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Item from './item';

const Year = ({ years, onClick, onMouseEnter, className }) => (
  <table className={classNames('ten-date-year', className)}>
    <tbody>
      {
        years.map((year, i) => (
          <tr key={i}>
            {
              year.map((y, j) => (
                <Item 
                  className="ten-date-year__item"
                  key={j}
                  {...y}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                />
              ))
            }
          </tr>
        ))
      }
    </tbody>
  </table>
);

Year.propTypes = {
  years: PropTypes.array,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

Year.defaultProps = {
  years: [],
  onClick() {},
  onMouseEnter() {}
};

export default Year;