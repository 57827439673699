export default {
  confirm: '确定',
  cancel: '取消',
  noData: '暂无数据',
  datePicker: {
    weeks: [
      '日',
      '一',
      '二',
      '三',
      '四',
      '五',
      '六'
    ],
    months: [
      '1 月',
      '2 月',
      '3 月',
      '4 月',
      '5 月',
      '6 月',
      '7 月',
      '8 月',
      '9 月',
      '10 月',
      '11 月',
      '12 月'
    ],
    to: '至',
    start: '开始{type}',
    end: '结束{type}',
    pleaseSelect: '请选择{type}',
    year: '年',
    month: '月',
    day: '日',
    yearName: '年份',
    monthName: '月份',
    dayName: '日期',
    yearTitle: '{year}年',
    dateTimeFormatter: 'YYYY 年 MM 月 DD 日',
    selectDate: '选择日期',
    selectTime: '选择时间'
  },
  timePicker: {
    pleaseSelect: '请选择时间',
    start: '开始时间',
    end: '结束时间',
  },
  transfer: {
    searchPlaceholder: '请输入搜索内容',
    source: '源列表',
    target: '目的列表',
    count: '条'
  }
};
