import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../checkbox';
// import Icon from '../icon';

class Thead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static contextTypes = {
    setTheadRenderedFunc: PropTypes.func
  };

  componentDidMount() {
    this.renderComplete();
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.renderComplete();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    if (
      'getThead' in this.props &&
      (prevProps.columns !== this.props.columns ||
        (this.height && this.height !== this.thead.clientHeight))
    ) {
      this.renderComplete();
    }
  }

  height;

  renderComplete = () => {
    setTimeout(() => {
      const { getThead } = this.props;
      if (!getThead) return;
      const { setTheadRenderedFunc } = this.context;
      const thead = this.thead;
      setTheadRenderedFunc(null);
      if (!thead || thead.clientHeight === 0) {
        setTheadRenderedFunc(this.renderComplete);
        return;
      }
      this.height = thead.clientHeight;
      getThead(thead);
    }, 0);
  };

  getTh = (item, index) => {
    const { title, sorter: propSorter, onSort } = item;
    const {
      prefixClassName,
      sorter: { order: propOrder, column } = {},
      onSorterHandler,
      colWidths = [],
      headerProps,
      theadHeight
    } = this.props;

    const width = colWidths[index] || item.width;

    const base = ({ classNames: proClassNames, children, onClick } = {}) => {
      const thStyles = {
        ...(width && { width }),
        ...(theadHeight && { height: theadHeight })
      };
      return (
        <th
          key={index}
          className={proClassNames}
          onClick={onClick}
          style={thStyles}
          {...headerProps(item)}
        >
          <span className={`${prefixClassName}-thead-title`}>{title}</span>
          {children}
        </th>
      );
    };

    if (propSorter || onSort) {
      const preClassNames = `${prefixClassName}-thead-sorter`;
      const order =
        'order' in item ? item.order : column.dataKey === item.dataKey && propOrder;
      const sortClassNames = classNames(preClassNames, {
        [`${preClassNames}--asc`]: order === 'asc',
        [`${preClassNames}--desc`]: order === 'desc'
      });
      const sorter = (
        <span className={sortClassNames}>
          <div className={`${preClassNames}-inner`}>
            <i className={`${preClassNames}-up`} />
            <i className={`${preClassNames}-down`} />
          </div>
        </span>
      );
      const props = {
        classNames: `${prefixClassName}-thead-th-sorter`,
        children: sorter,
        onClick: () => onSorterHandler(item)
      };
      return base(props);
    }

    return base();
  };

  getThead = () => {
    const {
      columns,
      selector = {},
      rowKey,
      ALL_CHECK_KEY,
      selectKeys,
      data,
      onChangeCheckbox,
      prefixClassName,
      selectable
    } = this.props;

    const ths = columns.map((item, index) => this.getTh(item, index));
    
    if (Object.keys(selector).length > 0) {
      const allKeys = data.filter((item, index) => selectable(index, item)).map(item => item[rowKey]);
      const checked = allKeys.length && allKeys.every(key => selectKeys.indexOf(key) !== -1);
      const indeterminate = allKeys.length && selectKeys.some(key => allKeys.indexOf(key) !== -1);
      const isAllDisabled = !allKeys.length;

      let disabled = isAllDisabled;
      if ('disabledSelectAll' in selector) {
        disabled = selector.disabledSelectAll;
      }

      ths.unshift(<th key="all-checkbox">
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            disabled={disabled}
            onChange={e => onChangeCheckbox(e.target.checked, ALL_CHECK_KEY)}
          />
        </th>);
    }

    return (
      <thead
        className={`${prefixClassName}-thead`}
        ref={n => {
          this.thead = n;
        }}
      >
        <tr>{ths}</tr>
      </thead>
    );
  };

  render() {
    const thead = this.getThead();

    return thead;
  }
}

export default Thead;
