import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Day from '../basic/day';
import Header from '../basic/header';
import Month from '../basic/month';
import Year from '../basic/year';

import {
  getWeeks,
  getYears,
  getMonths,
  flagActive,
  subtractMonth,
  addMonth,
  isSame,
  getToday,
  firstUpperCase,
  setDateTime
} from '../util';

const LEFT = 'left';
const RIGHT = 'right';

let isFirstClick = true;

class DateRangePicker extends React.Component {

  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    mode: PropTypes.oneOf(['year', 'month', 'date']),
    onChange: PropTypes.func
  };

  static defaultProps = {
    mode: 'date',
    onChange() { }
  };

  static getDerivedStateFromProps(props) {
    const { value } = props;

    let [startValue, endValue] = value;

    if (!isFirstClick) return null;

    if ((startValue && endValue)) {
      return { startValue, endValue };
    }

    if (!startValue) {
      startValue = getToday();
    }

    if (!endValue) {
      endValue = startValue;
    }

    return { startValue, endValue };
  }

  constructor(props) {
    super(props);

    let [startValue, endValue] = props.value;


    if (!startValue) {
      startValue = getToday();
    }

    if (!endValue) {
      endValue = startValue;
    }

    const leftYear = startValue.getFullYear();
    const leftMonth = startValue.getMonth();
    let rightMonth = endValue.getMonth();
    let rightYear = endValue.getFullYear();

    if (props.mode === 'date' && isSame(startValue, endValue, 'month')) {
      const next = addMonth(endValue, 1);
      rightMonth = next.getMonth();
      rightYear = next.getFullYear();
    }

    if (props.mode === 'month' && isSame(startValue, endValue, 'year')) {
      rightYear = leftYear + 1;
    }

    if (props.mode === 'year' && isSame(startValue, endValue, 'year')) {
      rightYear = leftYear + 10;
    }

    this.state = {
      leftYear,
      leftMonth,
      rightYear,
      rightMonth,
      startValue,
      endValue,
      leftType: props.mode,
      rightType: props.mode
    };

    isFirstClick = true;
    this.firstClickValue = null;
  }

  clickHeader = (target, action, type) => {

    const attrStr = [`${type}${firstUpperCase(target)}`];

    if (target === 'year' && action === 'prev') {
      this.setState(state => ({ [attrStr]: state[attrStr] - 1 }));
    }

    if (target === 'year' && action === 'next') {
      this.setState(state => ({ [attrStr]: state[attrStr] + 1 }));
    }

    if (target === 'month' && action === 'prev') {
      this.setState(state => {
        const prev = subtractMonth(new Date(state[`${type}Year`], state[attrStr]), 1);
        return { [`${type}Year`]: prev.getFullYear(), [attrStr]: prev.getMonth() };
      });
    }

    if (target === 'month' && action === 'next') {
      this.setState(state => {
        const next = addMonth(new Date(state[`${type}Year`], state[attrStr]), 1);
        return { [`${type}Year`]: next.getFullYear(), [attrStr]: next.getMonth() };
      });
    }

  }

  clickDate = (date) => {
    const { onChange } = this.props;

    const { startValue, endValue } = this.state;

    if (isFirstClick) {
      this.setState({ startValue: date, endValue: date });
      isFirstClick = false;
      this.firstClickValue = date;
    } else {
      onChange && onChange([startValue, setDateTime(endValue, 23, 59, 59)]);
      isFirstClick = true;
    }

  }

  clickYear = (date, type) => {
    const { onChange, mode } = this.props;

    const { startValue, endValue } = this.state;

    if (mode === 'year') {
      if (isFirstClick) {
        this.setState({ startValue: date, endValue: date });
        isFirstClick = false;
        this.firstClickValue = date;
      } else {
        onChange && onChange([startValue, endValue]);
        isFirstClick = true;
      }
    } else {
      this.setState({ [`${type}Type`]: 'month', [`${type}Year`]: date.getFullYear() });
    }
  }

  clickMonth = (date, type) => {
    const { onChange, mode } = this.props;

    const { startValue, endValue } = this.state;

    if (mode === 'month') {
      if (isFirstClick) {
        this.setState({ startValue: date, endValue: date });
        isFirstClick = false;
        this.firstClickValue = date;
      } else {
        onChange && onChange([startValue, endValue]);
        isFirstClick = true;
      }
    } else {
      this.setState({ 
        [`${type}Type`]: 'date', 
        [`${type}Year`]: date.getFullYear(),
        [`${type}Month`]: date.getMonth()
      });
    }
  }

  onHeaderClick(target, action, type) {
    const { unlinkPanels } = this.props;
    const types = unlinkPanels ? [type] : [LEFT, RIGHT];
    types.forEach(t => this.clickHeader(target, action, t));
  }

  onMouseEnter = (date) => {

    if (isFirstClick) {
      return;
    }

    if (this.firstClickValue.getTime() > date.getTime()) {
      this.setState({ startValue: date, endValue: this.firstClickValue });
    } else {
      this.setState({ startValue: this.firstClickValue, endValue: date });
    }
  }

  onTypeChange = (type, pos) => {
    this.setState({ [`${pos}Type`]: type, startValue: this.firstClickValue, endValue: this.firstClickValue });
    isFirstClick = true;
  }

  render() {

    const { value, onChange, mode, maxDate, minDate, firstDayOfWeek, disabledDate, unlinkPanels, ...others } = this.props;

    const {
      leftYear,
      leftMonth,
      rightYear,
      rightMonth,
      startValue: start,
      endValue: end,
      leftType,
      rightType } = this.state;

    const options = {
      maxDate,
      minDate,
      firstDayOfWeek,
      disabledDate
    };

    const leftWeeks = flagActive(getWeeks({ year: leftYear, month: leftMonth }, options), { start, end });
    const leftMonths = flagActive(getMonths(leftYear, options), { start, end, type: 'month' });
    const leftYears = flagActive(getYears(leftYear, options), { start, end, type: 'year' });

    const rightWeeks = flagActive(getWeeks({ year: rightYear, month: rightMonth }, options), { start, end });
    const rightMonths = flagActive(getMonths(rightYear, options), { start, end, type: 'month' });
    const rightYears = flagActive(getYears(rightYear, options), { start, end, type: 'year' });

    return (
      <div className="ten-date-range" {...others}>

        <div className="ten-date">
          <Header
            year={leftYear}
            month={leftMonth}
            type={leftType}
            onPrevOrNext={(target, action) => this.onHeaderClick(target, action, LEFT)}
            onTypeChange={type => this.onTypeChange(type, LEFT)}
            disableNextMonth={leftType !== 'date'}
            disablePrevMonth={leftType !== 'date'}
          />

          <Day
            className={classNames({
              'ten-date__sub-panel--hide': leftType !== 'date' 
            })}
            firstDayOfWeek={firstDayOfWeek}
            weeks={leftWeeks}
            onClick={this.clickDate}
            onMouseEnter={date => this.onMouseEnter(date, LEFT)}
          />

          <Year
            className={classNames({
              'ten-date__sub-panel--hide': leftType !== 'year' 
            })}
            years={leftYears}
            onClick={date => this.clickYear(date, LEFT)}
            onMouseEnter={date => this.onMouseEnter(date, LEFT)}
          />

          <Month
            className={classNames({
              'ten-date__sub-panel--hide': leftType !== 'month' 
            })}
            months={leftMonths}
            onClick={date => this.clickMonth(date, LEFT)}
            onMouseEnter={date => this.onMouseEnter(date, LEFT)}
          />
        </div>
        <div className="ten-date">
          <Header
            year={rightYear}
            month={rightMonth}
            type={rightType}
            onPrevOrNext={(target, action) => this.onHeaderClick(target, action, RIGHT)}
            onTypeChange={type => this.onTypeChange(type, RIGHT)}
            disablePrevMonth={rightType !== 'date'}
            disableNextMonth={rightType !== 'date'}
          />

          <Day
            className={classNames({
              'ten-date__sub-panel--hide': rightType !== 'date' 
            })}
            weeks={rightWeeks}
            firstDayOfWeek={firstDayOfWeek}
            onClick={this.clickDate}
            onMouseEnter={date => this.onMouseEnter(date, RIGHT)}
          />

          <Year
            className={classNames({
              'ten-date__sub-panel--hide': rightType !== 'year' 
            })}
            years={rightYears}
            onClick={date => this.clickYear(date, RIGHT)}
            onMouseEnter={date => this.onMouseEnter(date, RIGHT)}
          />

          <Month
            className={classNames({
              'ten-date__sub-panel--hide': rightType !== 'month' 
            })}
            months={rightMonths}
            onClick={date => this.clickMonth(date, RIGHT)}
            onMouseEnter={date => this.onMouseEnter(date, RIGHT)}
          />
        </div>
      </div>
    );
  }
}


export default DateRangePicker;
