/**
 * @author chazzhou
 */

import Input from './input';
import InputNumber from './input-number'; 
import InputTag from './input-tag';
import InputGroup from './input-group';
import InputSearch from './input-search';
import InputTextarea from './input-textarea';

Input.Number = InputNumber;
Input.Tag = InputTag;
Input.Group = InputGroup;
Input.Search = InputSearch;
Input.Textarea = InputTextarea;
export default Input;
