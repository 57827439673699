import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import Thead from './thead';
import Tbody from './tbody';

/**
 * @visibleName Table 表单
 */
class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBodyColgroup = () => {
    const { columns } = this.props;
    const cols = columns.map((col, i) => (
      <col key={i} style={{ width: col.width || undefined }} />
    ));
    return <colgroup>{cols}</colgroup>;
  };

  render() {
    const { props } = this;

    return (
      <table>
        {this.getBodyColgroup()}
        <Thead {...props} />
        <Tbody {...props} />
      </table>
    );
  }
}

export default Base;
