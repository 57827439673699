import React from 'react';
import ReactDOM from 'react-dom';
import Message from './message';

const instances = [];
let seed = 0;
let container;
let wrapper;
let config = {};

const message = function(props) {
  if (!container) {
    container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = 0;
    container.style.width = 0;
    container.style.zIndex = 99999;
    document.body.appendChild(container);
  }
  // 处理居中需求
  if (config.center && !wrapper) {
    wrapper = document.createElement('div');
    wrapper.style.position = 'fixed';
    wrapper.style.top = '50%';
    wrapper.style.transform = 'translateY(-50%)';
    wrapper.style.marginTop = '-7px';
    container.style.zIndex = 999999;
    container.appendChild(wrapper);
  }

  const div = document.createElement('div');

  seed += 1;
  const id = 'message__' + seed;
  const instance = {};
  
  const messageProps = {
    ...config,
    ...props,
    key: id,
    onClose: () => {
      message.close(id, props.onClose);
    },
    onAfterClose: () => {
      ReactDOM.unmountComponentAtNode(div);
      if (config.center) {
        wrapper && wrapper.removeChild(div);
        if (!instances.length && wrapper) {
          container.removeChild(wrapper);
          wrapper = null;
          container && document.body.removeChild(container);
          container = null;  
        }
      } else {
        container && container.removeChild(div);
        if (!instances.length && container) {
          document.body.removeChild(container);
          container = null;  
        }
      }
    }
  };
  if (config.center) {
    wrapper.appendChild(div);
  } else {
    container.appendChild(div);
  }
  instance.node = ReactDOM.render(<Message {...messageProps} />, div);
  instance.id = id;
  instances.push(instance);

  return instance.node;
};

const methods = ['success', 'warning', 'info', 'error'];

methods.forEach(type => {
  message[type] = ((content, duration, onClose, options) => {
    if (typeof duration === 'function') {
      if (onClose) options = onClose;
      onClose = duration;
      duration = undefined;
    } else if (duration && typeof duration !== 'number') {
      options = duration;
      duration = undefined;
    }
    return message({ content, duration, onClose, type, ...options });
  });
});

message.close = function(id, userOnClose) {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i]);
      }
      instances.splice(i, 1);
      break;
    }
  }
};

message.closeAll = function() {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].node.close();
  }
};

message.config = function(options) {
  config = options;
};

export default message;
