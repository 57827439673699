import React from 'react';
import FileItem from './file-item';

export default function FileList(props) {
  const { list = [], onRemove, onPreview } = props;

  return (
    list.length ? (
      <ul className="ten-file-list">
        {
          list.map((item, idx) => (
            <FileItem
              file={item}
              key={item.key || idx}
              idx={idx}
              onRemove={onRemove}
              onPreview={onPreview}
            />
          ))
        }
      </ul>
    ) : null
  );
};
