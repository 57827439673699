import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SelectBox from './select-box';
import Action from './action';
import { t } from '../locale';
import { withConfig } from '../config';

/**
 * @visibleName Transfer 穿梭框
 */
class Transfer extends React.Component {
  static propTypes = {
    /**
     * 数据源列表
     */
    data: PropTypes.arrayOf(PropTypes.shape({
      /**
         * key值
         */
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
         * 显示的标题
         */
      title: PropTypes.string,
      /**
         * 是否禁用
         */
      disabled: PropTypes.bool
    })),
    /**
     * 加入到右侧的key值列表
     */
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    /**
     * 被选中的key值列表
     */
    selectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * 总数单位
     */
    unit: PropTypes.string,
    /**
     * 标题列表
     */
    titles: PropTypes.arrayOf(PropTypes.string),
    /**
     * 是否使用搜索框
     */
    searchFlag: PropTypes.bool,
    /**
     * 两边列表发生改变时触发回调方法
     */
    onChange: PropTypes.func,
    /**
     * 选项被选着时触发回调方法
     */
    onSelectChange: PropTypes.func
  };

  static defaultProps = {
    data: [],
    values: [],
    selectedKeys: [],
    disabled: false,
    searchFlag: false,
    // unit: '条',
    // titles: ['源列表', '目的列表']
  };

  constructor(props) {
    super(props);

    const { values, selectedKeys } = this.props;

    this.state = {
      searchValues: ['', ''],
      selectedKeys: [
        selectedKeys.filter((key) => !values.some((k) => k === key)),
        selectedKeys.filter((key) => values.some((k) => k === key))
      ]
    };
  }

  getData = () => {
    const { data, values } = this.props;

    this.data = [
      data.filter((item) => !values.some((key) => key === item.key)),
      data.filter((item) => values.some((key) => key === item.key))
    ];
    return this.data;
  };

  onAction = (mode) => {
    const { onChange, values, onSelectChange } = this.props;
    const { selectedKeys } = this.state;
    let valueKeys;
    let moveKeys;
    if (mode === 'join') {
      moveKeys = selectedKeys[0];
      valueKeys = moveKeys.concat(this.data[1].map((item) => item.key));
      selectedKeys[0] = [];
    } else {
      moveKeys = selectedKeys[1];
      valueKeys = values.filter((key) => !moveKeys.some((k) => k === key));
      selectedKeys[1] = [];
    }

    this.setState({
      selectedKeys
    });

    onSelectChange && onSelectChange(selectedKeys[0], selectedKeys[1]);

    onChange && onChange(valueKeys, mode, moveKeys);
  };

  onSelect = (index, { key }) => {
    const { selectedKeys } = this.state;
    const { onSelectChange } = this.props;
    const currentSelectKeys = selectedKeys[index];
    const keyIndex = currentSelectKeys.findIndex((k) => k === key);
    keyIndex === -1 ? currentSelectKeys.push(key) : currentSelectKeys.splice(keyIndex, 1);
    selectedKeys[index] = currentSelectKeys;

    this.setState({
      selectedKeys
    });

    onSelectChange && onSelectChange(selectedKeys[0], selectedKeys[1]);
  };

  onSelectAll = (index) => {
    const { selectedKeys, searchValues } = this.state;
    const { onSelectChange } = this.props;
    const currentSelectKeys = selectedKeys[index];
    const searchValue = searchValues[index];

    const keys = this.data[index]
      .filter((item) => !item.disabled && (!searchValue || item.title.indexOf(searchValue) !== -1))
      .map((item) => item.key);

    if (currentSelectKeys.every((key) => keys.some((k) => k === key)) && currentSelectKeys.length === keys.length) {
      selectedKeys[index] = [];
    } else {
      selectedKeys[index] = keys;
    }

    this.setState({
      selectedKeys
    });

    onSelectChange && onSelectChange(selectedKeys[0], selectedKeys[1]);
  };

  onSearch = (index, e) => {
    const { searchValues } = this.state;
    searchValues[index] = e.target.value;
    this.setState({ searchValues });
  };

  render() {
    const { className: propClassName, style, disabled, titles = [t('transfer.source'), t('transfer.target')], unit = t('transfer.count'), searchFlag } = this.props;
    const { searchValues, selectedKeys } = this.state;

    const data = this.getData();

    const prefixClassName = 'ten-transfer';
    const className = classNames(propClassName, prefixClassName);

    const selectBoxProps = {
      disabled,
      prefixClass: prefixClassName,
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll,
      onSearch: this.onSearch,
      unit,
      searchFlag
    };

    const beforeSelectBoxProps = {
      ...selectBoxProps,
      index: 0,
      data: data[0],
      selectKeys: selectedKeys[0],
      title: titles[0],
      searchValue: searchValues[0]
    };

    const afterSelectBoxProps = {
      ...selectBoxProps,
      index: 1,
      data: data[1],
      selectKeys: selectedKeys[1],
      title: titles[1],
      searchValue: searchValues[1]
    };

    const actionProps = {
      disabled,
      prefixClass: prefixClassName,
      onAction: this.onAction,
      selectedKeys
    };

    return (
      <div className={className} style={style}>
        <SelectBox {...beforeSelectBoxProps} />
        <Action {...actionProps} />
        <SelectBox {...afterSelectBoxProps} />
      </div>
    );
  }
}

export default withConfig(Transfer);
