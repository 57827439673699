import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon';
import { t } from '../locale';

class SelectSearch extends React.Component {
  static propTypes = {
    prefixClass: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
  };

  render() {
    const { prefixClass, value, disabled, onChange, placeholder = t('transfer.searchPlaceholder') } = this.props;

    const prefixClassName = `${prefixClass}__search`;
    const className = classNames(prefixClassName, {
      [`${prefixClassName}--disabled`]: disabled
    });
    const iconClassName = classNames(`${prefixClassName}-icon-search`, {
      [`${prefixClassName}-icon--hide`]: value
    });
    

    return (
      <div className={className}>
        <input type="text" placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} />
        <span className={iconClassName}>
          <Icon type="zoom" />
        </span>
      </div>
    );
  }
}

export default SelectSearch;
