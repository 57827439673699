import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';
import Animate from '../animate';

const ICON_TYPE_MAP = {
  'error': 'circle_clear',
  'success': 'circle_tick',
  'warning': 'circle_error',
  'info': 'circle_info'
};

class Message extends React.Component {

  static propTypes = {
    /**
     * 消息内容
    */
    content: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.node
    ]),
    /**
     * 消息类型
    */
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
    /**
     * 自动关闭的延时， 单位毫秒
    */
    duration: PropTypes.number,
    /**
     * 自定义图标
    */
    icon: PropTypes.node,
    /**
     * 关闭时触发的回调函数
    */
    onClose: PropTypes.func,
    /**
     * 显示关闭按钮
    */
    showClose: PropTypes.bool,
    /**
     * 暗背景模式
    */
    dark: PropTypes.bool,
    /**
     * 是否显示在中间
     */
    center: PropTypes.bool
  };
  
  static defaultProps = {
    showClose: false,
    dark: false,
    center: false,
    duration: 3000
  };

  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      showMessage: true
    };
    this.mounted = true;
  }

  componentDidMount() {
    this.mounted = true;
    this.startTimer();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  close = () => {
    if (this.mounted) {
      this.setState({ showMessage: false });
    }
    this.props.onClose && this.props.onClose();
  }

  startTimer = () => {
    const { duration } = this.props;
    if (duration > 0) {
      this.timer = setTimeout(() => {
        if (this.state.showMessage) {
          this.clearTimer();
          this.close();
        }
      }, duration);
    }
  }

  clearTimer = () => {
    clearTimeout(this.timer);
  }

  render() {
    const { showMessage } = this.state;
    const { type, content, showClose, onAfterClose, icon, dark, className, style } = this.props;

    const mergedClassNames = classNames('ten-message', className, {
      'ten-message--dark': dark,
      'ten-message--success': type === 'success',
      'ten-message--error': type === 'error',
      'ten-message--info': type === 'info',
      'ten-message--warning': type === 'warning',
    });

    return (
      <Animate type="fade" onExited={onAfterClose} appear>
        {
          showMessage &&
          (
            <div
              className={mergedClassNames}
              style={style}
              onMouseEnter={this.clearTimer}
              onMouseLeave={this.startTimer}
            >
              {icon || <Icon className="ten-message__icon" type={ICON_TYPE_MAP[type]} />}
              <div className="ten-message__content">{content}</div>
              {showClose && <Icon className="ten-message__icon ten-message__close" type="clear" onClick={this.close} />}
            </div>
          )
        }
      </Animate>
    );
  }
}

export default Message;
