import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Popup from '../popup';

/**
 * @visibleName Dropdown 下拉框
 */
class Dropdown extends React.Component {

  static propTypes = {
    /**
     * 必须提供且仅提供一个 children 作为触发元素
     */
    children: PropTypes.node.isRequired,
    /**
     * 列表数据
     */
    data: PropTypes.array.isRequired,
    /**
     * 定义如何渲染列表数据，参数为 data 的一项
     */
    renderItem: PropTypes.func.isRequired,
    /**
     * 选项点击回调
     * @param key
     * @param itemData
     */
    onItemClick: PropTypes.func,
    /**
     * 选项点击后是否隐藏 dropdown
     */
    hideAfterItemClick: PropTypes.bool,
    /**
     * 定义如何获取每一项的 key 值
     */
    getItemKey: PropTypes.func,
    /**
     * 定义选中项（如果需要选中功能）
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    /**
     * 触发方式
     */
    triggerType: PropTypes.oneOf(['hover', 'click', 'manual']),
    /**
     * 手动控制显示
     */
    show: PropTypes.bool,
  };

  static defaultProps = {
    triggerType: 'click',
    hideAfterItemClick: true
  };

  static getDerivedStateFromProps(nextProps) {
    if ('show' in nextProps) {
      return {
        popupShow: nextProps.show,
      };
    }
    return null;
  }

  state = {
    popupShow: false,
  };


  onChildClick = (e) => {

    const { children } = this.props;
    const { onClick: childOnClick } = children.props;

    childOnClick && childOnClick(e);
    this.changeVisibility(true);
  }

  onItemClick = (itemData) => {
    const { onItemClick, hideAfterItemClick } = this.props;

    if (!('show' in this.props) && hideAfterItemClick) {
      this.changeVisibility(false);
    }
    onItemClick && onItemClick(itemData);
  }

  onPopupShow = () => {
    const { onShow } = this.props;
    if ('show' in this.props) {
      onShow && onShow();
      return;
    }
    this.changeVisibility(true);
  }

  onPopupHide = () => {
    const { onHide } = this.props;
    if ('show' in this.props) {
      onHide && onHide();
      return;
    }
    this.changeVisibility(false);
  }

  changeVisibility(show) {
    this.setState({
      popupShow: show
    });
  }

  renderList() {
    const { data, renderItem, getItemKey } = this.props;

    return (
      <div className="ten-dropdown__content">
        {
          data.map((item, index) => {
            const key = getItemKey ? getItemKey(item) : index;
            const className = classNames('ten-dropdown__content-item');
            return (
              <div
                key={key}
                className={className}
                onClick={() => this.onItemClick(item)} 
              >
                {
                  renderItem(item)
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  render() {
    const {
      children: propChildren,
      className: propClassName,
      onClick,
      renderItem,
      data,
      getItemKey,
      onShow,
      onHide,
      hideAfterItemClick,
      show,
      triggerType,
      onVisibleChange,
      onItemClick,
      ...others
    } = this.props;
    const { popupShow } = this.state;
    const list = this.renderList();
    const className = classNames('ten-dropdown ten-dropdown__popup', propClassName);

    const children = React.cloneElement(propChildren, {
      onClick: this.onChildClick
    });
    return (
      <Popup
        className={className}
        content={list}
        direction="bottom left"
        triggerType={triggerType}
        onShow={this.onPopupShow}
        onHide={this.onPopupHide}
        show={popupShow}
        {...others}
      >
        {children}
      </Popup>
    );
  }
}

export default Dropdown;
