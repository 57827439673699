import React from 'react';
import Progress from '../progress';
import Icon from '../icon';
import classNames from 'classnames';

const FILE_STATUS = {
  ERROR: 'error',
  DONE: 'done',
  LOADING: 'loading',
};

const STATUS_ICON = {
  ERROR: 'circle_clear',
  DONE: 'circle_tick',
  LOADING: 'loading_gradient',
};

export default function FileItem(props) {
  const { file, onRemove, onPreview, idx } = props;
  const {
    name,
    progress = 0,
    status = FILE_STATUS.DONE
  } = file;
  const prefixClassName = 'ten-file-item';

  const statusIcon = STATUS_ICON[status ? status.toUpperCase() : FILE_STATUS.DONE];

  return (
    <li className={prefixClassName}>
      <div className={`${prefixClassName}__info`}>
        <div
          className={`${prefixClassName}__info-filename`}
          onClick={() => onPreview(file)}
        >
          {name}
        </div>
        <Icon
          type={statusIcon}
          className={classNames(`${prefixClassName}__info-status-icon`, {
            [`${prefixClassName}__info-status-icon--loading`]: status === FILE_STATUS.LOADING,
            [`${prefixClassName}__info-status-icon--error`]: status === FILE_STATUS.ERROR,
            [`${prefixClassName}__info-status-icon--done`]: status === FILE_STATUS.DONE,
            [`${prefixClassName}__info-status-icon--ready`]: status === FILE_STATUS.READY,
          })}
        />
        <Icon
          type="circle_clear"
          className={`${prefixClassName}__info-close-icon`}
          onClick={() => onRemove(file, idx)}
        />
      </div>
      {
        status === FILE_STATUS.LOADING ?
          (<Progress
            className={`${prefixClassName}__progress`}
            inline={false}
            value={progress}
          />) : null
      }
    </li>
  );
}