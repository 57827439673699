import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Item = (props) => (
  <td
    className={classNames('ten-date-item', {
      'ten-date-item--active': props.active,
      'ten-date-item--disabled': props.disabled,
      'ten-date-item--highlight': props.highlight, 
      'ten-date-item--active-start': props.startOfRange,
      'ten-date-item--active-end': props.endOfRange,
      'ten-date-item--additional': props.additional,
      'ten-date-item--now': props.now,
      'ten-date-item--first-day-of-month': props.firstDayOfMonth,
      'ten-date-item--last-day-of-month': props.lastDayOfMonth
    }, props.className)}
  >
    <div
      className="ten-date-item__wrapper"
      onClick={() => {
        if (!props.disabled) {
          props.onClick(props.value);
        }
      }}
      onMouseEnter={() => {
        props.onMouseEnter(props.value);
      }}
    >
      <span className="ten-date-item__text">{props.text}</span>
    </div>
  </td>
);

Item.propTypes = {
  value: PropTypes.instanceOf(Date),
  text: PropTypes.string,
  active: PropTypes.bool,
  highlight: PropTypes.bool,
  disabled: PropTypes.bool,
  start: PropTypes.bool,
  end: PropTypes.bool,
  additional: PropTypes.bool,
  now: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

Item.defaultProps = {
  text: '',
  active: false,
  highlight: false,
  disabled: false,
  start: false,
  end: false,
  additional: false,
  now: false,
  onClick() {},
  onMouseEnter() {}
};

export default Item;
