import React from 'react';
import PropTypes from 'prop-types';
import Time from './time';
import { compareTime, cloneTime, isSame } from '../util';

class TimeRange extends React.Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    format: PropTypes.string,
    onChange: PropTypes.func,
    relativetimeinput: PropTypes.object,
    isLinkage: PropTypes.bool
  };

  static defaultProps = {
    isLinkage: true
  }

  static getDerivedStateFromProps(nextProps) {
    const { value } = nextProps;

    if (value[0] && value[1]) {
      return { isSameDay: isSame(value[0], value[1]) };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  onChangeHandler = (newValue, type) => {
    const { onChange, value } = this.props;
    const [start, end] = value;

    switch (type) {
      case 'start':
        if (end && compareTime(newValue, end) >= 0 && this.state.isSameDay) {
          onChange && onChange([newValue, cloneTime(newValue, end)]);
        } else {
          onChange && onChange([newValue, end || '']);
        }
        break;
      case 'end':
        onChange && onChange([start || '', newValue]);
        break;
      default:
        break;
    }
  }
  
  render() {
    const { 
      format,
      value,
      relativetimeinput,
      onChange,
      disabledHour,
      disabledMin,
      disabledSec,
      maxTime,
      minTime,
      isLinkage,
      unlinkPanels,

      firstDayOfWeek,
      minDate,
      maxDate,
    
      ...others } = this.props;

    const [start, end] = value;

    return (
      <div className="ten-time-range" {...others}>
        <Time 
          value={start} 
          format={format}
          disabledHour={disabledHour}
          disabledMin={disabledMin}
          disabledSec={disabledSec}
          maxTime={maxTime}
          minTime={minTime}
          onChange={(v) => {
            this.onChangeHandler(v, 'start'); 
          }}
          relativetimeinput={relativetimeinput.startTimeInput}
        />
        <Time 
          value={end} 
          minTime={isLinkage || (!isLinkage && this.state.isSameDay) ? start : undefined}
          format={format}
          disabledHour={disabledHour}
          disabledMin={disabledMin}
          disabledSec={disabledSec}
          onChange={(v) => {
            this.onChangeHandler(v, 'end'); 
          }}
          relativetimeinput={relativetimeinput.endTimeInput}
        />
      </div>
    );
  }
}

export default TimeRange;
