import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Popup from '../popup';

/**
 * @visibleName Tooltip 提示
 */
class Tooltip extends React.Component {

  static propTypes = {
    /**
     * 提示文本
     */
    message: PropTypes.node,
    /**
     * 额外内容，比如底部链接
     */
    extra: PropTypes.node,
    /**
     * 暗色模式
     */
    dark: PropTypes.bool
  };

  static defaultTypes = {
    dark: false
  };

  render() {
    const { className: propClassName, message, extra, dark, content: propContent, ...others } = this.props;
    const className = classNames('ten-tooltip', propClassName, {
      'ten-tooltip--dark': dark
    });
    const content = (
      <div className="ten-tooltip-content">
        {
          propContent || [
            <div key="message" className="ten-tooltip__message">{message}</div>,
            (extra ?
              <div key="extra" className="ten-tooltip__extra">{extra}</div> :
              null)
          ]
        }

      </div>
    );

    return (
      <Popup className={className} content={content} type="bubble" {...others} />
    );
  }
}

export default Tooltip;
