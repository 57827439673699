import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox';

class SelectItem extends React.Component {
  static propTypes = {
    prefixClass: PropTypes.string,
    item: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
  };

  render() {
    const { prefixClass, item, disabled: propDisabled, onChange, checked } = this.props;

    const disabled = propDisabled || item.disabled;

    const prefixClassName = `${prefixClass}-item`;
    const className = classNames(prefixClassName, {
      [`${prefixClassName}--disabled`]: disabled
    });

    return (
      <li className={className}>
        <Checkbox disabled={disabled} checked={checked} onChange={disabled ? undefined : () => onChange(item)}>
          <span className={`${prefixClassName}-text`}>{item.title}</span>
        </Checkbox>
      </li>
    );
  }
}

export default SelectItem;
