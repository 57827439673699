import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @visibleName RadioGroup 单选组
 */
export default class RadioGroup extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    /**
     * 指定当前选中的 radio value
     */
    value: PropTypes.any.isRequired,
    button: PropTypes.bool
  };

  static childContextTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
    button: PropTypes.bool
  }

  getChildContext() {
    const context = {
      name: this.props.name,
      value: this.props.value,
      disabled: this.props.disabled,
      onChange: this.onChange
    };
    if ('button' in this.props) {
      context.button = this.props.button;
    }
    return context;
  }

  onChange = (e) => {
    const { disabled, onChange } = this.props;

    if (disabled) {
      return;
    }

    onChange && onChange(e);
  };

  render() {
    const { children, button } = this.props;
    const className = classNames('ten-radio-group', {
      'ten-radio-btn-group': button,
      'ten-button-group': button
    });

    return (
      <div className={className}>
        { children }
      </div>
    );

  }

}
