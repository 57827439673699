import domHelper from '../../utils/dom-helper';

export default function fade () {
  let aniT;

  const timeout = function(fn, dur) {
    clearTimeout(aniT);
    aniT = setTimeout(fn, dur);
  };

  const cancel = function() {
    clearTimeout(aniT);
  };

  return ({
    defaultParams: {
      dur: 200,
      easing: 'ease-in-out'
    },
    onEnter({ domNode }) {

      const style = {
        opacity: 0,
        transition: 'none'
      };

      domHelper.css(domNode, style);
    },

    onEntering({ domNode, params, done }) {
      const { dur, easing } = params;

      const style = {
        opacity: 1,
        transition: `opacity ${dur}ms ${easing}`
      };

      timeout(function () {
        domHelper.css(domNode, style);

        timeout(done, dur + 20);
      }, 0);
    },

    onEntered({ domNode }) {
      const style = {
        opacity: '',
        transition: ''
      };
      domHelper.css(domNode, style);
    },

    onExit({ domNode, params }) {
      const { dur, easing } = params;
      const style = {
        opacity: 1,
        transition: `opacity ${dur}ms ${easing}`
      };

      domHelper.css(domNode, style);
    },

    onExiting({ domNode, params, done }) {
      const { dur } = params;

      const style = {
        opacity: 0
      };

      timeout(function () {
        domHelper.css(domNode, style);

        timeout(done, dur);
      }, 0);
    },

    onExited({ domNode }) {
      const style = {
        transition: ''
      };
      domHelper.css(domNode, style);
    },

    onCancel() {
      cancel();
    }

  });
}
