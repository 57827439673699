import { onTransitionEnd, offTransitionEnd } from '../utils/transitionend';

export default function fade() {
  let aniT;

  function getClass(classes = 'ten-animate', hook) {
    if (typeof classes === 'string') {
      return classes + '-' + hook;
    }

    if (typeof classes === 'object') {
      return classes[hook];
    }

    return 'ten-animate-' + hook;
  }

  return ({
    defaultParams: {
      // classes: ''
      dur: 10000
    },

    onEnter({ domNode, params }) {
      domNode.classList.remove(
        getClass(params.classes, 'enter'),
        getClass(params.classes, 'entering'),
        getClass(params.classes, 'entered'),
        getClass(params.classes, 'exit'),
        getClass(params.classes, 'exiting'),
        getClass(params.classes, 'exited')
      );
      domNode.classList.add(getClass(params.classes, 'enter'));
    },

    onEntering({ domNode, params, done }) {
      const { dur } = params;
      setTimeout(() => {
        domNode.classList.add(getClass(params.classes, 'entering'));
        domNode.classList.remove(getClass(params.classes, 'enter'));
        offTransitionEnd(aniT);
        aniT = onTransitionEnd(domNode, done, dur);
      }, 10);
    },

    onEntered({ domNode, params }) {
      domNode.classList.add(getClass(params.classes, 'entered'));
      domNode.classList.remove(
        getClass(params.classes, 'enter'),
        getClass(params.classes, 'entering'),
        getClass(params.classes, 'entered')
      );
      offTransitionEnd(aniT);
    },

    onExit({ domNode, params }) {
      domNode.classList.add(getClass(params.classes, 'exit'));
    },

    onExiting({ domNode, params, done }) {
      const { dur } = params;

      setTimeout(() => {
        domNode.classList.add(getClass(params.classes, 'exiting'));
        domNode.classList.remove(getClass(params.classes, 'exit'));
        offTransitionEnd(aniT);
        aniT = onTransitionEnd(domNode, done, dur);
      }, 10);
    },

    onExited({ domNode, params }) {
      domNode.classList.add(getClass(params.classes, 'exited'));
      domNode.classList.remove(
        getClass(params.classes, 'exit'),
        getClass(params.classes, 'exiting'),
        getClass(params.classes, 'exited')
      );
      offTransitionEnd(aniT);
    },

    onCancel() {
      offTransitionEnd(aniT);
    }

  });
}
