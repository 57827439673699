import React from 'react';

let config = {
  locale: require('../locale/zh-CN').default
};
const listeners = [];

function set(options) {
  config = Object.assign({}, config, options);
  listeners.forEach(listener => listener());
}

function get(key) {
  if (key) {
    return config[key];
  }
  return config;
}

function addListener(listener) {
  if (listeners.indexOf(listener) === -1) {
    listeners.push(listener);
  }
}

function removeListener(listener) {
  const index = listeners.indexOf(listener);
  if (index > -1) {
    listeners.splice(index, 1);
  }
}

/* eslint-disable */
export function withConfig(Component) {

  class Config extends React.Component {
  
    componentDidMount() {
      addListener(this.onLocaleChange);
    }
  
    componentWillUnmount() {
      removeListener(this.onLocaleChange);
    }
  
    onLocaleChange = () => {
      this.forceUpdate();
    }
  
    render() {
      const { fRef, ...others } = this.props;
      return <Component ref={fRef} {...others}/*  tenConfig={config} *//>;
    }
  }

  return React.forwardRef((props, ref) => <Config { ...props } fRef={ref} />);
}

export default {
  set, get
};
