import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input from './input';
import Icon from '../icon';


class InputSearch extends React.Component {

  static propTypes = {
    /**
     * 搜索回调
     */
    onSearch: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ''
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.setState({
      value: e.target.value
    });
    onChange && onChange(e);
  }

  onKeyUp = (e) => {
    const { onKeyUp, disabled } = this.props;
    const keyCode = e.keyCode;

    if (disabled) {
      return;
    }

    if (keyCode === 13) {
      this.search();
    }

    onKeyUp && onKeyUp(e);
  }
  
  onSearchClick = () => {
    this.search();
  }
  
  search() {
    const { onSearch } = this.props;
    const { value } = this.state;
    onSearch && onSearch(value);
  }

  render() {
    const { className: propClassName, onSearch, onChange, ...others } = this.props;
    const { value } = this.state;
    const className = classNames(propClassName, 'ten-input-search');

    return (
      <Input
        className={className}
        type="text"
        value={value}
        {...others}
        suffixIcon={<Icon onClick={this.onSearchClick} type="zoom" />}
        onKeyUp={this.onKeyUp}
        onChange={this.onChange}
      />
    );
  }
}

export default InputSearch;
