import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';

/**
 * @visibleName Switch 开关
 */
class Switch extends React.Component {

  static propTypes = {
    /**
     * 初始是否选中
     */
    defaultChecked: PropTypes.bool,
    // defaultChecked
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * 选中时的内容
     */
    checkedVal: PropTypes.string,
    /**
     * 未选中时的内容
     */
    unCheckedVal: PropTypes.string,
    /**
     * 选中时的Icon
     */
    checkedIcon: PropTypes.string,
    /**
     * 未选中时的Icon
     */
    unCheckedIcon: PropTypes.string,
    /**
     * 点击回调函数
     */
    onClick: PropTypes.func,
    /**
     * 变化回调函数
     */
    onChange: PropTypes.func,
    /**
     * 当前是否选中
     */
    isChecked: PropTypes.bool,
  };
  
  static defaultProps = {
    disabled: false,
    defaultChecked: false,
  };
  
  constructor (props) {
    super(props);
    this.state = {
      checked: this.props.defaultChecked,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { isChecked } = nextProps;
    if (typeof (isChecked) === 'boolean') {
      return {
        checked: isChecked,
      };
    }
    return null;
  }

  onClick = (e) => {
    const { onClick } = this.props;
    this.setState({
      checked: e.target.checked
    });
    onClick && onClick(e);
  };

  onChange = (e) => {
    const { onChange } = this.props;
    onChange && onChange(e);
  }

  render() {
    const {
      disabled,
      defaultChecked,
      className,
      checkedVal,
      unCheckedVal,
      checkedIcon,
      unCheckedIcon,
      onClick,
      onChange,
      isChecked,
      ...others
    } = this.props;
    const { checked } = this.state;

    const classes = classNames(className, 'ten-switch', {
      'ten-switch--disabled': disabled,
      'ten-switch--ischecked': checked,
      'ten-switch--unchecked': !checked
    });
    const checkShow = <span className="ten-switch__text">{checkedIcon && <Icon type={checkedIcon} />} {checkedVal}</span>;
    const uncheckShow = <span className="ten-switch__text">{unCheckedIcon && <Icon type={unCheckedIcon} />} {unCheckedVal}</span>;

    return (
      <button
        className={classes}
        {...others}
      >
        <input
          className="ten-switch__checkinput"
          type="checkbox"
          checked={checked}
          onChange={this.onChange}
          onClick={this.onClick}
          disabled={disabled}
        />
        { checked ? checkShow : uncheckShow }
      </button>
    );
  }
}

export default Switch;
