export { default as config } from './config/index';
export { default as locale } from './locale/index';
export { default as Alert } from './alert/index';
export { default as Animate } from './animate/index';
export { default as Badge } from './badge/index';
export { default as Button } from './button/index';
export { default as Breadcrumb } from './breadcrumb/index';
export { default as Checkbox } from './checkbox/index';
export { default as Col } from './col/index';
export { default as Dropdown } from './dropdown/index';
export { default as Icon } from './icon/index';
export { default as Input } from './input/index';
export { default as Loading } from './loading/index';
export { default as Menu } from './menu/index';
export { default as Modal } from './modal/index';
export { default as Pagination } from './pagination/index';
export { default as Popup } from './popup/index';
export { default as Progress } from './progress/index';
export { default as Radio } from './radio/index';
export { default as Row } from './row/index';
export { default as Select } from './select/index';
export { default as Slider } from './slider/index';
export { default as Steps } from './steps/index';
export { default as Switch } from './switch/index';
export { default as Table } from './table/index';
export { default as Tabs } from './tabs/index';
export { default as Tag } from './tag/index';
export { default as Tooltip } from './tooltip/index';
export { default as Transfer } from './transfer/index';
export { default as Tree } from './tree/index';
export { default as TreeSelect } from './tree-select/index';
export { default as Upload } from './upload/index';
export { default as DatePicker } from './date-picker/index';
export { default as TimePicker } from './time-picker/index';
export { default as message } from './message/index';
export { default as Form } from './form/index';
export const version = __VERSION__;
