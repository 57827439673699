export function filter(data, fn, arr = []) {
  if (Array.isArray(data)) {
    data.forEach(item => filter(item, fn, arr));
  } else {
    const isValid = fn(data);
    if (isValid) {
      arr.push(data);
    }
    if (Array.isArray(data.children)) {
      filter(data.children, fn, arr);
    }
  }
  return arr;
}

export function getDataKeyMap(treeData, getNodeKey, getNodeChildren) {
  const keyMap = {};
  walkData(treeData, (node) => {
    const key = getNodeKey(node);
    keyMap[key] = node;
  }, getNodeChildren);
  return keyMap;
}

export function walkData(data, fn, getNodeChildren) {
  if (Array.isArray(data)) {
    data.forEach(item => walkData(item, fn, getNodeChildren));
  } else {
    fn(data);
    const children = getNodeChildren(data);
    if (children) {
      walkData(children, fn, getNodeChildren);
    }
  }
}

export function getNodeByKey(treeData, key, getNodeKey, getNodeChildren) {
  if (Array.isArray(treeData)) {
    for (let i = 0; i < treeData.length; i++) {
      const result = getNodeByKey(treeData[i], key, getNodeKey, getNodeChildren);
      if (result) {
        return result;
      }
    }
  } else {
    const nodeKey = getNodeKey(treeData);
    const nodeChildren = getNodeChildren(treeData);
    if (nodeKey === key) {
      return treeData;
    }
    return getNodeByKey(nodeChildren, key, getNodeKey, getNodeChildren);
  }
}

export function flattenTreeData(treeData, getNodeChildren, arr = []) {
  if (Array.isArray(treeData)) {
    for (let i = 0; i < treeData.length; i++) {
      flattenTreeData(treeData[i], getNodeChildren, arr);
    }
  } else {
    arr.push(treeData);
    const children = getNodeChildren(treeData);
    if (Array.isArray(children)) {
      flattenTreeData(children, getNodeChildren, arr);
    }
  }
  return arr;
}
