import React from 'react';
import PropTypes from 'prop-types';

function valueFormat(Component, options = {}) {

  const { input = true, enter = true, blur = true } = options;

  return class ValueFormat extends React.Component {

    static propTypes = {
      parser: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
      formatter: PropTypes.func,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      const { value: nextValue, formatter } = nextProps;
      let value = nextValue;

      if (formatter) {
        value = formatter(value);
      }

      if (value !== prevState.prevPropsValue) {
        return { value, prevPropsValue: value };
      } 

      return null;
    }

    constructor(props) {
      super(props);

      let value = props.value;

      const { formatter } = props;

      if (formatter) {
        value = formatter(value);
      }

      this.state = { value, lastValue: value, prevPropsValue: value };
    }

    onChange = (value) => {
      const { onChange, formatter } = this.props;
      const { lastValue } = this.state;

      if (!('value' in this.props) || value === lastValue) {
        if (formatter) value = formatter(value);
        this.setState({ value, lastValue: value });
      }

      onChange && onChange(value);
    }

    onBlur = (e) => {
      const { onBlur, parser } = this.props;

      let value = e.target.value;

      if (parser && Array.isArray(parser) && parser.length) {
        parser.forEach(rule => {
          const b = rule.options ? rule.options.blur : blur;
          if (b && rule.parser) value = rule.parser(value);
        });
      } else if (blur && parser) {
        value = parser(value);
      }
      onBlur && onBlur(e);
      this.onChange(value);
    }

    onKeyDown = (e) => {
      const { onKeyDown, parser } = this.props;
      let value = e.target.value;

      if (e.keyCode === 13) {
        if (parser && Array.isArray(parser) && parser.length) {
          parser.forEach(rule => {
            const en = rule.options ? rule.options.enter : enter;
            
            if (en && rule.parser) { 
              value = rule.parser(value);
            }
          });
        } else if (enter && parser) {
          value = parser(value);
        }
        this.onChange(value);
      }

      onKeyDown && onKeyDown(e);
    }

    onInputChange = (e) => {
      const { parser, formatter, disabled } = this.props;

      if (disabled) return;

      let value = e.target.value;

      if (parser && Array.isArray(parser) && parser.length) {
        parser.forEach(rule => {
          const inpt = rule.options ? rule.options.input : input;
          if (inpt && rule.parser) value = rule.parser(value);
        });
      } else if (input && parser) {
        value = parser(value);
      }

      if (input && formatter) {
        value = formatter(value);
      }

      this.setState({ value });
    }

    render() {
      const { 
        onChange,
        onBlur,
        onKeyDown,
        parser,
        formatter,
        value: propValue,

        ...others
      } = this.props;

      const { value } = this.state;

      return (
        <Component 
          {...others}
          value={value}
          onChange={this.onInputChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
        />
      );
      
    }
  };
}

export default valueFormat;
