import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { easeout } from '../util';

const itemHeight = 40;

class TimeOptions extends React.Component {
  static propTypes = {
    topItemIndex: PropTypes.number,
    onChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    optionList: PropTypes.array
  };

  componentDidMount() {
    this.fixScrollBarshake();
    this.scrollTo(this.props.topItemIndex);
  }

  componentDidUpdate() {
    this.fixScrollBarshake();
    this.scrollTo(this.props.topItemIndex);
  }

  fixScrollBarshake() {
    if (this.itemsWrapper) {
      const width = this.itemsWrapper.getBoundingClientRect().width;
      this.itemsWrapper.style.width = width + 'px';
      this.itemsWrapperScrollElm.style.width = width + 'px';
    }
  }

  onClickHandler(newValue) {
    const { onChange } = this.props;
    onChange && onChange(newValue);
  }

  onMouseEnterHanlder = () => {
    const { onMouseEnter } = this.props;
    onMouseEnter && onMouseEnter();
  }

  scrollTo(index, rate = 4) {

    const itemsWrapper = this.itemsWrapper;

    const start = itemsWrapper.scrollTop;
    const end = (itemHeight * index) + 1;

    if (this.isScrolling || start === end) return;
    
    if (itemsWrapper && !itemsWrapper.classList.contains('ten-time-options--scrolling')) {
      this.isScrolling = true;

      setTimeout(() => {
        itemsWrapper.classList.add('ten-time-options--scrolling');
      });

      setTimeout((function() {
        easeout.call(this, start, end, rate, (value, isFinished) => {
          itemsWrapper.scrollTop = value;
          if (isFinished) {
            setTimeout((function() {
              this.isScrolling = false;
              itemsWrapper.classList.remove('ten-time-options--scrolling');
            }).bind(this));
          }
        });
      }).bind(this));
    }
  }

  render() {
    const { 
      value,
      onChange,
      className,
      optionList,
      onMouseEnter,
      topItemIndex,

      ...ohters } = this.props;

    return (
      <div
        className={classNames('ten-time-options', className)}
        ref={e => {
          this.itemsWrapper = e; 
        }}
        onMouseEnter={this.onMouseEnterHanlder}
        {...ohters}
      >
        <div ref={e => {
          this.itemsWrapperScrollElm = e; 
        }}>
          {
            optionList.map((item, key) => (
              <span 
                key={key}
                className={classNames('ten-time-options__item', {
                  'ten-time-options__item--selected': item.selected,
                  'ten-time-options__item--disabled': item.disabled
                })}
                onClick={() => { 
                  if (item.disabled) return;
                  this.onClickHandler(item.value); 
                }}
              >
                {item.text}
              </span>
            ))
          }
        </div>
      </div>
    );
  }
}

export default TimeOptions;
