import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import renderChildren from './render-children';
import TreeModel from './tree-model';
import utils from '../utils';
import * as treeUtil from './tree-util';

/**
 * @visibleName Tree 树形组件
 */
class Tree extends React.Component {

  static propTypes = {
    /**
     * 数据
     */
    data: PropTypes.array,
    /**
     * @ignore
     * from TreeSelect 不用重复做了
     */
    dataKeyMapFromProps: PropTypes.object,
    /**
     * 选中值
     */
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]),
    /**
     * 是否禁用，如果 Boolean 类型，整个组件禁用，
     * 如果是 Function 类型，则根据函数返回值确定某个节点是否禁用，参数是节点数据
     */
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /**
     * 指定打开的节点
     */
    expanded: PropTypes.array,
    /**
     * 是否自动展开父节点
     */
    expandParent: PropTypes.bool,
    /**
     * 是否自动折叠兄弟菜单
     */
    closeSiblings: PropTypes.bool,
    /**
     * 是否显示展开动画
     */
    expandAnimation: PropTypes.bool,
    /**
     * 是否可选
     */
    selectable: PropTypes.bool,
    /**
     * 是否多选
     */
    multiple: PropTypes.bool,
    /**
     * 多选模式下是否显示 checkbox
     */
    multipleCheckbox: PropTypes.bool,
    /**
     * selected 类型 key or 选中选项的 data 数据
     */
    selectedType: PropTypes.oneOf(['key', 'data']),
    /**
     * 定义选中 keys 的形式，会决定 onChange 接受到的 selectedKeys 数组的形式<br>
     * all: 选中数组包含所有选中的节点 <br>
     * onlyLeaf: 选中数组包含所有叶子的节点 <br>
     * parentFirst: 若节点被选中，则不包含该节点的子孙节点 
     */
    selectedMode: PropTypes.oneOf(['all', 'onlyLeaf', 'parentFirst']),
    /**
     * 选中值变化回调
     */
    onChange: PropTypes.func,
    /**
     * 动态加载节点，返回 promise
     */
    loadChildren: PropTypes.func,
    /**
     * 节点 key(id) 在 nodeData 中对应的键名<br/>
     */
    nodeKey: PropTypes.string,
    /**
     * 节点文本在 nodeData 中对应的键名<br/>
     * 如需另外对节点内容定制，可以指定 renderNode (可返回 react element)
     */
    nodeText: PropTypes.string,
    /**
     * 节点 children 在 nodeData 中对应的键名
     */
    nodeChildren: PropTypes.string,
    /**
     * 节点点击回调
     * params：(nodeKey, nodeData，selected, expanded)
     */
    onNodeClick: PropTypes.func,
    /**
     * 渲染节点，params：(nodeData，selected, expanded)
     */
    renderNode: PropTypes.func,
    /**
     * 打开节点回调
     */
    onExpand: PropTypes.func,
    /**
     * @ignore
     */
    filterData: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /**
     * @ignore
     */
    filterText: PropTypes.string,
    /**
     * 是否可拖拽
     */
    draggable: PropTypes.bool,
    /**
     * 节点开始拖拽事件
     * @param {Object} data 被拖拽的节点
     * @param {Object} event 拖拽事件对象
     */
    onNodeDragStart: PropTypes.func,
    /**
     * 拖拽进入其他节点时触发的事件
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     * @param {Object} event 拖拽事件对象
     */
    onNodeDragEnter: PropTypes.func,
    /**
     * 拖拽节点悬浮于其他节点时触发的事件
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     * @param {Object} event 拖拽事件对象
     */
    onNodeDragOver: PropTypes.func,
    /**
     * 拖拽节点离开其他节点时触发的事件
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     * @param {Object} event 拖拽事件对象
     */
    onNodeDragLeave: PropTypes.func,
    /**
     * 拖拽结束时触发的事件
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} event 拖拽事件对象
     */
    onNodeDragEnd: PropTypes.func,
    /**
     * 拖拽释放于其他节点时触发的事件
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     * @param {Object} event 拖拽事件对象
     */
    onNodeDrop: PropTypes.func,
    /**
     * 拖拽放置钩子，在某节点之前插入节点，若返回 false 则阻止拖拽
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     */
    onNodeDropInsertBefore: PropTypes.func,
    /**
     * 拖拽放置钩子，在某节点之后插入节点，若返回 false 则阻止拖拽
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     */
    onNodeDropInsertAfter: PropTypes.func,
    /**
     * 拖拽放置钩子，在某节点之内加入节点，若返回 false 则阻止拖拽
     * @param {Object} sourceData 被拖拽的节点
     * @param {Object} targetData 目标节点
     */
    onNodeDropAppend: PropTypes.func,
    /**
     * data 发生变化，一般发生在拖拽之后
     * @param {Array} newTreeData
     */
    onDataChange: PropTypes.func,
  };

  static defaultProps = {
    selectable: false,
    multipleCheckbox: true,
    expandParent: true,
    expandAnimation: true,
    selectedMode: 'all',
    nodeKey: 'key',
    nodeText: 'text',
    nodeChildren: 'children',
  };

  static childContextTypes = {
    tree: PropTypes.object,
    showCheckbox: PropTypes.bool,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { multiple, selectedType, selectedMode, expandParent, closeSiblings, nodeKey, nodeText, nodeChildren } = nextProps;
    const getNodeKey = node => node[nodeKey];
    const getNodeChildren = node => node[nodeChildren];
    const newState = {
      nodeModel: prevState.nodeModel,
      data: prevState.data,
      expanded: nextProps.expanded || prevState.expanded,
      dataSelected: prevState.dataSelected,
    };

    /* get data keymap */
    const updateDataKeyMap = function () {
      newState.cloneDataKeyMap = treeUtil.getDataKeyMap(
        nextProps.data,
        getNodeKey,
        getNodeChildren
      );
      newState.selectedDataKeyMap = {};
      if (selectedType === 'data' && nextProps.selected) {
        const selected = multiple ? nextProps.selected : [nextProps.selected];
        selected.forEach((node) => {
          const key = getNodeKey(node);
          newState.selectedDataKeyMap[key] = node;
        });
      }
      newState.dataKeyMap = { ...newState.cloneDataKeyMap, ...newState.selectedDataKeyMap };
    };
    if (nextProps.data !== prevState.data || nextProps.selected !== prevState.selected) {
      if (nextProps.dataKeyMapFromProps) {
        newState.cloneDataKeyMap = nextProps.dataKeyMapFromProps.cloneDataKeyMap;
        newState.selectedDataKeyMap = nextProps.dataKeyMapFromProps.selectedDataKeyMap;
        newState.dataKeyMap = nextProps.dataKeyMapFromProps.dataKeyMap;
      } else {
        updateDataKeyMap();
      }
    }

    /* transform selected */
    const transform = (v) => {
      let dataSelected;
      if (!multiple) {
        dataSelected = !utils.isEmpty(v) ? [v] : [];
      } else {
        dataSelected = Array.isArray(v) ? v : [];
      }
      if (selectedType === 'data') {
        dataSelected = dataSelected.map((data) => {
          const key = getNodeKey(data);
          return key;
        });
      }
      return dataSelected;
    };
    if ('selected' in nextProps && nextProps.selected !== prevState.selected) {
      newState.selected = nextProps.selected;
      newState.dataSelected = transform(nextProps.selected);
    }

    /* remake treeModel */
    if (nextProps.data !== prevState.data) {
      newState.data = nextProps.data;
      newState.cloneData = nextProps.data;
      newState.nodeModel = new TreeModel({
        selectMode: selectedMode,
        expandParent,
        closeSiblings,
      });
      newState.nodeModel.buildFromArray(
        nextProps.data,
        item => item[nodeKey],
        item => item[nodeChildren]
      );
    }

    if (
      nextProps.data !== prevState.data || 
      nextProps.filterData !== prevState.filterData || 
      nextProps.filterText !== prevState.filterText
    ) {
      newState.filterData = nextProps.filterData;
      newState.filterText = nextProps.filterText;

      const { filterData, filterText, nodeModel } = newState;
      let filterFn;
      let filteredNodeModels = nodeModel.children;

      if (filterText) {
        if (filterData === true) {
          filterFn = (model) => model.data[nodeText].indexOf(filterText) > -1;
          filteredNodeModels = treeUtil.filter(nodeModel.children, filterFn);
        } else if (typeof filterData === 'function') {
          filterFn = (node) => filterData(filterText, node.data);
          filteredNodeModels = treeUtil.filter(nodeModel.children, filterFn);
        }
      }

      newState.filteredNodeModels = filteredNodeModels;
    } 

    newState.nodeModel.setExpanded(newState.expanded);
    newState.nodeModel.applyExpanded();

    newState.nodeModel.setSelected(newState.dataSelected);
    newState.nodeModel.applySelected();

    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cloneData: [],
      selected: null,
      dataSelected: [], // tranformed 全部为 key 类型
      nodeModel: null,
      nodeModelNeedUpdate: false,
      filteredNodeModels: [],
      expanded: [],
      selectedDataKeyMap: {},
      cloneDataKeyMap: {},
      dataKeyMap: {},
    };
  }

  getChildContext() {
    return {
      tree: this,
      showCheckbox: this.props.multiple && this.props.multipleCheckbox,
    };
  }
  
  getNodeKey = nodeData => nodeData[this.props.nodeKey];
  getNodeChildren = nodeData => nodeData[this.props.nodeChildren];

  renderNode = (nodeData, selected, expanded) => {
    const { renderNode, nodeText } = this.props;
    if (renderNode) {
      return renderNode(nodeData, selected, expanded);
    }
    return nodeData[nodeText];
  }

  isRemoteNode = (nodeModel) => {
    const { nodeChildren } = this.props;
    return !nodeModel.isLeaf && nodeModel.data[nodeChildren] === true;
  }

  isNodeDisabled = (nodeData) => {
    const { disabled } = this.props;
    if (typeof disabled === 'function') {
      return disabled(nodeData);
    }
    return nodeData.disabled || disabled;
  }

  reverse(v) {
    const { selectedType, multiple } = this.props;
    let selected = v;
    if (selectedType === 'data') {
      selected = v.map(key => this.getDataByKey(key));
    }
    if (!multiple) {
      selected = selected.length ? selected[0] : undefined;
    }
    return selected;
  }

  getDataByKey(key) {
    return this.state.dataKeyMap[key];
  }

  onNodeSelectChange = (key, selected) => {
    const { multiple, selectedMode, onChange } = this.props;
    const { nodeModel, dataSelected, selectedDataKeyMap, cloneDataKeyMap } = this.state;
    let selectedKeys;
    if (!multiple) {
      selectedKeys = [key];
    } else {
      nodeModel.handleSelectedChange(key, selected);
      const unSortedSelected = Array.prototype.concat.apply(
        [],
        nodeModel.children.map(child => child.getSelected(selectedMode))
      );
      // 排序
      selectedKeys = [];
      dataSelected.forEach((preSelectedKey) => {
        const index = unSortedSelected.indexOf(preSelectedKey);
        if (index > -1) {
          selectedKeys.push(preSelectedKey);
          unSortedSelected.splice(index, 1);
        }
        if (selectedDataKeyMap[preSelectedKey] && !cloneDataKeyMap[preSelectedKey]) {
          selectedKeys.push(preSelectedKey);
        }
      });
      selectedKeys = selectedKeys.concat(unSortedSelected);
    }
    if (!('selected' in this.props)) {
      this.setState({
        dataSelected: selectedKeys,
      });
      nodeModel.setSelected(selectedKeys);
      nodeModel.applySelected();
    }
    onChange && onChange(this.reverse(selectedKeys));
  }

  onNodeClick = (key, data, selected, expanded) => {
    const { onNodeClick } = this.props;
    onNodeClick && onNodeClick(key, data, selected, expanded);
  }

  onNodeExpandChange = (key, expandedKeys) => {
    const { onExpand } = this.props;
    const { nodeModel } = this.state;
    nodeModel.handleExpandedChange(key, expandedKeys);

    const expanded = Array.prototype.concat.apply(
      [],
      nodeModel.children.map(child => child.getExpanded())
    );
    if (!('expanded' in this.props)) {
      this.setState({
        expanded
      });
      nodeModel.setExpanded(expanded);
      nodeModel.applyExpanded();
    }
    onExpand && onExpand(expanded);
  }

  onNodeLoad = (nodeModel) => {
    const { loadChildren, nodeKey, nodeText, nodeChildren } = this.props;
    if (!loadChildren) {
      return;
    }

    const p = loadChildren(nodeModel.key, nodeModel.data);
    nodeModel.loading = true;
    if (typeof p.then === 'function') {
      p.then((children) => {
        nodeModel.loading = false;
        if (children && Array.isArray(children)) {
          nodeModel.addChildren(
            this.state.nodeModel,
            children,
            nodeKey,
            nodeText,
            nodeChildren
          );
          this.forceUpdate();
        }
      }, (e) => {
        nodeModel.loading = false;
        this.forceUpdate();
        throw e;
      });
    } else {
      nodeModel.loading = false;
      this.forceUpdate();
    }
  }

  /* 节点拖拽 */
  onNodeDragStart(sourceModelId, e) {
    const { onNodeDragStart  } = this.props;
    const { nodeModel  } = this.state;
    
    if (onNodeDragStart) {
      const sourceNode = nodeModel.getNodeById(sourceModelId);
      /**
       * 节点开始拖拽事件
       * @param {Object} data 被拖拽的节点
       * @param {Object} event 拖拽事件对象
       */
      onNodeDragStart(sourceNode.data, e);
    }
  }

  onNodeDragEnter(sourceModelId, targetModelId, e) {
    const { onNodeDragEnter  } = this.props;
    const { nodeModel  } = this.state;
    if (onNodeDragEnter) {
      const targetNode = nodeModel.getNodeById(targetModelId);
      const sourceNode = nodeModel.getNodeById(sourceModelId);
      /**
       * 拖拽进入其他节点时触发的事件
       * @param {Object} sourceData 被拖拽的节点
       * @param {Object} targetData 目标节点
       * @param {Object} event 拖拽事件对象
       */
      onNodeDragEnter(sourceNode.data, targetNode.data, e);
    }
  }

  onNodeDragOver(sourceModelId, targetModelId, e) {
    const { onNodeDragOver  } = this.props;
    const { nodeModel  } = this.state;

    if (onNodeDragOver) {
      const targetNode = nodeModel.getNodeById(targetModelId);
      const sourceNode = nodeModel.getNodeById(sourceModelId);
      /**
       * 拖拽节点悬浮于其他节点时触发的事件
       * @param {Object} sourceData 被拖拽的节点
       * @param {Object} targetData 目标节点
       * @param {Object} event 拖拽事件对象
       */
      onNodeDragOver(sourceNode.data, targetNode.data, e);
    }
  }

  onNodeDragLeave(sourceModelId, targetModelId, e) {
    const { onNodeDragLeave  } = this.props;
    const { nodeModel  } = this.state;

    if (onNodeDragLeave) {
      const targetNode = nodeModel.getNodeById(targetModelId);
      const sourceNode = nodeModel.getNodeById(sourceModelId);
      /**
       * 拖拽节点离开其他节点时触发的事件
       * @param {Object} sourceData 被拖拽的节点
       * @param {Object} targetData 目标节点
       * @param {Object} event 拖拽事件对象
       */
      onNodeDragLeave(sourceNode.data, targetNode.data, e);
    }
  }
  onNodeDragEnd(sourceNode, e) {
    const { onNodeDragEnd  } = this.props;
    if (onNodeDragEnd) {
      /**
       * 拖拽结束时触发的事件
       * @param {Object} sourceData 被拖拽的节点
       * @param {Object} event 拖拽事件对象
       */
      onNodeDragEnd(sourceNode.data, e);
    }
  }
  onNodeDrop(sourceModelId, targetModelId, e) {
    const { onNodeDrop  } = this.props;
    const { nodeModel  } = this.state;
    if (onNodeDrop) {
      const targetNode = nodeModel.getNodeById(targetModelId);
      const sourceNode = nodeModel.getNodeById(sourceModelId);
      onNodeDrop(sourceNode.data, targetNode.data, e);
    }
  }
  insert(beforeOrAfter = 0, sourceModelId, targetModelId) {
    const { onNodeDropInsertBefore, onNodeDropInsertAfter, onDataChange } = this.props;
    const { nodeModel  } = this.state;
    const targetNode = nodeModel.getNodeById(targetModelId);
    const sourceNode = nodeModel.getNodeById(sourceModelId);

    if (!onDataChange) {
      return;
    }

    if (!(sourceNode && targetNode)) {
      return;
    }

    if (beforeOrAfter === 0 && onNodeDropInsertBefore) {
      if (
        onNodeDropInsertBefore(sourceNode.data, targetNode.data)
        === false
      ) {
        return;
      }
    }

    if (beforeOrAfter === 1 && onNodeDropInsertAfter) {
      if (
        onNodeDropInsertAfter(sourceNode.data, targetNode.data) === false
      ) {
        return;
      }
    }

    const cloneData = cloneDeep(this.state.data);
    const flattenedTreeData = treeUtil.flattenTreeData(cloneData, this.getNodeChildren);
    const flattenedNodeModel = treeUtil.flattenTreeData(nodeModel.children, model => model.children);
    const sourceNodeParentIndex = flattenedNodeModel.indexOf(sourceNode.parent);
    const targetNodeParentIndex = flattenedNodeModel.indexOf(targetNode.parent);
    const sourceNodeIndex = flattenedNodeModel.indexOf(sourceNode);
    const targetNodeIndex = flattenedNodeModel.indexOf(targetNode);

    const sourceData = flattenedTreeData[sourceNodeIndex];
    const targetData = flattenedTreeData[targetNodeIndex];
    const sourceDataParent = flattenedTreeData[sourceNodeParentIndex];
    const targetDataParent = flattenedTreeData[targetNodeParentIndex];

    let sourceDataParentChildren;
    if (typeof sourceDataParent === 'undefined') {
      sourceDataParentChildren = cloneData;
    } else {
      sourceDataParentChildren = this.getNodeChildren(sourceDataParent);
    }

    // delete
    const index = sourceDataParentChildren.indexOf(sourceData);
    if (index > -1) {
      sourceDataParentChildren.splice(index, 1);
    }

    // insert
    let targetDataParentChildren;
    if (typeof targetDataParent === 'undefined') {
      targetDataParentChildren = cloneData;
    } else {
      targetDataParentChildren = this.getNodeChildren(targetDataParent);
    }

    const index2 = targetDataParentChildren.indexOf(targetData);
    if (index2 > -1) {
      targetDataParentChildren.splice(index2 + beforeOrAfter, 0, sourceData);
    }
    onDataChange(cloneData);
  }

  append(sourceModelId, targetModelId) {
    const { onNodeDropAppend, onDataChange, nodeChildren  } = this.props;
    const { nodeModel  } = this.state;
    const targetNode = nodeModel.getNodeById(targetModelId);
    const sourceNode = nodeModel.getNodeById(sourceModelId);

    if (!onDataChange) {
      return;
    }

    if (!(sourceNode && targetNode)) {
      return;
    }

    if (sourceNode.parent === targetNode) {
      return;
    }

    if (onNodeDropAppend) {
      if (onNodeDropAppend(sourceNode.data, targetNode.data) === false) {
        return;
      }
    }

    const cloneData = cloneDeep(this.state.data);
    const flattenedTreeData = treeUtil.flattenTreeData(cloneData, this.getNodeChildren);
    const flattenedNodeModel = treeUtil.flattenTreeData(nodeModel.children, model => model.children);
    const sourceNodeParentIndex = flattenedNodeModel.indexOf(sourceNode.parent);
    const sourceNodeIndex = flattenedNodeModel.indexOf(sourceNode);
    const targetNodeIndex = flattenedNodeModel.indexOf(targetNode);

    const sourceData = flattenedTreeData[sourceNodeIndex];
    const targetData = flattenedTreeData[targetNodeIndex];
    const sourceDataParent = flattenedTreeData[sourceNodeParentIndex];
    let targetDataChildren = this.getNodeChildren(targetData);

    let sourceDataParentChildren;
    if (typeof sourceDataParent === 'undefined') {
      sourceDataParentChildren = cloneData;
    } else {
      sourceDataParentChildren = this.getNodeChildren(sourceDataParent);
    }

    // delete
    const index = sourceDataParentChildren.indexOf(sourceData);
    if (index > -1) {
      sourceDataParentChildren.splice(index, 1);
    }

    // append
    if (!Array.isArray(targetDataChildren)) {
      targetDataChildren = [];
      targetData[nodeChildren] = targetDataChildren;
    }
    targetDataChildren.push(sourceData);
    onDataChange(cloneData);
  }

  render() {
    const { filteredNodeModels } = this.state;
    const {
      data,
      dataKeyMapFromProps,
      disabled,
      expanded,
      expandParent,
      closeSiblings,
      expandAnimation,
      selectable,
      multiple,
      multipleCheckbox,
      selected,
      selectedType,
      selectedMode,
      onChange,
      loadChildren,
      nodeKey,
      nodeText,
      nodeChildren,
      onNodeClick,
      renderNode,
      onExpand,
      filterData,
      filterText,
      draggable,
      onNodeDragStart,
      onNodeDragEnter,
      onNodeDragOver,
      onNodeDragLeave,
      onNodeDragEnd,
      onNodeDrop,
      onNodeDropInsertBefore,
      onNodeDropInsertAfter,
      onNodeDropAppend,
      onDataChange,
      ...others
    } = this.props;

    return (
      <div className="ten-tree" {...others}>
        {
          renderChildren(filteredNodeModels)
        }
      </div>
    );
  }
}

export default Tree;
