import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from '../../locale';

import Item from './item';

const Day = ({ weeks, onClick, onMouseEnter, firstDayOfWeek, className }) => {
  const WEEKS_LEN = 7;
  const weekArr = [];
  let wi = firstDayOfWeek;

  while (weekArr.length < WEEKS_LEN) {
    weekArr.push(t(`datePicker.weeks.${wi}`));
    wi = (wi + WEEKS_LEN + 1) % WEEKS_LEN;
  }

  return (
    <table className={classNames('ten-date-day', className)}>
      <thead>
        <tr>
          {weekArr.map((w, i) => <th key={i}>{w}</th>)}
        </tr>
      </thead>

      <tbody>
        {weeks.map((week, i) => (
          <tr key={i}>
            {week.map((w, j) => (
              <Item
                className="ten-date-day__item"
                key={j}
                {...w}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Day.propTypes = {
  firstDayOfWeek: PropTypes.number,
  weeks: PropTypes.array,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

Day.defaultProps = {
  firstDayOfWeek: 0,
  weeks: [],
  onClick() { },
  onMouseEnter() { }
};

export default Day;
