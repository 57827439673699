import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input from './input';
import Tag from '../tag';


class InputTag extends React.Component {

  static propTypes = {
    tags: PropTypes.array,
    onTagDelete: PropTypes.func,
    onTagClick: PropTypes.func,
    getTagKey: PropTypes.func,
    getTagText: PropTypes.func
  };

  static defaultProps = {
    tags: [],
    onTagDelete: () => { },
    onTagClick: () => { },
    getTagKey: tag => tag,
    getTagText: tag => tag
  };

  onTagDelete(tagToDelete) {
    const { tags, onTagDelete, getTagKey } = this.props;
    const tagsAfterDelete = tags.filter(tag => getTagKey(tag) !== getTagKey(tagToDelete));

    onTagDelete(tagToDelete, tagsAfterDelete);
    // e && e.stopPropagation();
  }

  onTagClick(tagClicked) {
    const { onTagClick } = this.props;

    onTagClick(tagClicked);
  }

  onKeyDown = (e) => {
    const { onKeyDown, disabled, value: propValue, tags } = this.props;
    const value = propValue || '';

    if (disabled) {
      return;
    }
    if (e.keyCode === 8 && value === '' && tags.length > 0) {
      this.onTagDelete(tags[tags.length - 1]);
    }

    onKeyDown && onKeyDown(e);
  }

  componentDidUpdate() { 
    const { value, tags, suffixIcon, readOnly, inputProps } = this.props;

    const padding = suffixIcon ? 40 : 16;
    
    if (this.inputElm && this.inputElm.refInputElem && this.inputMirrorElm) {
      if (!value && (readOnly || (inputProps && inputProps.readOnly))) {
        this.inputElm.refInputElem.style.width = '';
      } else if (tags.length && !value) {
        this.inputElm.refInputElem.style.width = `calc(0.75em + ${padding}px)`;
      } else if (!tags.length && !value) {
        this.inputElm.refInputElem.style.width = '100%';
      } else {
        this.inputElm.refInputElem.style.width = `${Math.ceil(this.inputMirrorElm.getBoundingClientRect().width) + 2 + padding}px`;
      }
    }
  }

  render() {
    const { className: propClassName, tags, onTagDelete, onTagClick, getTagKey, getTagText, ...others } = this.props;
    
    const isEmpty = !tags.length && !this.props.value;

    const className = classNames(propClassName, 'ten-input-tag', {
      'ten-input-tag--empty': isEmpty
    });

    const tagsPrefix = (
      <React.Fragment>
        {
          tags.map(tag => (
            <Tag
              key={getTagKey(tag)}
              onClick={(e) => this.onTagClick(tag, e)}
              onClose={(e) => this.onTagDelete(tag, e)}
            >
              {getTagText(tag)}
            </Tag>
          ))
        }
      </React.Fragment>
    );

    return (
      <Input
        ref={el => {
          this.inputElm = el; 
        }}
        className={className}
        type="text"
        {...others}
        inputPrefixRaw={
          tagsPrefix
        }
        inputSuffix={<span ref={el => {
          this.inputMirrorElm = el; 
        }} className="ten-input-tag__input-mirror">{this.props.value}</span>}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

export default InputTag;
