import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Col from '../col';

function calSize(width) {
  let size = 'xs';
  if (width < 768) {
    size = 'xs';
  } else if (width >= 768 && width < 992) {
    size = 'sm';
  } else if (width >= 992 && width < 1200) {
    size = 'md';
  } else if (width >= 1200 && width < 1920) {
    size = 'lg';
  } else {
    size = 'xl';
  }
  return size;
}

/**
 * @visibleName Row 行
 */
export default class Row extends React.PureComponent {
  static propTypes = {
    /**
     * 所含列数
     */
    colCount: PropTypes.number,
    /**
     * 列之间的间隔
     */
    gutter: PropTypes.number,
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  static defaultProps = {
    colCount: 12,
    gutter: 0
  };

  static getDerivedStateFromProps(nextProps, preState) {
    const { size } = preState;
    const newState = {
      colCount: nextProps.colCount,
      gutter: nextProps.gutter
    };

    if (size && nextProps[size]) {
      const config = nextProps[size];
      if (typeof config === 'number') {
        newState.colCount = config;
      } else {
        newState.colCount = config.colCount;
        newState.gutter = config.gutter;
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      size: calSize(window.innerWidth),
      colCount: 12,
      gutter: 0
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = debounce(() => {
    const size = calSize(window.innerWidth);
    this.setState({
      size
    });
  }, 50)

  render() {
    const {
      colCount: propColCount,
      gutter: propGutter,
      style: propStyle,
      xs,
      sm,
      md,
      lg,
      xl,
      className,
      children,
      ...others
    } = this.props;
    const {
      gutter, colCount, size
    } = this.state;
    const style = {
      margin: `0 ${-gutter / 2}px`,
    };

    // console.log(gutter, colCount, size);
    return (
      <div
        className={classNames('ten-row', className)}
        style={{
          ...style, ...propStyle
        }}
        {...others}
      >
        {
          React.Children.map(children, child => {
            if (child && child.type === Col) {
              return React.cloneElement(child, {
                gutter, colCount, size
              });
            }
            return child;
          })
        }
      </div>
    );


  }
}
