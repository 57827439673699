import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Picker from './picker';
import DatePanel from './panel/date';
import DateRange from './panel/date-range';
import DateTime from './panel/date-time';
import { t } from '../locale';
import { withConfig } from '../config';

/**
 * @visibleName DatePicker 日期选择器
 */
class DatePicker extends React.Component {
  static propTypes = {
    /**
     * 模式
     */
    mode: PropTypes.oneOf(['date', 'datetime', 'month', 'year'])
  }

  static defaultProps = {
    mode: 'date'
  }

  render() {
    const { isRange, mode, startPlaceholder, endPlaceholder, placeholder } = this.props;

    let ph;

    switch (mode) {
      case 'year':
        ph = t('datePicker.yearName');
        break;
      case 'month':
        ph = t('datePicker.monthName');
        break;
      default:
        ph = t('datePicker.dayName');
        break;
    }

    let panel = DatePanel;

    if (mode === 'datetime') {
      panel = DateTime;
    } else if (isRange) {
      panel = DateRange;
    }

    return (
      <Picker
        {...this.props}
        className={classNames({
          [`ten-picker-${mode}`]: true,
          [`ten-picker-${mode}-range`]: isRange
        })}
        panel={panel}
        startPlaceholder={startPlaceholder || t('datePicker.start', { type: ph })}
        endPlaceholder={endPlaceholder || t('datePicker.end', { type: ph })}
        placeholder={placeholder || t('datePicker.pleaseSelect', { type: ph })}
        type={mode}
      />
    );
  }
}

export default withConfig(DatePicker);
