import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';
import Animate from '../animate';

/**
 * @visibleName  Alert 警告提示
 */
class Alert extends React.Component {

  static propTypes = {
    /**
     * 警告提示样式类型
     */
    type: PropTypes.oneOf(['info', 'success', 'error', 'warn', 'disabled']),
    /**
     * 是否可关闭
     */
    closeable: PropTypes.bool,
    /**
     * 警告提示内容
     */
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * 警告提示的辅助文字
     */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * 关闭时触发的回调函数
     */
    onClose: PropTypes.func,
    /**
     * 自定义辅助图标
     */
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    /**
     * 是否展示辅助图标
     */
    showIcon: PropTypes.bool,
    /**
     * 自定义关闭按钮
     */
    closeContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ])
  };

  static defaultProps = {
    type: 'info',
    closeable: false,
    showIcon: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShow: true
    };
  }

  handleClose = (e, func) => {
    e.preventDefault();
    const { onClose } = this.props;
    this.setState({
      isShow: false,
    });
    if (onClose) {
      onClose(func);
    }
  }

  render = () => {
    const {
      type,
      message,
      description,
      className,
      closeable,
      showIcon,
      icon,
      closeContent,
      ...others
    } = this.props;
    const { isShow } = this.state;
    const classes = classNames(className, 'ten-alert', {
      [`ten-alert--${type}`]: true,
      'ten-alert--hasclose': closeable,
      'ten-alert--noicon': !showIcon
    });

    const descriptionClass = classNames(className, {
      'ten-alert__description': description,
    });
    const messageClass = classNames(className, 'ten-alert__message', {
    });

    let iconType;

    if (type === 'info' || type === 'warn') {
      iconType = 'circle_error';
    } else if (type === 'success') {
      iconType = 'circle_tick';
    } else if (type === 'error') {
      iconType = 'circle_clear';
    } else if (type === 'disabled') {
      iconType = 'circle_info';
    }

    let iconElem;
    if (icon) {
      iconElem = typeof icon === 'string' ? <Icon type={icon} /> : icon;
    } else {
      iconElem = <Icon type={iconType} />;
    }

    let closeElem;
    if (closeContent) {
      closeElem = <div className="ten-alert__closeable ten-alert__closeable__content" onClick={this.handleClose}>{closeContent}</div>;
    } else {
      closeElem = <Icon type="clear" className="ten-alert__closeable ten-alert__closeable__icon" onClick={this.handleClose} />;
    }
    return (
      <Animate type={{ name: 'fade', params: {
        dur: 300,
      } }}>
        {
          isShow &&
          (
            <div
              className={classes}
              {...others}
            >
              <div>
                {showIcon && iconElem}
                <div className={messageClass}>{message}</div>
                {closeable ? closeElem : ''}
              </div>
              <div className={descriptionClass}>{description}</div>
            </div>
          )
        }
      </Animate>
    );
  };

}

export default Alert;
