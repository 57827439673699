/**
 * @author chazzhou
 */

import Button from './button';
import ButtonGroup from './button-group';

Button.Group = ButtonGroup;

export default Button;
