import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Day from '../basic/day';
import Header from '../basic/header';
import Year from '../basic/year';
import Month from '../basic/month';

import { 
  getWeeks, 
  getYears, 
  getMonths, 
  flagActive, 
  subtractMonth, 
  addMonth,
  getToday } from '../util';

class DatePicker extends React.Component {

  static propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    mode: PropTypes.oneOf(['year', 'month', 'date'])
  };

  static defaultProps = {
    value: getToday(),
    onChange() {},
    mode: 'date'
  };

  constructor(props) {
    super(props);

    const { value, mode } = props;

    this.state = { 
      year: value.getFullYear(), 
      month: value.getMonth(),
      type: mode
    };
  }

  clickHeader = (target, action) => {

    if (target === 'year' && action === 'prev') {
      this.setState(state => ({ year: state.year - 1 }));
    }

    if (target === 'year' && action === 'next') {
      this.setState(state => ({ year: state.year + 1 }));
    }

    if (target === 'month' && action === 'prev') {
      this.setState(state => {
        const prev = subtractMonth(new Date(state.year, state.month), 1);
        return { year: prev.getFullYear(), month: prev.getMonth() };
      });
    }

    if (target === 'month' && action === 'next') {
      this.setState(state => {
        const next = addMonth(new Date(state.year, state.month), 1);
        return { year: next.getFullYear(), month: next.getMonth() };
      });
    }
  }

  clickDate = (date) => {

    const { onChange } = this.props;

    onChange && onChange(date);
  }

  clickYear = (date) => {
    const { mode, onChange } = this.props;
    if (mode === 'year') {
      onChange && onChange(date);
    } else {
      this.setState({ type: 'month', year: date.getFullYear() });
    }
  }

  clickMonth = (date) => {
    const { mode, onChange } = this.props;
    if (mode === 'month') {
      onChange && onChange(date);
    } else {
      this.setState({ type: 'date', year: date.getFullYear(), month: date.getMonth() });
    }
  }

  onTypeChange = (type) => {
    this.setState({ type });
  }

  render() {

    const { 
      value,
      mode,
      onChange,
      disabledDate,
      minDate,
      maxDate,
      firstDayOfWeek, 
      unlinkPanels,
      ...others } = this.props;

    const { year, month, type } = this.state;

    const newValue = new Date(year, month);

    const options = { minDate, maxDate, disabledDate, firstDayOfWeek };

    const weeks = flagActive(getWeeks({ year, month }, options), { start: value });
    const months = flagActive(getMonths(year, options), { start: newValue, type: 'month' });
    const years = flagActive(getYears(year, options), { start: newValue, type: 'year' });

    return (
      <div className="ten-date" {...others}>

        <Header 
          year={year}
          month={month}
          type={type}
          onPrevOrNext={this.clickHeader}
          onTypeChange={this.onTypeChange}
        />

        <Day
          className={classNames({
            'ten-date__sub-panel--hide': type !== 'date' 
          })}
          firstDayOfWeek={firstDayOfWeek}
          weeks={weeks}
          onClick={this.clickDate}
        />

        <Year
          className={classNames({
            'ten-date__sub-panel--hide': type !== 'year' 
          })}
          years={years} 
          onClick={this.clickYear}
        />

        <Month
          className={classNames({
            'ten-date__sub-panel--hide': type !== 'month' 
          })} 
          months={months}
          onClick={this.clickMonth}
        />

      </div>
    );
  }
}


export default DatePicker;
