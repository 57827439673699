import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import Thead from './thead';
import Tbody from './tbody';
import domHelper from '../utils/dom-helper';

const DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  TOP: 'top'
};

class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colWidths: [],
      rowHeights: [],
      theadHeight: 0,
      leftFixedShadow: false,
      rightFixedShadow: false
    };
    this.scrollBarWidth = domHelper.getBrowserScrollBarWidth();
  }

  getChildContext() {
    return {
      setTbodyRenderedFunc: func => {
        this.tbodyRenderComplete = func;
      },
      setTheadRenderedFunc: func => {
        this.theadRenderComplete = func;
      }
    };
  }

  static childContextTypes = {
    setTbodyRenderedFunc: PropTypes.func,
    setTheadRenderedFunc: PropTypes.func
  };

  componentDidMount() {
    this.tbodyRenderComplete && this.tbodyRenderComplete();
    this.theadRenderComplete && this.theadRenderComplete();
    this.verdictFixedShadow();

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.mouseMoveBody = null;
  };

  tbodyRenderComplete;

  theadRenderComplete;

  getTbody = tbody => {
    if (!tbody) return;
    const tr = tbody.querySelector('tr');
    if (!tr) return;
    const colWidths = [];
    const rowHeights = [];
    const tds = tr.querySelectorAll('td');
    for (let i = 0; i < tds.length; i++) {
      colWidths.push(tds[i].offsetWidth);
    }
    const trs = tbody.querySelectorAll('tr');
    for (let i = 0; i < trs.length; i++) {
      rowHeights.push(trs[i].offsetHeight);
    }
    if (
      !isEqual(colWidths, this.state.colWidths) ||
      !isEqual(rowHeights, this.state.rowHeights)
    ) {
      this.setState({ colWidths, rowHeights });
    }
  };

  getThead = thead => {
    if (thead.offsetHeight !== this.state.theadHeight) {
      this.setState({
        theadHeight: thead.offsetHeight
      });
    }
  };

  getColgroup = (cols) => {
    const columns = cols || this.props.columns;
    return (
      <colgroup>
        {
          columns.map((col, i) => (
            <col key={i} style={{ width: col.width || undefined }} />
          ))
        }
      </colgroup>
    );
  };

  mouseMoveBody;

  setMouseMoveBody = name => {
    this.mouseMoveBody = name;
  };

  verdictFixedShadow = () => {
    const { leftFixedShadow, rightFixedShadow } = this.state;
    const { width } = this.props;
    const { scrollLeft, clientWidth } = this.bodyTable;
    if (scrollLeft > 0 && !leftFixedShadow) {
      this.setState({
        leftFixedShadow: true
      });
    } else if (scrollLeft === 0 && leftFixedShadow) {
      this.setState({
        leftFixedShadow: false
      });
    }
    if (clientWidth + scrollLeft === width && rightFixedShadow) {
      this.setState({
        rightFixedShadow: false
      });
    } else if (clientWidth + scrollLeft < width && !rightFixedShadow) {
      this.setState({
        rightFixedShadow: true
      });
    }
  };

  preScrollTop = 0;

  scrollHanlder = direction => {
    switch (direction) {
      case DIRECTION.CENTER:
        this.fixedHead.scrollLeft = this.bodyTable.scrollLeft;
        this.leftFixedBody && (this.leftFixedBody.scrollTop = this.bodyTable.scrollTop);
        this.rightFixedBody && (this.rightFixedBody.scrollTop = this.bodyTable.scrollTop);
        this.verdictFixedShadow();
        break;
      case DIRECTION.TOP:
        this.bodyTable.scrollLeft = this.fixedHead.scrollLeft;
        break;
      case DIRECTION.LEFT:
        this.bodyTable.scrollTop = this.leftFixedBody.scrollTop;
        this.rightFixedBody && (this.rightFixedBody.scrollTop = this.leftFixedBody.scrollTop);
        break;
      case DIRECTION.RIGHT:
        this.bodyTable.scrollTop = this.rightFixedBody.scrollTop;
        this.leftFixedBody && (this.leftFixedBody.scrollTop = this.rightFixedBody.scrollTop);
        break;
      default:
        this.bodyTable.scrollTop = this.preScrollTop;
        this.leftFixedBody && (this.leftFixedBody.scrollTop = this.preScrollTop);
        this.rightFixedBody && (this.rightFixedBody.scrollTop = this.preScrollTop);
        this.verdictFixedShadow();
    }

    this.preScrollTop = this.bodyTable.scrollTop;
  };

  onScroll = (e, direction) => {
    e.preventDefault();
    e.stopPropagation();
    this.scrollHanlder(direction);
  };

  getFixedTable = direction => {
    const {
      prefixClassName: propPrefixClassName,
      columns,
      height,
      width: fixedWidth
    } = this.props;
    const { colWidths, rowHeights, theadHeight } = this.state;

    if (!fixedWidth) {
      return null;
    }

    const prefixClassName = `${propPrefixClassName}-fixed`;

    const cols = columns.filter(item => item.width && item.fixed === direction);
    if (cols.length === 0) return null;
    const colgroupNode = this.getColgroup(cols);

    const w = height ? this.scrollBarWidth - 1 : -1;
    const endPushWidth = direction === DIRECTION.RIGHT ? w : 0;

    const fixedProps = {
      ...this.props,
      columns: cols,
      colWidths,
      theadHeight
    };

    const width = cols.map(v => v.width).reduce((a = 0, b) => a + b) + endPushWidth;

    const fixedShadow = this.state[`${direction}FixedShadow`];
    const fixedClassNames = classNames(`${prefixClassName}-${direction}`, {
      [`${prefixClassName}-${direction}-shadow`]: fixedShadow
    });
    const fixedHeadClassNames = classNames(`${prefixClassName}-head`);
    const fixedBodyClassNames = classNames(`${prefixClassName}-body`);

    const fixedHeadStyles = {
      marginBottom: -this.scrollBarWidth,
      marginRight: -this.scrollBarWidth
    };

    const fixedBodyStyles = {
      marginBottom: -this.scrollBarWidth,
      paddingBottom: this.scrollBarWidth
    };

    if (height) {
      const bodyHeight = height - theadHeight;
      Object.assign(fixedBodyStyles, {
        maxHeight: bodyHeight,
      });
      if (direction === DIRECTION.LEFT) {
        Object.assign(fixedBodyStyles, {
          marginRight: -this.scrollBarWidth - 20,
          paddingRight: 20,
        });
      }
    }

    const fixedBodyProps = {
      className: fixedBodyClassNames,
      style: fixedBodyStyles,
      ref: node => {
        this[`${direction}FixedBody`] = node;
      },
      onScroll: e => this.onScroll(e, direction)
    };

    return (
      <div className={fixedClassNames} style={{ width }}>
        <div className={fixedHeadClassNames} style={fixedHeadStyles}>
          <table>
            { colgroupNode }
            <Thead {...fixedProps} />
          </table>
        </div>
        <div {...fixedBodyProps}>
          <table>
            { colgroupNode }
            <Tbody {...fixedProps} isFixed rowHeights={rowHeights} />
          </table>
        </div>
      </div>
    );
  };

  render() {
    const { props, state } = this;
    const { prefixClassName, height, width } = props;
    const { theadHeight } = state;

    const fixedClassNames = classNames(`${prefixClassName}-fixed`);
    const fixedHeadClassNames = classNames(`${fixedClassNames}-head`);
    const fixedBodyClassNames = classNames(`${fixedClassNames}-body`);

    const containerStyles = width ? { width } : {};
    const bodyStyles = height ? { maxHeight: height - theadHeight - 1 } : {};

    return (
      <div className={fixedClassNames}>
        <div
          className={fixedHeadClassNames}
          ref={node => {
            this.fixedHead = node;
          }}
          style={{ marginBottom: -this.scrollBarWidth - 20, paddingBottom: 20 }}
          onScroll={e => this.onScroll(e, DIRECTION.TOP)}
        >
          <table style={containerStyles}>
            {this.getColgroup()}
            <Thead {...props} getThead={this.getThead} />
          </table>
        </div>
        <div
          className={fixedBodyClassNames}
          style={bodyStyles}
          onScroll={e => this.onScroll(e, DIRECTION.CENTER)}
          ref={node => {
            this.bodyTable = node;
          }}
        >
          <table style={containerStyles}>
            {this.getColgroup()}
            <Tbody {...props} getTbody={this.getTbody} />
          </table>
        </div>
        {this.getFixedTable(DIRECTION.LEFT)}
        {this.getFixedTable(DIRECTION.RIGHT)}
      </div>
    );
  }
}

export default Base;
