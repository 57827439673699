/* eslint no-underscore-dangle: 0 */
import isEqual from 'lodash/isEqual';

let treeNodeId = 0;
export default class TreeNodeModel {
  constructor(config) {
    this.id = treeNodeId;
    treeNodeId += 1;
    this.children = [];
    this.selected = 0; // 0 unSelected 1 Selected 2 halfSelected
    this.expanded = 0; // 0 unExpanded 1 Expanded
    this.setConfig(config);
    this.deep = 0;

    this.status = {
      selected: this.selected,
      expanded: this.expanded,
    };
    this.applyT = 0;
    this.manualExpand = false;
    this.loading = false;
  }

  setConfig({ key, isLeaf, data, onApply } = {}) {
    this.key = key;
    this.isLeaf = isLeaf;
    this.data = data;
    this.onApply = onApply;
    this.updateTreeInfo();
  }

  setStatus(status) {
    this.status = status;
    this.selected = status.selected;
    this.expanded = status.expanded;
  }

  updateTreeInfo() {
    this.root = this.getRoot();
    this.nodePath = this.getNodePath();
    this.path = this.nodePath.map(node => node.key);
  }

  getRoot() {
    if (this.root) {
      return this.root;
    }
    if (this.parent) {
      this.root = this.parent.getRoot();
      return this.root;
    }
    return this.isRoot ? this : null;
  }

  getNodePath() {
    let nodePath;
    if (this.isRoot) {
      nodePath = [];
    } else {
      nodePath = this.parent ? this.parent.getNodePath().concat(this) : [this];
    }

    this.nodePath = nodePath;
    return nodePath;
  }

  getNodeById(id) {
    if (id === this.id) {
      return this;
    }
    if (!this.isLeaf) {
      let node;
      for (let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        node = child.getNodeById(id);
        if (node) {
          return node;
        }
      }
    }
    return null;
  }

  getNodeByPath(path) {
    if (isEqual(path, this.path)) {
      return this;
    }
    if (!this.isLeaf) {
      let node;
      for (let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        node = child.getNodeByPath(path);
        if (node) {
          return node;
        }
      }
    }
    return null;
  }

  getNodesByKey(key) {
    if (key === this.key) {
      return [this];
    }
    if (!this.isLeaf) {
      let nodes = [];
      for (let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        nodes = nodes.concat(child.getNodesByKey(key));
      }
      return nodes;
    }
    return [];
  }

  apply() {
    this.children.forEach(child => child.apply());
    if (
      this.status.selected !== this.selected
      || this.status.expanded !== this.expanded
    ) {
      this.status.selected = this.selected;
      this.status.expanded = this.expanded;
      this.fireApply();
    }
  }

  applySelected() {
    this.children.forEach(child => child.applySelected());
    if (this.status.selected !== this.selected) {
      this.status.selected = this.selected;
      this.fireApply();
    }
  }

  applyExpanded() {
    this.children.forEach(child => child.applyExpanded());
    if (this.status.expanded !== this.expanded) {
      this.status.expanded = this.expanded;
      this.fireApply();
    }
  }

  fireApply() {
    if (this.onApply) {
      clearTimeout(this.applyT);
      this.applyT = setTimeout(() => {
        this.onApply && this.onApply();
      });
    }
  }

  mount(parent) {
    parent.addChild(this);
    if (this.parent.selected === 1) {
      this.selected = 1;
    }
    if (this.parent.status.selected === 1) {
      this.status.selected = 1;
    }
  }

  unMount() {
    if (parent) {
      this.parent.removeChild(this);
      this.parent = null;
    }
  }

  addChild(child, updateRoot = true) {
    if (!this.isLeaf && !this.children.find(item => item === child)) {
      this.children.push(child);
      child.parent = this; // eslint-disable-line
      child.deep = this.deep + 1; // eslint-disable-line
      child.updateTreeInfo();
      if (updateRoot && this.root) {
        this.root.update();
      }
    }
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    if (index > -1) {
      this.children.splice(index, 1);
    }
  }

  walk(fn) {
    const stop = this.deep > -1 ? fn(this) : false;
    !stop && this.children && this.children.forEach(child => child.walk(fn));
  }

  // 默认获取选中的 key 数组，用于不区分非兄弟同 key 节点的情况
  getSelected(mode) {
    return this.getSelectedNodes(mode).map(node => node.key);
  }

  // 选中节点的 path key 数组，用于需区分非兄弟同 key 节点的情况
  getSelectedPaths(mode) {
    return this.getSelectedNodes(mode).map(node => node.path);
  }

  // 选中节点的 path node 数组
  getSelectedNodePaths(mode) {
    return this.getSelectedNodes(mode).map(node => node.nodePath);
  }

  /**
   * @param {string} mode 'all', 'onlyLeaf', 'parentFirst'
   */
  getSelectedNodes(mode = 'all') {
    const arr = [];

    /* eslint-disable */
    function collect(node) {
      if (node.isRoot) {
        return false;
      }
      switch (mode) {
        case 'all':
          if (node.selected === 1) {
            arr.push(node);
          }
          break;
        case 'parentFirst':
          if (node.selected === 1) {
            arr.push(node);
            return true;
          }
          return node.selected !== 2;
        case 'onlyLeaf':
          if (node.isLeaf) {
            if (node.selected === 1) {
              arr.push(node);
            }
          }
      }
    }
    /* eslint-enable */
    this.walk(collect);
    return arr;
  }

  getExpanded() {
    const expanded = [];
    this.walk((node) => {
      if (node.expanded) {
        expanded.push(node.key);
      }
    });
    return expanded;
  }

  /**
   * 用 key 或 path 数组更新当前节点下的节点选中状态
   * @param {Array} selectedKeys 支持 path 形式的 key
   */
  updateSelectedByKeys(selectedKeys) {
    const selected = selectedKeys.some((item) => {
      const isPath = Array.isArray(item);
      const expect = isPath ? this.path : this.key;
      return isEqual(item, expect);
    });

    if (this.root.config.selectMode === 'independent') {
      this.selected = selected ? 1 : 0;
      this.children.forEach(child => child.updateSelectedByKeys(selectedKeys));
      return;
    }

    if (selected) {
      this.select(1, false);
    } else if (this.children.length === 0) {
      this.select(0, false);
    } else {
      this.children.forEach(child => child.updateSelectedByKeys(selectedKeys));
      this.updateSelected();
    }
  }

  handleSelectedChange(key, selected) {
    const isPath = Array.isArray(key);
    const expect = isPath ? this.path : this.key;

    if (isEqual(key, expect)) {
      this.select(selected ? 1 : 0);
    } else {
      this.children.forEach(child => child.handleSelectedChange(key, selected));
    }
  }

  select(selected, propagation = true) {
    this.selected = selected; // 0 or 1
    if (this.root.config.selectMode === 'independent') {
      return;
    }
    if (propagation) {
      this.parent && this.parent.updateSelected();
    }
    if (!this.isLeaf && (selected === 1 || selected === 0)) {
      this.children.forEach(child => child.select(selected, false));
    }
  }

  updateSelected() {
    if (this.root.config.selectMode === 'independent') {
      return;
    }
    if (!this.isLeaf && this.children.length > 0) {
      let allSelectedCount = 0;
      let selectedCount = 0;
      this.children.forEach((child) => {
        if (child.selected === 1 || child.selected === 2) {
          selectedCount += 1;
          if (child.selected === 1) {
            allSelectedCount += 1;
          }
        }
      });

      const selected = allSelectedCount === this.children.length ? 1 : (selectedCount > 0 ? 2 : 0); // eslint-disable-line

      this.selected = selected;
      this.parent && this.parent.updateSelected();
    }
  }

  updateExpandedByKeys(expandedKeys) {
    const expanded = expandedKeys.some((item) => {
      const isPath = Array.isArray(item);
      const expect = isPath ? this.path : this.key;
      return isEqual(item, expect);
    });

    this.children.forEach(child => child.updateExpandedByKeys(expandedKeys));

    if (expanded) {
      this.expanded = 1;
    } else if (!this.manualExpand && this.root.config.expandParent) {
      this.expanded = this.children.some(child => child.expanded === 1) ? 1 : 0;
    } else {
      this.expanded = 0;
    }
  }

  handleExpandedChange(key, expanded) {
    if (key === this.key) {
      this.expand(expanded ? 1 : 0);
    } else {
      this.children.forEach(child => child.handleExpandedChange(key, expanded));
    }
  }

  expand(expanded) {
    if (expanded && this.root.config.closeSiblings) {
      this.parent && this.parent.expandChildren(0);
    }
    this.expanded = expanded;
    this.manualExpand = true;
  }

  expandChildren(expanded) {
    this.children.forEach(child => child.expand(expanded));
  }
}
