import React from 'react';
import domHelper from '../../utils/dom-helper';

export default function () {
  let aniT;

  const timeout = function(fn, dur) {
    clearTimeout(aniT);
    aniT = setTimeout(fn, dur);
  };

  const cancel = function() {
    clearTimeout(aniT);
  };

  return ({
    onEnter({ domNode }) {
      const height = domNode.offsetHeight;
      const style = {
        height: 0,
        overflow: 'hidden'
      };

      domHelper.css(domNode, style);
      domNode.dataset.height = height;
    },

    onEntering({ domNode, params, done }) {
      const { dur, easing } = params;

      const style = {
        height: domNode.dataset.height + 'px',
        overflow: 'hidden',
        transition: `all ${dur}ms ${easing}`
      };

      timeout(function () {
        domHelper.css(domNode, style);
        timeout(done, dur);
      }, 10);
    },

    onEntered({ domNode }) {
      domHelper.css(domNode, {
        height: '',
      });
    },

    onExit({ domNode, params }) {
      const { dur, easing } = params;
      const height = domNode.offsetHeight;

      const style = {
        height: height + 'px',
        overflow: 'hidden',
        transition: `all ${dur}ms ${easing}`
      };

      domHelper.css(domNode, style);
    },

    onExiting({ domNode, params, done }) {
      const { dur } = params;

      const style = {
        height: 0,
        overflow: 'hidden'
      };

      timeout(function () {
        domHelper.css(domNode, style);

        timeout(done, dur);
      }, 10);
    },

    onExited({ domNode }) {
      domHelper.css(domNode, {
        height: '',
      });
    },

    onCancel() {
      cancel();
    },


    getChildren(children, status) {
      return children;
      if (status === 'entered' || status === 'exited') {
        return children;
      }
      return (
        <div>{ children }</div>
      );
    }

  });
}
