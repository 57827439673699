import React from 'react';

const defaultFn = v => v;

export function mapContent(children, mapFn = defaultFn) {
  const SubMenu = require('./sub-menu').default;
  const MenuItem = require('./menu-item').default;
  return React.Children.map(children, child => {
    if (child && (child.type !== SubMenu && child.type !== MenuItem)) {
      return mapFn(child);
    }
    return null;
  });
}

export function mapItems(children, mapFn = defaultFn) {
  const SubMenu = require('./sub-menu').default;
  const MenuItem = require('./menu-item').default;
  return React.Children.map(children, child => {
    if (child && (child.type === SubMenu || child.type === MenuItem)) {
      return mapFn(child);
    }
    return null;
  });
}
