import React from 'react';

export function getArrayChildren(children) {
  let arr = [];
  let key = -1;

  if (!children) {
    arr = [];
  } else {
    React.Children.forEach(children, child => {
      if (child && typeof child === 'object') {
        let keyChild = child;
        if (!child.key && React.isValidElement(child)) {
          key += 1;
          keyChild = React.cloneElement(child, {
            key
          });
        }
        arr.push(keyChild);
      }
    });
  }

  return arr;
}

export function findChildInChildrenByKey(children, key) {
  let ret = null;
  if (children) {
    children.forEach((child) => {
      if (ret) {
        return;
      }
      if (child && child.key === key) {
        ret = child;
      }
    });
  }
  return ret;
}

export function findChildInArrayByKey(arr, key, map) {

  for (let i = 0; i < arr.length; i++) {
    const child = map ? map(arr[i]) : arr[i];
    if (child && child.key === key) { 
      return child;
    }
  }

  return null;
}

export function mergeChildren(prev, next) {
  let ret = [];

  const nextChildrenPending = {};
  let pendingChildren = [];
  prev.forEach((child) => {
    if (child && findChildInChildrenByKey(next, child.key)) {
      if (pendingChildren.length) {
        nextChildrenPending[child.key] = pendingChildren;
        pendingChildren = [];
      }
    } else {
      pendingChildren.push(child);
    }
  });

  next.forEach((child) => {
    if (child && Object.prototype.hasOwnProperty.call(nextChildrenPending, child.key)) {
      ret = ret.concat(nextChildrenPending[child.key]);
    }
    ret.push(child);
  });

  ret = ret.concat(pendingChildren);

  return ret;
}
