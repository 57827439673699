import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MenuContext from './menu-context';
import Icon from '../icon';
import TreeNodeModel from './tree-node-model';
import { mapContent, mapItems } from './menu-utils';

/**
 * @visibleName Menu.Item 菜单项
 */
export default class Item extends React.Component {
  static propTypes = {
    /** 
     * 唯一标识
     */
    index: PropTypes.string,
    /** 
     * 菜单项图标
     */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * @ignore
     */
    isMenu: PropTypes.bool,
    /**
     * @ignore
     */
    menuItems: PropTypes.node
  }
  
  static contextType = MenuContext;

  constructor(props) {
    super(props);
    const { parentModel, isMenu, dataActive, index } = props;
    this.state = {
      collapseExpanded: false
    };
    this.model = new TreeNodeModel(index, !isMenu, null, this.onModelApply, dataActive);
    this.model.mount(parentModel);
  }

  componentWillUnmount() {
    this.model.unMount();
  }

  onModelApply = () => {
    const isMounted = this.updater.isMounted(this);
    isMounted && this.forceUpdate();
  }

  onMouseOver = () => {
    this.setState({
      collapseExpanded: true
    });
  }

  onMouseLeave = () => {
    this.setState({
      collapseExpanded: false
    });
  }

  onContentClick = () => {
    const { isMenu } = this.props;
    if (isMenu) {
      this.handleMenuClick();
    } else {
      this.handleItemClick();
    }
  }

  // 子菜单点击
  handleMenuClick() {
    this.model.toggleExpand(true, this.context.closeSiblings);
    this.context.setExpanded(this.props.index, Boolean(this.model.expanded));
  }

  // 菜单项点击
  handleItemClick() {
    const { index } = this.props;
    if (typeof index !== 'undefined') {
      this.context.setActive(index);
    }
  }

  renderChildren() {
    const { children } = this.props;
    return mapItems(children, child => React.cloneElement(child, {
      parentModel: this.model
    }));
  }

  renderContent() {
    const { children } = this.props;
    return mapContent(children);
  }

  render() {
    const { icon, isMenu, onClick } = this.props;
    const { collapseExpanded } = this.state;
    const { mode, dataCollapsed } = this.context;
    const { deep, status: { selected, expanded } } = this.model;
    const isSelected = !isMenu && selected === 1;
    const isHalfSelected = isMenu && selected !== 0;
    const isCollapsed = mode === 'horizontal' || dataCollapsed;
    const paddingDeep = deep - (isCollapsed ? 1 : 0);
    const isCollapsedDeep0 = deep === 0 && isCollapsed;
    const isCollapsedDeep0Submenu = isCollapsedDeep0 && isMenu;
    const realExpanded = isCollapsedDeep0Submenu ? collapseExpanded : expanded;
    const itemEvents = { onClick };

    if (isCollapsedDeep0Submenu && isMenu) {
      Object.assign(itemEvents, {
        onMouseOver: this.onMouseOver,
        onMouseLeave: this.onMouseLeave,
      });
    }

    let menuIcon;

    if (icon) {
      menuIcon = typeof icon === 'string' ? <Icon type={icon} /> : icon;
    }

    return (
      <div
        className={classNames(
          'ten-menu-item', 
          `ten-menu-item--deep-${deep}`,
          `ten-menu-item--paddingdeep-${paddingDeep}`,
          {
            'ten-menu-item--ismenu': isMenu, 
            'ten-menu-item--selected': isSelected,
            'ten-menu-item--childrenselected': isHalfSelected
          }
        )}
        {...itemEvents}
      >
        <div
          className={classNames(
            'ten-menu-item__content',
            {
              'ten-menu-item__content--selected': isSelected,
              'ten-menu-item__content--childrenselected': isHalfSelected,
              'ten-menu-item__content--expanded': expanded === 1,
            }
          )}
          onClick={this.onContentClick}
        >
          <div
            className="ten-menu-item__contentinner"
          >
            {
              menuIcon && (
                <span
                  className="ten-menu-item__content-icon"
                >
                  {menuIcon}
                </span>
              )
            }

            <span className="ten-menu-item__content-content">
              { this.renderContent() }
            </span>
            {
              isMenu && (
                <Icon
                  className="ten-menu-item__content-carret"
                  type="carret_down"
                />
              )
            }
          </div>
        </div>
        {
          isMenu && (
            <div
              className="ten-menu-item__submenu"
              style={{ display: realExpanded ? '' : 'none' }}
            >
              { this.renderChildren() }
            </div>
          )
        }

      </div>

    );

  }
}
