import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../button';

class PageItem extends React.Component {
  static propTypes = {
    displayPageNumber: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func
  };

  static defaultProps = {
    onPageChange: () => {}
  };

  onClickHandler = (e) => {
    const { onPageChange, displayPageNumber } = this.props;
    onPageChange && onPageChange(displayPageNumber, e);
  }

  render() {
    const { displayPageNumber, currentPage, onPageChange, ...others } = this.props;

    const className = classNames('ten-pagination__page-item', {
      'ten-pagination__page-item--active': currentPage === displayPageNumber
    });

    return <Button {...others} size="small" className={className} onClick={this.onClickHandler}>{displayPageNumber}</Button>;
  }
}

export default PageItem;