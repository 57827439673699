import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox';
import SelectItem from './select-item';
import SelectSearch from './select-search';

class SelectBox extends React.Component {
  static propTypes = {
    prefixClass: PropTypes.string,
    index: PropTypes.number,
    data: PropTypes.array,
    selectKeys: PropTypes.array,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSearch: PropTypes.func,
    searchFlag: PropTypes.bool,
    unit: PropTypes.string,
    title: PropTypes.string,
    searchValue: PropTypes.string,
    notFound: PropTypes.node
  };

  static defaultProps = {
    data: [],
    selectKeys: [],
    disabled: false,
    notFound: <span>暂无数据</span>
  };

  render() {
    const {
      prefixClass,
      disabled,
      data,
      unit,
      selectKeys,
      title,
      searchFlag,
      searchValue,
      onSearch,
      onSelect,
      onSelectAll,
      index,
      notFound
    } = this.props;

    const prefixClassName = `${prefixClass}__select-box`;
    const className = classNames(prefixClassName, {
      [`${prefixClassName}--disabled`]: disabled
    });

    const keys = data.filter((item) => !item.disabled).map((item) => item.key);
    const allChecked =
      selectKeys.length && selectKeys.every((key) => keys.some((k) => k === key)) && selectKeys.length === keys.length;

    const headerClassName = classNames(`${prefixClassName}-header`, {
      [`${prefixClassName}-header--disabled`]: disabled
    });
    const header = (
      <div className={headerClassName}>
        <Checkbox
          onChange={disabled ? null : (e) => onSelectAll(index, e)}
          checked={allChecked}
          indeterminate={selectKeys.length}
          disabled={disabled}
        >
          {selectKeys.length ? `${selectKeys.length}/` : ''}
          {`${data.length}${unit}`}
        </Checkbox>
        <span className={`${prefixClassName}-header-title`}>{title}</span>
      </div>
    );

    const search = (
      <SelectSearch
        prefixClass={prefixClass}
        value={searchValue}
        disabled={disabled}
        onChange={(e) => onSearch(index, e)}
      />
    );
    const list = data.map((item) => {
      if (searchValue && item.title.indexOf(searchValue) === -1) {
        return null;
      }
      return (
        <SelectItem
          key={item.key}
          prefixClass={`${prefixClassName}-main-content`}
          item={item}
          disabled={disabled}
          checked={selectKeys.some((key) => key === item.key)}
          onChange={(e) => onSelect(index, e)}
        />
      );
    });

    const notFoundNode = list.length === 0 && <div className={`${prefixClassName}-main-notfound`}>{notFound}</div>;

    const mainClassName = classNames(`${prefixClassName}-main`, {
      [`${prefixClassName}-main--search`]: searchFlag
    });

    return (
      <div className={className}>
        {header}
        <div className={mainClassName}>
          {searchFlag && search}
          <div className={`${prefixClassName}-main-content`}>
            {!notFoundNode ? <ul className={`${prefixClassName}-main-content-list`}>{list}</ul> : notFoundNode}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectBox;
