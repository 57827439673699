import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../tooltip';


/**
 * @visibleName Slider 滑动选择器
 */
class Slider extends React.Component {

  static propTypes = {
    /**
     * 设置当前取值。当 range 为 false 时，使用 number，否则用 [number, number]
     */
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ]),
    /**
     * 初始值, 当 range 为 false 时，defaultValue 默认为 0 ；当 range 为 true 时，defaultValue 默认为 [0, 100]
     */
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ]),
    /**
     * 双滑块模式
     */
    range: PropTypes.bool,
    /**
     * 滑块是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * 当 Slider 发生改变时，触发 onChange 事件，并把 Slider 当前值传入，当 range 为 true 是，传入数组形式 [number, number]
     */
    onChange: PropTypes.func,
    /**
     * 刻度标记
     */
    marks: PropTypes.array,
    /**
     * 自定义 Tooltip 内容 , renderTip , 当 renderTip 为 null 时则隐藏 Tooltip
     */
    renderTip: PropTypes.func,
    /**
     * 是否始终显示 Tooltip , 当值为 true 时 , Tooltip 始终显示, 当值为 false 时 , Tooltip 始终隐藏
     */
    showTooltip: PropTypes.bool,
    /**
     * 步长 , 必须大于 0
     */
    step: PropTypes.number,
  };

  static defaultProps = {
    range: false,
    defaultValue: 0,
    disabled: false,
    step: 1,
  };

  constructor (props) {
    super(props);
    this.state = {
      sliderDomLeft: 0,
      sliderDomWidth: 0,
      canMoveLeft: false,
      canMoveRight: false,
      barLeftWidth: 0,
      barRightWidth: 100,
      leftTooltipShow: false,
      rightTooltipShow: false,
      effectiveLeave: true,
    };
    this.sliderDom = React.createRef();
  }

  static getDerivedStateFromProps(nextProps) {
    const { value, step } = nextProps;
    const stepNum = step <= 0 ? 1 : step;

    let multiple1;
    let multiple2;
    if (typeof (value) === 'number') {
      multiple1 = Math.round(value / stepNum);
      return {
        barRightWidth: 100 - (stepNum * multiple1)
      };
    }
    if (typeof (value) === 'object' && value !== null) {
      value.length = 2;
      const newDefaultValue = [Math.min.apply(null, value), Math.max.apply(null, value)];
      multiple1 = Math.round(newDefaultValue[0] / stepNum);
      multiple2 = Math.round(newDefaultValue[1] / stepNum);
      return {
        barLeftWidth: (stepNum * multiple1),
        barRightWidth: 100 - (stepNum * multiple2)
      };
    }
    return null;
  }

  componentDidMount () {
    const { range, defaultValue, step } = this.props;
    const stepNum = step <= 0 ? 1 : step;

    setTimeout(() => {
      this.setState({
        sliderDomLeft: this.sliderDom.current.getBoundingClientRect().left,
        sliderDomWidth: this.sliderDom.current.offsetWidth
      });
    }, 100);

    let newDefaultValue;
    let multiple1;
    let multiple2;
    if (range) {
      if (typeof (defaultValue) === 'object') {
        defaultValue.length = 2;
        multiple1 = Math.round(Math.min.apply(null, defaultValue) / stepNum);
        multiple2 = Math.round(Math.max.apply(null, defaultValue) / stepNum);
        newDefaultValue = [(stepNum * multiple1), (stepNum * multiple2)];
      } else {
        newDefaultValue = [0, 100];
      }
      this.setState({
        barLeftWidth: newDefaultValue[0],
        barRightWidth: (100 - newDefaultValue[1])
      });
    } else {
      if (Array.isArray(defaultValue)) {
        multiple1 = Math.round(defaultValue[0] / stepNum);
      } else {
        multiple1 = Math.round(defaultValue / stepNum);
      }
      newDefaultValue = stepNum * multiple1;
      this.setState({
        barRightWidth: (100 - newDefaultValue)
      });
    }

    window.addEventListener('resize', this.onResizes, false);

  }

  componentWillUnmount () {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('resize', this.onResizes);
  }

  onClick = (e) => {
    const { disabled, range } = this.props;
    const { sliderDomLeft, sliderDomWidth, barLeftWidth, barRightWidth } = this.state;

    if (!disabled) {
      const calculated = ((e.pageX - sliderDomLeft) / sliderDomWidth) * 100;

      let moveDistance;
      if (calculated < 0) {
        moveDistance = 0;
      } else if (calculated > 100) {
        moveDistance = 100;
      } else {
        moveDistance = this.getStep(calculated);
      }

      const leftDistance = calculated - barLeftWidth;
      const rightDistance = (100 - barRightWidth) - calculated;
      if (range) {
        if (leftDistance <= 0 || rightDistance > leftDistance) {
          this.setState({ barLeftWidth: moveDistance });
        } else {
          this.setState({ barRightWidth: 100 - moveDistance });
        }
      } else {
        this.setState({ barRightWidth: 100 - moveDistance });
      }
    }
    setTimeout(() => {
      this.setState({
        canMoveLeft: false,
        canMoveRight: false,
      });
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);

      this.onChange();
    }, 100);
  }

  onMouseDown = (value) => {
    const { disabled } = this.props;
    const { barLeftWidth, barRightWidth } = this.state;

    if (!disabled) {
      this.setState({ effectiveLeave: false });

      value === 1 ? this.setState({ canMoveLeft: true, leftTooltipShow: true }) : this.setState({ canMoveRight: true, rightTooltipShow: true });

      if (Math.round(100 - barRightWidth) - Math.round(barLeftWidth) === 0) {
        this.setState({
          canMoveLeft: true,
          canMoveRight: true,
        });
      }

      window.addEventListener('mousemove', this.onMouseMove, false);
      window.addEventListener('mouseup', this.onMouseUp, false);
    }
  }

  onMouseMove = (e) => {
    const {
      sliderDomLeft,
      sliderDomWidth,
      canMoveLeft,
      canMoveRight,
      barLeftWidth,
      barRightWidth
    } = this.state;

    const calculated = ((e.pageX - sliderDomLeft) / sliderDomWidth) * 100;

    let moveDistance;
    if (calculated < 0) {
      moveDistance = 0;
    } else if (calculated > 100) {
      moveDistance = 100;
    } else {
      moveDistance = this.getStep(calculated);
    }

    // 判断移动哪个点
    if (canMoveLeft) {
      if (moveDistance >= (100 - barRightWidth)) {
        this.setState({
          canMoveLeft: false,
          canMoveRight: true,
          leftTooltipShow: false,
          rightTooltipShow: true,
          barLeftWidth: (100 - barRightWidth),
        });
      } else {
        this.setState({
          barLeftWidth: moveDistance,
        });
      }
    }
    if (canMoveRight) {
      if (moveDistance <= barLeftWidth) {
        this.setState({
          canMoveLeft: true,
          canMoveRight: false,
          leftTooltipShow: true,
          rightTooltipShow: false,
          barRightWidth: (100 - barLeftWidth),
        });
      } else {
        this.setState({
          barRightWidth: (100 - moveDistance),
        });
      }
    }
  }

  onMouseUp = () => {
    this.setState({
      canMoveLeft: false,
      canMoveRight: false,
      leftTooltipShow: false,
      rightTooltipShow: false,
      effectiveLeave: true,
    });
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);

    this.onChange();
  }

  onChange = () => {
    const { onChange, range } = this.props;
    const { barLeftWidth, barRightWidth } = this.state;

    const returnVal = range ? [Math.round(barLeftWidth), Math.round(100 - barRightWidth)] : Math.round(100 - barRightWidth);
    onChange && onChange(returnVal);
  }

  onResizes = () => {
    setTimeout(() => {
      if (this.sliderDom && this.sliderDom.current) {
        this.setState({
          sliderDomLeft: this.sliderDom.current.offsetLeft,
          sliderDomWidth: this.sliderDom.current.offsetWidth
        });
      }
    }, 100);
  }

  getMark = () => {
    const { marks, className } = this.props;
    const { barLeftWidth, barRightWidth } = this.state;

    const marksDistance = 100 / (marks.length - 1);
    const markElem = marks.map((key, i) => {
      const markLeft = marksDistance * i;
      const classes = classNames(className, 'ten-slider__mark_i', {
        'ten-slider__mark_i--focus': markLeft >= barLeftWidth && markLeft <= (100 - barRightWidth)
      });
      const markEl = <span className={classes} key={i} style={{ left: markLeft + '%' }}>{key}</span>;
      return markEl;
    });

    return markElem;
  }

  getTooltip = () => {
    const { renderTip } = this.props;
    const { barLeftWidth, barRightWidth } = this.state;

    const leftTooltipElem = renderTip(Math.round(barLeftWidth));
    const rightTooltipElem = renderTip(Math.round(100 - barRightWidth));
    const TooltipElem = {
      left: leftTooltipElem,
      right: rightTooltipElem
    };
    return TooltipElem;
  }

  barClick = (e) => {
    e.stopPropagation();
  }

  onMouseEnter = (val) => {

    if (val === 1) {
      this.setState({
        leftTooltipShow: true,
      });
    } else {
      this.setState({
        rightTooltipShow: true,
      });
    }
  }

  onMouseLeave = () => {
    const { effectiveLeave } = this.state;
    effectiveLeave && this.setState({
      leftTooltipShow: false,
      rightTooltipShow: false,
    });
  }

  getStep = (num) => {
    const { step } = this.props;
    const stepNum = step <= 0 ? 1 : step;

    if (step === 1) return num;

    const multiple = Math.round(num / stepNum);

    return (multiple * stepNum);
  }

  render() {
    // console.log(this.getStep([12, 45]));
    const { renderTip, range, marks, disabled, className, showTooltip, ...others } = this.props;
    const { barLeftWidth, barRightWidth, leftTooltipShow, rightTooltipShow } = this.state;

    const leftTooltipClassName = 'ten-slider__indicator ten-slider__leftindicator';
    const rightTooltipClassName = 'ten-slider__indicator ten-slider__rightindicator';
    let leftTooltipMsg;
    let rightTooltipMsg;

    if (renderTip) {
      const TooltipElem = this.getTooltip();
      leftTooltipMsg = TooltipElem.left;
      rightTooltipMsg = TooltipElem.right;
    } else {
      leftTooltipMsg = Math.round(barLeftWidth);
      rightTooltipMsg = Math.round(100 - barRightWidth);
    }

    const Tooltipshow = typeof (showTooltip) === 'boolean';
    const leftTooltipElem = <Tooltip message={leftTooltipMsg} show={Tooltipshow ? showTooltip : leftTooltipShow} dark><div className={leftTooltipClassName} onMouseEnter={() => this.onMouseEnter(1)} onMouseLeave={this.onMouseLeave} /></Tooltip>;
    const rightTooltipElem = <Tooltip message={rightTooltipMsg} show={Tooltipshow ? showTooltip : rightTooltipShow} dark><div className={rightTooltipClassName} onMouseEnter={() => this.onMouseEnter(2)} onMouseLeave={this.onMouseLeave} /></Tooltip>;

    const classes = classNames(className, 'ten-slider', {
      'ten-slider--disabled': disabled,
      'ten-slider--tootipshow': showTooltip === true || leftTooltipShow === true || rightTooltipShow === true,
    });

    const leftbar = <div className="ten-slider__bar ten-slider__leftbar" onClick={this.barClick} onMouseDown={() => this.onMouseDown(1)} style={{ width: barLeftWidth + '%' }}>{leftTooltipElem}</div>;
    const rightbar = <div className="ten-slider__bar ten-slider__rightbar" onClick={this.barClick} onMouseDown={() => this.onMouseDown(2)} style={{ width: barRightWidth + '%' }}>{rightTooltipElem}</div>;

    return (
      <div className={classes} ref={this.sliderDom} onClick={this.onClick} {...others}>
        <div className="ten-slider__background" />
        <div className="ten-slider__container">
          {range && leftbar}
          {rightbar}
          <div className="ten-slider__mark">
            { marks && this.getMark() }
          </div>
        </div>
      </div>
    );
  }
}

export default Slider;
