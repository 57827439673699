// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement, forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7.35 8.65v3.85h1.3V8.65h3.85v-1.3H8.65V3.5h-1.3v3.85H3.5v1.3h3.85z","fillOpacity":0.9}}]};

const AddIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'add',
    ref,
    icon: element,
  },
));

AddIcon.displayName = 'AddIcon';

export default AddIcon;
