import React from 'react';

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setStateSafely(...args) {
    if (this.mounted) {
      this.setState(...args);
    }
  }
}
