import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @visibleName Checkbox 多选框
 */
class Checkbox extends React.Component {
  static propTypes = {
    /**
     * 默认是否选中
     */
    defaultChecked: PropTypes.bool,
    /**
     * 是否选中
     */
    checked: PropTypes.bool,
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * 状态改变时触发回调方法
     */
    onChange: PropTypes.func,
    /**
     * 获得焦点时回调方法
     */
    onFocus: PropTypes.func,
    /**
     * 失去焦点时回调方法
     */
    onBlur: PropTypes.func,
    /**
     * 选框值
     */
    value: PropTypes.any,
    /**
     * 选框名
     */
    name: PropTypes.string,
    /**
     * 是否自动获得焦点
     */
    autoFocus: PropTypes.bool,
    /**
     * 样式控制，应用于未全部选中的效果。
     */
    indeterminate: PropTypes.bool
  };

  static defaultProps = {
    defaultChecked: false,
    autoFocus: false,
    checked: false,
    disabled: false,
    indeterminate: false
  };

  static contextTypes = {
    checkboxGroup: PropTypes.any
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (typeof nextProps.checked !== 'undefined' && nextProps.checked !== prevState.checked) {
      return { checked: nextProps.checked };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const checked = 'checked' in props ? !!props.checked : props.defaultChecked;
    this.state = {
      checked,
      isFocus: false
    };
  }

  onChange = e => {
    const { disabled, onChange } = this.props;
    if (disabled) {
      return;
    }
    if (!('checked' in this.props)) {
      this.setState({
        checked: e.target.checked
      });
    }
    onChange && onChange(e);
  };

  onFocus = e => {
    const { onFocus } = this.props;
    this.setState({ isFocus: true });
    onFocus && onFocus(e);
  };

  onBlur = e => {
    const { onBlur } = this.props;
    this.setState({ isFocus: false });
    onBlur && onBlur(e);
  };

  static propTypes = {};

  render() {
    const {
      children,
      className: propClassName,
      style,
      name,
      value,
      disabled: propDisabled,
      indeterminate,
      defaultChecked,
      ...restProps
    } = this.props;
    const { isFocus, checked: stateChecked } = this.state;
    const { checkboxGroup } = this.context;

    const disabled = propDisabled || (checkboxGroup && checkboxGroup.disabled);
    const onChange = checkboxGroup ? checkboxGroup.onChange : this.onChange;
    const checked = checkboxGroup ? checkboxGroup.value.some(val => val === value) : stateChecked;

    const prefixClass = 'ten-checkbox';
    const className = classNames(prefixClass, propClassName, {
      [`${prefixClass}--checked`]: checked,
      [`${prefixClass}--disabled`]: disabled,
      [`${prefixClass}--focus`]: isFocus,
      [`${prefixClass}--indeterminate`]: !checked && indeterminate
    });

    const inputProps = {
      ...restProps,
      className: `${prefixClass}__input`,
      type: 'checkbox',
      name,
      disabled,
      value,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      ref: this.saveInput,
      checked,
      onChange: disabled ? undefined : onChange
    };

    /* eslint-disable */
    return (
      <label className={className} style={style}>
        <span className={`${prefixClass}__checkbox`}>
          <input {...inputProps} />
          <i className={`${prefixClass}__checkbox-inner`} />
        </span>
        {children !== undefined && <span>{children}</span>}
      </label>
    );
    /* eslint-enable */
  }
}

export default Checkbox;
