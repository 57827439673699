import React from 'react';
import PropTypes from 'prop-types';
import TimeOptions from '../basic/time-options';
import { 
  getMeridiem, 
  getHours,
  getMintues,
  getSeconds,
  getDateObj, 
  getFormatInfo,
  parseToDate,
  hour24To12 } from '../util';

function focusIndexNeedToFix(type, value) {
  return type.length < value.toString().length ? value.toString().length - type.length : 0;
}

function fixFocusIndex(tokens) {

  const result = {};

  tokens.reduce((accOffset, token, currIndex, src) => {
    const { name, index } = token;
    
    if (currIndex) {
      const prevToken = src[currIndex - 1];
      const { value, 0: type } = prevToken;
      const offset = focusIndexNeedToFix(type, value);
      if (offset || accOffset) {
        accOffset += offset;
        result[`${name}TypeIndex`] = accOffset + index;
        return accOffset;
      }      
    } 
    
    result[`${name}TypeIndex`] = index;
    return accOffset;
  }, 0);
  
  return result;
}

class Time extends React.Component {
  static propTypes = {
    value: PropTypes.instanceOf(Date),
    format: PropTypes.string,
    maxTime: PropTypes.instanceOf(Date),
    minTIme: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    relativetimeinput: PropTypes.object,
    disabledHour: PropTypes.func,
    disabledMin: PropTypes.func,
    disabledSec: PropTypes.func
  };

  static getDerivedStateFromProps(props) {
    const { value, format } = props;
    const formatInfo = getFormatInfo(format);
    const is12 = /h{1,2}/.test(formatInfo.hourFormat[0]) && formatInfo.meridiemFormat[0];
    return { ...getDateObj(value), ...formatInfo, is12 };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeHandler(value, type) {
    const { onChange } = this.props;
    const hours = ['HH', 'H'].indexOf(type) > -1 ? value : this.state.hours;
    const hours12 = ['hh', 'h'].indexOf(type) > -1 ? value : hour24To12(this.state.hours);
    const minutes = ['mm', 'm'].indexOf(type) > -1 ? value : this.state.minutes;
    const seconds = ['ss', 's'].indexOf(type) > -1 ? value : this.state.seconds;
    const meridiem = ['a', 'A'].indexOf(type) > -1 ? value : this.state.meridiem;

    const { year, month, date, is12 } = this.state;

    const dateStr = `${year}-${month + 1}-${date} ${is12 ? hours12 : hours}:${minutes}:${seconds} ${is12 ? meridiem : ''}`;
    const format = `YYYY-M-D ${is12 ? 'h' : 'H'}:m:s ${is12 ? 'A' : ''}`;

    onChange && onChange(parseToDate(dateStr, format));
  }

  focusTimeInput(type, index, value) {
    const { relativetimeinput } = this.props;
    const rawInput = relativetimeinput;
    rawInput.focus();
    rawInput.selectionStart = index;
    let len;
    if (typeof value === 'number') {  
      len = type.length === 2 && value < 10 ? value.toString().length + 1 : value.toString().length;
    } else {
      len = value.length;
    }
    rawInput.selectionEnd = index + len;
  }

  render() {
    const { style, disabledHour, disabledMin, disabledSec, minTime } = this.props;

    const { hourFormat, minFormat, secFormat, meridiemFormat, is12 } = this.state;

    const { 0: hourType } = hourFormat;
    const { 0: minType } = minFormat;
    const { 0: secType } = secFormat;
    const { 0: meridiemType } = meridiemFormat;

    const { hours, minutes, seconds, meridiem } = this.state;

    const { hours: minH, minutes: minM, seconds: minS } = getDateObj(minTime);

    const tokens = [
      { value: hours, name: 'hour', ...hourFormat },
      { value: minutes, name: 'min', ...minFormat }, 
      { value: seconds, name: 'sec', ...secFormat },
      { value: meridiem, name: 'meridiem', ...meridiemFormat }, 
    ].filter(v => v[0]).sort((a, b) => a.index - b.index);

    const { hourTypeIndex, minTypeIndex, secTypeIndex, meridiemTypeIndex } = fixFocusIndex(tokens);

    const hoursList = getHours({ hourType, meridiem }, { disabledHour, minH }, hours);
    const minutesList = getMintues(hours, { disabledMin, minM, minH }, minutes);
    const secondsList = getSeconds({ hours, minutes }, { disabledSec, minM, minH, minS }, seconds);
    const meridiemList = getMeridiem(meridiemType, { disabledHour, minH }, meridiem);
    
    return (
      <div className="ten-time" style={style}>
        { hourType && (
          <TimeOptions 
            className="ten-time__item" 
            topItemIndex={hoursList.findIndex(v => v.selected)}
            optionList={hoursList} 
            onChange={v => {
              this.onChangeHandler(v, hourType); 
            }}
            onMouseEnter={() => {
              this.focusTimeInput(hourType, hourTypeIndex, hours); 
            }}
          /> 
        )
        }
        { minType && (
          <TimeOptions 
            className="ten-time__item" 
            topItemIndex={minutesList.findIndex(v => v.selected)}
            optionList={minutesList} 
            onChange={v => {
              this.onChangeHandler(v, minType); 
            }}
            onMouseEnter={() => {
              this.focusTimeInput(minType, minTypeIndex, minutes); 
            }}
          /> 
        )
        }
        { secType && (
          <TimeOptions 
            className="ten-time__item" 
            topItemIndex={secondsList.findIndex(v => v.selected)}
            optionList={secondsList} 
            onChange={v => {
              this.onChangeHandler(v, secType); 
            }}
            onMouseEnter={() => {
              this.focusTimeInput(secType, secTypeIndex, seconds); 
            }}
          /> 
        )
        }
        { is12 && (
          <TimeOptions 
            className="ten-time__item" 
            topItemIndex={meridiemList.findIndex(v => v.selected)}
            optionList={meridiemList} 
            onChange={v => {
              this.onChangeHandler(v, meridiemType); 
            }}
            onMouseEnter={() => {
              this.focusTimeInput(meridiemType, meridiemTypeIndex, meridiem); 
            }}
          /> 
        )
        }
      </div>
    );
  }
}

export default Time;