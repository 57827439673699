import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Component from '../utils/component';
import Popup from '../popup';
import InputSelect from '../input/input-select';
import Icon from '../icon';
import Checkbox from '../checkbox';
import Loading from '../loading';
import Status from '../status';

function getOptionInfo(optionData, keyName) {
  if (typeof keyName === 'function') {
    return keyName(optionData);
  }
  if (typeof optionData !== 'object') {
    return optionData;
  }
  return optionData[keyName];
}

function isOptionDisabled(optionData, disabledKeyName) {
  if (typeof disabledKeyName === 'function') {
    return disabledKeyName(optionData);
  }
  if (typeof optionData === 'object' && disabledKeyName) {
    return optionData[disabledKeyName];
  }
  return false;
}

/**
 * prop value to state value
 * state value 为选中的 key 值（统一为 Array）
 * @params propValue { array | item }
 * @params props
 */
function getStateValue(propValue, { valueType, multiple, optionKey }) {
  let value = [];

  if (typeof propValue === 'undefined') {
    return value;
  }

  if (multiple) {
    value = Array.isArray(propValue) ? propValue : [propValue];
  } else {
    value = [propValue];
  }

  if (!value[0] && value[0] !== 0) {
    value = [];
  }

  if (valueType === 'data') {

    value = value.map(item => {
      const key = getOptionInfo(item, optionKey);
      return key;
    });
  }

  return value;
}

/**
 * @visibleName Select 下拉选择
 */
class Select extends Component {

  static propTypes = {
    /**
     * 选项 data
     */
    options: PropTypes.array,
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * 选项为 0 条时的提示，异步获取选项时适用
     */
    emptyTip: PropTypes.node,
    /**
     * 尺寸
     */
    size: PropTypes.oneOf(['default', 'small']),
    /**
     * 是否多选
     */
    multiple: PropTypes.bool,
    /**
     * 多选时最多选择多少项
     */
    max: PropTypes.number,
    /**
     * placeholder
     */
    placeholder: PropTypes.string,

    /**
     * 选中 key，多选为数组
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    /**
     * 初始选中 value
     */
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    /**
     * value 类型 key or 选中选项的 data 数据
     */
    valueType: PropTypes.oneOf(['key', 'data']),
    /**
     * 从 data （optionData）中获取 key，可以是返回键值的函数，也可以是字符串形式的键名
     * @param optionData
     */
    optionKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /**
     * 从 data （optionData）中获取文本，也用与选中框显示（只支持文本）<br/>
     * 也可以是字符串形式的键名
     * 如需另外对 option 内容定制，可以指定 renderOption (可返回 react element)
     * @param optionData
     */
    optionText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /**
     * 渲染更丰富的 option（react element），区别于 optionText  <br/>
     * 因选中框只支持文本，需另指定 optionText 用于指定选中文本
     * @param optionData
     */
    renderOption: PropTypes.func,
    /**
     * 从 data （optionData）中获取 key，可以是返回键值的函数，也可以是字符串形式的键名
     * @param optionData
     */
    optionDisabled: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /**
     * 选中值变化回调
     * @param key 选中的 key <br>
     * @param optionData 选中 Option 的 data 
     */
    onChange: PropTypes.func,
    /**
     * 选项被选中或取消选中回调
     * @param optionData 选中 Option 的 data 
     * @param selected 是否选中
     */
    onSelect: PropTypes.func,
    /**
     * @param text 筛选输入变化回调，可以用来实现异步获取选项
     */
    onFilter: PropTypes.func,
    /**
     * 是否加载中，异步获取选项时适用
     */
    loading: PropTypes.bool,
    /**
     * 如果筛选选项为空，是否创建，触发 onCreate
     */
    creatable: PropTypes.bool,
    /**
     * creatable 为 true 时，用户选择创建之后的回调
     * @params filterText 输入框的筛选值
     */
    onCreate: PropTypes.func,
    /**
     * 自定义创建操作区
     * @params filterText 输入框的筛选值
     */
    renderCreate: PropTypes.func,
    /**
     * 设置为 true 则根据 optionText 的值自动筛选<br/>
     * 设置为 false 不作过滤操作 <br/>
     * 可以是函数<br/>
     * @param filterText 筛选输入值<br/>
     * @param optionData 对应 optionData
     */
    filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /**
     * 后缀图标，默认为 carret_down
     */
    suffixIcon: PropTypes.node,
    /**
     * 多选模式下是否在选项前显示 checkbox
     */
    optionCheckbox: PropTypes.bool,
    /**
     * 单选模式下支持清空
     */
    clearable: PropTypes.bool,
    /**
     * 清空回调
     */
    onClear: PropTypes.func,
    /**
     * onBlur 回调
     */
    onBlur: PropTypes.func,
    /**
     * onFocus 回调
     */
    onFocus: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    multiple: false,
    optionKey: key => key,
    optionText: key => key,
    optionDisabled: 'disabled',
    filterOption: false,
    emptyTip: <Status.Empty />,
    size: 'default',
    valueType: 'key',
    optionCheckbox: true
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value: propValue, optionKey, optionText, optionDisabled, onFilter, filterOption } = nextProps;
    const newState = {
      filterAble: onFilter || filterOption
    };

    if (nextProps.options !== prevState.propOptions) {
      newState.propOptions = nextProps.options;
      newState.filteredOptions = nextProps.options;
      newState.propOptionsKeys = [];
      newState.keyDataMap = { ...prevState.keyDataMap };
      nextProps.options.forEach(item => {
        const key = getOptionInfo(item, optionKey);
        newState.keyDataMap[key] = {
          data: item,
          text: getOptionInfo(item, optionText),
          disabled: isOptionDisabled(item, optionDisabled)
        };
        newState.propOptionsKeys.push(key);
      });
    }

    if ('value' in nextProps) {
      const value = getStateValue(propValue, nextProps);

      if (nextProps.valueType === 'data') {
        const propValueArr = Array.isArray(propValue) ? propValue : [propValue];
        newState.keyDataMap = newState.keyDataMap || { ...prevState.keyDataMap };

        propValueArr.forEach(item => {
          const key = getOptionInfo(item, optionKey);
          newState.keyDataMap[key] = { data: item, text: getOptionInfo(item, optionText) };
        });
      }
      newState.value = value;
    }

    return newState;
  }

  refInputComponent = null;

  refPopup = null;

  constructor(props) {
    super(props);
    const { initialValue, optionKey, optionText } = props;
    const value = getStateValue(initialValue, props);
    const keyDataMap = {};

    if (props.valueType === 'data' && 'initialValue' in props) {
      const propValueArr = Array.isArray(initialValue) ? initialValue : [initialValue];
      propValueArr.forEach(item => {
        const key = getOptionInfo(item, optionKey);
        keyDataMap[key] = { data: item, text: getOptionInfo(item, optionText) };
      });
    }

    this.state = {
      value,
      keyDataMap,
      focusIndex: 0,
      propOptionsKeys: [],
      popupShow: false,
      filterText: '',
      filteredOptions: [...props.options]
    };
  }

  isSelected = (key) => {
    const { value } = this.state;
    return value.indexOf(key) > -1;
  }

  getFilteredOptions(_filterText) {
    const filterText = _filterText.trim();
    const { filterOption } = this.props;
    const { propOptions, propOptionsKeys, keyDataMap } = this.state;
    let filteredOptions = propOptions;

    if (filterOption === true) {
      filteredOptions = propOptions.filter((option, i) => {
        const key = propOptionsKeys[i];
        const text = keyDataMap[key].text;
        if (text && text.indexOf(filterText) > -1) {
          return true;
        }
        return false;
      });
    } else if (typeof filterOption === 'function') {
      filteredOptions = propOptions.filter((option) => {
        if (filterOption(filterText, option)) {
          return true;
        }
        return false;
      });
    }
    return { filteredOptions };
  }

  onClick = () => {
    const { disabled } = this.props;
    const { filterAble } = this.state;

    if (disabled) {
      return;
    }
    this.setState({
      popupShow: filterAble ? true : !this.state.popupShow
    });
  }

  onCarretClick = (e) => {
    e.stopPropagation();
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.refInputComponent.focusInput();
    this.setState({
      popupShow: !this.state.popupShow
    });
  }

  onOptionClick = (key) => {
    const { multiple, max, onSelect } = this.props;
    const { value, keyDataMap } = this.state;
    const data = keyDataMap[key].data;
    let isSelected = true;

    let newValue = [...this.state.value];

    if (multiple) {
      const index = value.indexOf(key);
      if (index === -1) {
        newValue.push(key);

        while (max && newValue.length > max) {
          newValue.shift();
        }
      } else {
        isSelected = false;
        newValue.splice(index, 1);
      }
      this.refInputComponent.clearFilter();
    } else {
      newValue = [key];
      this.onPopupHide();
    }
    onSelect && onSelect(data, isSelected);
    this.refInputComponent.focusInput();
    this.update(newValue);
  }

  onTagDelete = (key) => {
    const newValue = this.state.value.filter(v => v !== key);
    this.update(newValue);
  }

  onFilter = text => {
    const { onFilter, filterOption } = this.props;

    if (filterOption) {
      const { filteredOptions } = this.getFilteredOptions(text);

      this.setStateSafely({
        filteredOptions
      });
    }
    this.setStateSafely({
      filterText: text,
    });
    onFilter && onFilter(text);
  }

  onCreate(text) {
    const { multiple } = this.props;
    const { onCreate } = this.props;

    if (onCreate) {
      onCreate(text);
      if (multiple) {
        this.refInputComponent.clearFilter();
        this.refInputComponent.focusInput();
      } else {
        this.onPopupHide();
      }
    }
  }

  onSelectKeyDown = (e) => {
    const { popupShow, focusIndex, filteredOptions } = this.state;
    const actionCount = this.getActionCount();
    const itemCount = filteredOptions.length;
    const total = actionCount + itemCount;


    const keyCode = e.keyCode;

    if (popupShow) {
      let newIndex;
      switch (keyCode) {
        case 13:
          if (focusIndex < total && focusIndex >= 0) {
            this.onOptionEnter(focusIndex);
          } else {
            this.onPopupHide();
          }
          break;
        case 38: // up
          newIndex = focusIndex <= 0 ? total - 1 : focusIndex - 1;

          this.setState({
            focusIndex: newIndex
          });
          e.preventDefault();
          break;
        case 40: // down
          newIndex = focusIndex >= total - 1 ? 0 : focusIndex + 1;

          this.setState({
            focusIndex: newIndex
          });
          e.preventDefault();
          break;

        default:
          break;
      }
    } else if (keyCode === 13) {
      this.onPopupShow();
    }
  }

  onOptionEnter(index) {
    if (this.refPopup) {
      const options = this.refPopup.querySelectorAll('.ten-select-option');
      options[index] && options[index].click();
    }
  }

  onInputFocus = (event) => {
    const { onFocus } = this.props;
    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }

  onInputBlur = (event) => {
    const { onBlur } = this.props;
    if (typeof onBlur === 'function') {
      onBlur(event);
    }
    this.onPopupHide();
  }

  onPopupHide = () => {
    if (!this.state.popupShow) {
      return;
    }
    const { options } = this.props;
    this.setStateSafely({ popupShow: false, filteredOptions: options, filterText: '', focusIndex: 0 });
    this.refInputComponent.clearFilter();
  }

  onPopupShow = () => {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({
        popupShow: true,
        focusIndex: 0
      });
    }
  }

  update(newValue) {
    const { multiple, options, onChange, valueType } = this.props;
    const { keyDataMap } = this.state;
    const newData = newValue.map(dataKey => keyDataMap[dataKey].data);
    const valueParam = multiple ? newValue : newValue[0];
    const dataParam = multiple ? newData : newData[0];

    this.setState({
      filterText: '',
      filteredOptions: options
    });

    if (!('value' in this.props)) {
      this.setState({
        value: newValue
      });
    }

    if (valueType === 'data') {
      onChange && onChange(dataParam);
    } else {
      onChange && onChange(valueParam, dataParam);
    }
  }

  /**
   * 操作区域，操作条目数量，目前只有 创建选项
   */
  getActionCount() {
    return this.isCreatableShow() ? 1 : 0;
  }

  isCreatableShow() {
    const { loading, creatable, optionKey } = this.props;
    const { keyDataMap, filterText, filteredOptions } = this.state;
    const text = filterText.trim();

    return creatable && !loading && text && filteredOptions.every(optionData => {
      const key = getOptionInfo(optionData, optionKey);
      const keyData = keyDataMap[key];
      return keyData.text !== text;
    });
  }

  onClear = () => {
    const { onClear } = this.props;
    this.update([]);
    onClear && onClear();
  }

  renderInput() {
    const { keyDataMap, value, filterText, popupShow, filterAble } = this.state;
    const {
      loading,
      emptyTip,
      options,
      multiple,
      children,
      onChange,
      onSelect,
      initialValue,
      value: propValue,
      valueType,
      size,
      creatable,
      onCreate,
      renderCreate,
      clearable,
      onClear,
      onFilter: propOnFilter,
      filterOption,
      className: propClassName,
      renderOption,
      optionKey,
      optionText,
      optionDisabled,
      suffixIcon: propSuffixIcon,
      optionCheckbox,
      ...others
    } = this.props;
    const className = classNames(propClassName, 'ten-select  ten-select-input', {
      'ten-select-input--popupshow': popupShow
    });

    const suffixIcon = propSuffixIcon || (<Icon type="carret_down" onClick={this.onCarretClick} />);

    return (
      <InputSelect
        {...others}
        ref={c => {
          this.refInputComponent = c; 
        }}
        size={size}
        multiple={multiple}
        className={className}
        value={value}
        filterValue={filterText}
        onFilter={filterAble ? this.onFilter : undefined}
        onClick={this.onClick}
        // eslint-disable-next-line no-confusing-arrow
        getSelectedText={key => (keyDataMap[key] ? keyDataMap[key].text : '')}
        onTagDelete={this.onTagDelete}
        onFocus={this.onInputFocus}
        onBlur={this.onInputBlur}
        onKeyDown={this.onSelectKeyDown}
        suffixIcon={suffixIcon}
        getInput={el => {
          this.inputElem = el; 
        }}
        readOnly={!popupShow}
        onClear={clearable ? this.onClear : undefined}
      />
    );
  }

  renderCreate() {
    const { renderCreate } = this.props;
    const { filterText, focusIndex } = this.state;
    const text = filterText.trim();

    if (this.isCreatableShow()) {
      return (
        <div
          className={classNames('ten-select__create', 'ten-select-option', {
            'ten-select-option--focused': focusIndex === 0,
          })}
          onClick={() => this.onCreate(text)}
          onMouseEnter={() => {
            this.setState({ focusIndex: 0 }); 
          }}
        >
          {
            renderCreate ?
              renderCreate(filterText) :
              `创建 ${filterText}`
          }
        </div>
      );
    }

    return null;
  }

  renderOption(optionData) {
    const { optionText, renderOption } = this.props;
    const optionContent = renderOption ? renderOption(optionData) : getOptionInfo(optionData, optionText);

    return optionContent;
  }

  render() {
    const { multiple, optionKey, size, optionCheckbox, loading, emptyTip } = this.props;
    const { keyDataMap, popupShow, filteredOptions, focusIndex } = this.state;
    const actionCount = this.getActionCount();
    const className = classNames('ten-select__popup', `ten-select__popup--size-${size}`, {
      'ten-select__popup--loading': loading
    });

    const optionElem = filteredOptions.length > 0 ? (
      <ul className="ten-select-options">
        {
          filteredOptions.map((optionData, i) => {
            const key = getOptionInfo(optionData, optionKey);
            const keyData = keyDataMap[key];
            const isSelected = this.isSelected(key);
            return (
              <li
                key={key}
                role="option"
                aria-selected={isSelected}
                className={classNames('ten-select-option', {
                  'ten-select-option--disabled': keyData.disabled,
                  'ten-select-option--selected': isSelected,
                  'ten-select-option--focused': focusIndex === i + actionCount,
                })}
                onClick={() => {
                  !keyData.disabled && this.onOptionClick(key);
                }}
                onMouseEnter={() => {
                  this.setState({ focusIndex: i + actionCount }); 
                }}
              >
                {
                  multiple && optionCheckbox ?
                    <Checkbox checked={isSelected} /> : null
                }
                {this.renderOption(optionData)}
              </li>
            );
          })
        }
      </ul>
    ) : null;

    const content = (
      <div role="listbox" className="ten-select__popup-content" ref={el => {
        this.refPopup = el; 
      }}>
        {
          this.renderCreate()
        }
        {optionElem}
        {
          !loading && filteredOptions.length === 0 && <div className="ten-select__empty">{emptyTip}</div>
        }
        {
          loading && <Loading type="block" />
        }
      </div>
    );

    return (
      <Popup
        className={className}
        content={content}
        show={(filteredOptions.length > 0 || loading || emptyTip) && popupShow}
        width={el => el.offsetWidth}
        direction="bottom left"
        triggerType="manual"
        onHide={this.onPopupHide}
        onShow={this.onPopupShow}
      >
        {
          this.renderInput()
        }
      </Popup>
    );
  }
}

export default Select;
