import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

let paneId = 0;

/**
 * @visibleName Tabs.TabPane
 */
export default class TabPane extends React.Component {

  static propTypes = {
    /**
     * tab 标签文字
     */
    label: PropTypes.string,
    /**
     * tab 标签值
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * information 提示待处理
     */
    information: PropTypes.bool,
    icon: PropTypes.node,
    /**
     * 标签是否可关闭
     * 
     * @default false
     */
    closable: PropTypes.bool,
  };

  static defaultProps = {
    closable: false,
  };

  constructor(props) {
    super(props);
    paneId += 1;
    this.state = { paneId };
  }

  render() {
    const { value, children, className, curValue } = this.props;

    const name = 'ten-tabs-tabpane';
    const _dataValue = typeof value !== 'undefined' ? value : `ten-pane-id${this.state.paneId}`;
    const _active = _dataValue === curValue;
    const tabPaneClassNames = classNames(name, className, {
      [`${name}--active`]: _active,
    });

    return (
      <div className={tabPaneClassNames}>{ children }</div>
    );
  }
}
