/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import Checkbox from '../checkbox';
import Icon from '../icon';

class Tbody extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.renderComplete();
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.renderComplete();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.renderComplete();
    }
  }

  static contextTypes = {
    setTbodyRenderedFunc: PropTypes.func
  };

  renderComplete = () => {
    setTimeout(() => {
      const { getTbody } = this.props;
      const { setTbodyRenderedFunc } = this.context;
      if (!setTbodyRenderedFunc || !getTbody) return;
      const tbody = this.tbody;
      setTbodyRenderedFunc(null);
      if (!tbody || tbody.clientHeight === 0) {
        setTbodyRenderedFunc(this.renderComplete);
        return;
      }
      getTbody(tbody);
    }, 0);
  };

  generateCheckbox = (item, index) => {
    const { rowKey, onChangeCheckbox, selectKeys, selectable } = this.props;

    const value = item[rowKey];
    const checked = selectKeys.indexOf(value) !== -1;
    return (
      <Checkbox
        checked={checked}
        disabled={!selectable(index, item)}
        onChange={e => onChangeCheckbox(e.target.checked, value)}
      />
    );
  };

  getTds = (item, index) => {
    const {
      columns,
      selector,
      rowKey,
      prefixClassName,
      expands,
      onExpandHandler,
      rowHeights = [],
      rowProps
    } = this.props;
    let isExpand = false;
    let expandRender = null;
    const tdKey = item[rowKey];

    const tds = columns.map(({ render, dataKey, expand, width }, idx) => {
      let expandPlus = null;
      let style = {};

      if (width) {
        style = { width };
      }
      if (expand && !isExpand) {
        isExpand = true;
        expandPlus = (
          <span
            className={`${prefixClassName}-expand`}
            onClick={e => onExpandHandler(e, tdKey)}
          >
            <Icon type={expands.indexOf(tdKey) === -1 ? 'add' : 'remove'} />
          </span>
        );
        expandRender = expand(item);
      }
      return (
        <td key={`${item[dataKey]}-${idx}`} style={style}>
          {expandPlus}
          {render ? render(item[dataKey], item, index) : item[dataKey]}
        </td>
      );
    });

    if (Object.keys(selector).length > 0) {
      tds.unshift(<td key={`${tdKey}-checkbox`}>{this.generateCheckbox(item, index)}</td>);
    }
    const height = rowHeights[index];
    const style = {};
    height && (style.height = height);

    const trProps = {
      style,
      key: tdKey,
      'data-key': tdKey,
      ...rowProps(item)
    };

    // eslint-disable-next-line react/jsx-key
    const res = [<tr {...trProps}>{tds}</tr>];

    if (expands.indexOf(tdKey) !== -1 && expandRender) {
      res.push(<tr key={`${tdKey}-expand`} data-key={`${tdKey}-expand`}>
          <td colSpan={tds.length} className={`${prefixClassName}-td-expand`}>
            {expandRender}
          </td>
        </tr>);
    }

    return res;
  };

  renderEmpty() {
    const { columns, emptyText, isFixed = false } = this.props;

    return !isFixed ? (
      <tr>
        <td
          className="ten-table__table-cell ten-table__table-cell--empty-text"
          colSpan={columns.length}
        >
          {typeof emptyText === 'function' ? emptyText() : emptyText}
        </td>
      </tr>
    ) : null;
  }

  render() {
    const { data } = this.props;

    return (
      <tbody ref={n => {
        this.tbody = n; 
      }}>
        {
          data.length > 0 ? data.map((values, index) => this.getTds(values, index)) : this.renderEmpty()
        }
      </tbody>
    );
  }
}

export default Tbody;
