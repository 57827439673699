import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @visibleName Loading 加载中
 */
class Loading extends React.Component {

  static propTypes = {
    /** 
     * 是否加载状态
    */
    loading: PropTypes.bool,
    /**  
     * 加载中的文案
    */
    text: PropTypes.string,
    /** 
     * 加载中的指示图案
    */
    loadingIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** 
     * type='block'用于容器级加载，容器需设置 position='relative'
    */
    type: PropTypes.oneOf(['block', 'default']),
  };

  static defaultProps = {
    loading: true,
    text: '',
    type: 'default',
  };

  render() {
    const { className: propClassName, children, text, loading, loadingIcon, type, ...others } = this.props;
    return React.Children.count(children) > 0 || loading ? (
      <div
        className={classNames(
          'ten-loading',
          propClassName,
          {
            'ten-loading--block': type === 'block',
          }
        )}
        {...others}
      >
        <div className={classNames(
          'ten-loading__wrapper',
          {
            'ten-loading__wrapper--hasText': text,
            'ten-loading__wrapper--closed': !loading,
          }
        )}
        >
          <span>
            {
              loadingIcon && (
                <span className={classNames('ten-loading__indicator')}
                >
                  {loadingIcon}
                </span>
              )
            }
            {
              !loadingIcon && <DefaultLoadingIcon />
            }
          </span>
          {
            text && (
              <div className={classNames('ten-loading__text')}
              >
                {text}
              </div>
            )
          }

        </div>
        <div className={classNames({
          'ten-loading--blur': loading,
        })}
        >
          {this.props.children}
        </div>

      </div>
    ) : null;
  }
}

const DefaultLoadingIcon = () => (
  <div className={classNames('ten-loading__icon-default')}
  >
    <span />
    <span />
    <span />
    <span />
  </div>
);

export default Loading;
