import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @visibleName InputGroup 输入框组
 */
class InputGroup extends React.Component {

  static propTypes = {
    /**
     * 参考 Input 对应属性，优先级高于 Input
     */
    disabled: PropTypes.bool,
    /**
     * 参考 Input 对应属性，优先级高于 Input
     */
    error: PropTypes.bool,
    /**
     * 是否合并边框
     */
    collapse: PropTypes.bool
  };

  static defaultProps = {
    collapse: true
  };

  static childContextTypes = {
    disabled: PropTypes.bool
  }

  getChildContext() {
    const context = {
      disabled: this.props.disabled
    };
    return context;
  } 

  render() {
    const { children, collapse, ...others } = this.props;  
    const className = classNames('ten-input-group', {
      'ten-input-group__collapse': collapse
    });

    delete others.disabled;
    delete others.error;

    return (
      <div className={className} {...others}>
        { children }
      </div>
    );
  }
}

export default InputGroup;