import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @visibleName Col 列
 */
export default class Col extends React.PureComponent {
  static propTypes = {
    /**
     * 所占列数
     */
    span: PropTypes.number,
    /**
     * 偏移
     */
    offset: PropTypes.number,
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  static defaultProps = {
    span: 1,
    offset: 0
  };

  render() {
    const { size, gutter, colCount, offset: propOffset, span: propSpan, style: propStyle, className, children, xs, sm, md, lg, xl, ...others } = this.props;
    let span = propSpan;
    let offset = propOffset;

    if (size && this.props[size]) {
      const config = this.props[size];
      if (typeof config === 'number') {
        span = config;
      } else {
        span = config.span;
        offset = config.offset;
      }
    }

    const style = {
      width: ((span / colCount).toFixed(6) * 100) + '%',
      marginLeft: ((offset / colCount).toFixed(6) * 100) + '%',
      padding: `0 ${gutter / 2}px`
    };

    return (
      <div
        className={classNames('ten-col', className)}
        style={{
          ...style, ...propStyle
        }}
        {...others}
      >
        {children}
      </div>
    );
  }
}
