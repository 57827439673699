import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RadioGroup from './radio-group';

/**
 * @visibleName Radio 单选
 */
export default class Radio extends React.Component {
  static propTypes = {
    /**
     * 按钮形态的radio
     */
    button: PropTypes.bool,
    /**
     * @ignore
     */
    type: PropTypes.oneOf(['radio', 'checkbox']),
    /**
     * input 元素 name
     */
    name: PropTypes.string,
    /**
     * 是否选中
     */
    checked: PropTypes.bool,
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    // onFocus: PropTypes.func,
    // onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
    /**
     * 除开 props 中的事件，其他应用于 input 元素的 props
     */
    inputProps: PropTypes.object
  };

  static defaultProps = {
    button: false,
    type: 'radio',
    inputProps: {}
  };

  static contextTypes = { ...RadioGroup.childContextTypes };

  constructor(props) {
    super(props);

    this.state = {
      isFocus: false
    };
  }

  getContextVal(key) {
    return typeof this.context === 'object' ? this.context[key] : undefined;
  }

  getContextOrPropVal(key) {
    const contextValue = this.getContextVal(key);
    return contextValue || this.props[key];
  }

  onChange = (e) => {
    const { onChange } = this.props;
    const disabled = this.getContextOrPropVal('disabled');
    if (disabled) {
      return;
    }

    onChange && onChange(e);

    if (this.context && this.context.onChange) {
      this.context.onChange(e);
    }
  };

  onFocus = (e) => {
    const { onFocus } = this.props;
    this.setState({ isFocus: true });
    onFocus && onFocus(e);
  };

  onBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ isFocus: false });
    onBlur && onBlur(e);
  };

  render() {
    const {
      children,
      className: propClassName,
      // name: propName,
      // disabled: propDisabled,
      value,
      checked: propChecked,
      type,
      onFocus,
      onBlur,
      inputProps,
      ...others
    } = this.props;

    delete others.name;
    delete others.disabled;
    delete others.button;

    const { isFocus } = this.state;

    const name = this.getContextOrPropVal('name');
    const disabled = this.getContextOrPropVal('disabled');
    const button = this.getContextOrPropVal('button');
    const contextValue = this.getContextVal('value');
    const checked = typeof contextValue !== 'undefined' ? contextValue === value : propChecked;

    let className;

    if (!button) {
      className = classNames('ten-radio', propClassName, {
        'ten-radio--checked': checked,
        'ten-radio--disabled': disabled,
        'ten-radio--focus': isFocus,
      });
    } else {
      className = classNames(
        'ten-button-radio ten-button ten-button--size-default',
        propClassName,
        {
          'ten-button-radio--checked': checked,
          'ten-button--type-primary': checked,
          'ten-button--type-default': !checked,

          'ten-button-radio--disabled': disabled,
          'ten-button--disabled': disabled,
          'ten-button-radio--focus': isFocus,
        }
      );
    }

    /* eslint-disable */
    return (
      <label className={className} {...others} >
        <span className="ten-radio__radio">
          <input
            name={name}
            type={type}
            disabled={disabled}
            checked={checked}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            ref={this.saveInput}
            value={value}
            {...inputProps}
          />
          <i className="ten-radio__radio-inner" />
        </span>
        {
          button ? 
          children :
          <span className="ten-radio__child">{children}</span>
        }
      </label>
    );
    /* eslint-enable */
  }
}

Radio.Group = RadioGroup;
