import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @visibleName CheckboxGroup 多选框组合
 */
class CheckboxGroup extends React.Component {
  static propTypes = {
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * 默认选中选框值列表
     */
    defaultValue: PropTypes.arrayOf([PropTypes.string, PropTypes.number]),
    /**
     * 选中选框值列表
     */
    value: PropTypes.array,
    /**
     * 状态改变时触发回调方法
     */
    onChange: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    defaultValue: [],
    value: []
  };

  static childContextTypes = {
    checkboxGroup: PropTypes.any
  };

  getChildContext() {
    const { value } = this.state;
    const { disabled } = this.props;
    return {
      checkboxGroup: {
        onChange: this.onChange,
        value,
        disabled
      }
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        value: nextProps.value || []
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || props.defaultValue || []
    };
  }

  static defaultProps = {
    value: []
  };

  onChange = (e) => {
    const val = e.target.value;
    const { value } = this.state;
    const { onChange } = this.props;
    const index = value.indexOf(e.target.value);
    index !== -1 ? value.splice(index, 1) : value.push(val);
    this.setState({
      value
    });
    onChange && onChange(value);
  };

  render() {
    const { props } = this;
    const { className, style, children } = props;

    const prefixClass = classNames('ten-checkbox-group', className);

    return (
      <div className={prefixClass} style={style}>
        {children}
      </div>
    );
  }
}

export default CheckboxGroup;
