import TreeNodeModal from './tree-node-model';
import isEqual from 'lodash/isEqual';

class TreeModel extends TreeNodeModal {
  constructor(config, selectedKeys = [], expandedKeys = []) {
    super({ key: 'tree-root', isLeaf: false, data: null });
    this.setConfig(config);
    this.selectedKeys = selectedKeys || [];
    this.expandedKeys = expandedKeys || [];
    this.isRoot = true;
    this.updateT = 0;
    this.deep = -1;
  }

  setConfig(config) {
    const defaultConfig = {
      selectMode: TreeModel.SELECTMODE.DEFAULT,
      expandParent: false,
      closeSiblings: false,
    };
    this.config = { ...defaultConfig, ...config };
  }

  update() {
    clearTimeout(this.updateT);
    this.updateT = setTimeout(() => {
      this.refreshSelected();
      this.refreshExpanded();
      this.apply();
    });
  }

  refreshSelected() {
    this.children.forEach(child => child.updateSelectedByKeys(this.selectedKeys));
  }

  refreshExpanded() {
    this.children.forEach(child => child.updateExpandedByKeys(this.expandedKeys));
  }

  setSelectedByKeys(selectedKeys) {
    this.setSelected(node => selectedKeys.indexOf(node.key) > -1);
  }

  setSelected(selectedKeys) {
    if (Array.isArray(selectedKeys)) {
      this.selectedKeys = selectedKeys;
      this.refreshSelected();
    }
  }

  setExpanded(expandedKeys) {
    if (Array.isArray(expandedKeys)) {
      this.expandedKeys = expandedKeys;
      this.refreshExpanded();
    }
  }

  handleSelectedChange(key, selected) {
    this.children.forEach(child => child.handleSelectedChange(key, selected));
  }

  handleExpandedChange(key, expanded) {
    this.children.forEach(child => child.handleExpandedChange(key, expanded));
  }

  updateSelected() {}

  getSelected(mode) {
    const unSortedSelectedKeys = super.getSelected(mode);
    // 排序
    let selectedKeys = [];
    this.selectedKeys.forEach((preSelectedKey) => {
      const index = unSortedSelectedKeys.indexOf(preSelectedKey);
      if (index > -1) {
        selectedKeys.push(preSelectedKey);
        unSortedSelectedKeys.splice(index, 1);
      }
    });
    selectedKeys = selectedKeys.concat(unSortedSelectedKeys);
    return selectedKeys;
  }

  getSelectedPaths(mode) {
    const unSortedSelectedPaths = super.getSelectedPaths(mode);
    // 排序
    let selectedPaths = [];
    this.selectedKeys.forEach((preSelectedPath) => {
      const foundPath = unSortedSelectedPaths.find(path => isEqual(path, preSelectedPath));
      if (foundPath) {
        const index = unSortedSelectedPaths.indexOf(foundPath);
        selectedPaths.push(preSelectedPath);
        unSortedSelectedPaths.splice(index, 1);
      }
    });
    selectedPaths = selectedPaths.concat(unSortedSelectedPaths);
    return selectedPaths;
  }

  buildFromArray(arr, getKey, getChildren) {
    function addChild(parentModel, nodeData) {
      const key = getKey(nodeData);
      const children = getChildren(nodeData);
      const isLeaf = Boolean(!children);
      const nodeModel = new TreeNodeModal({ key, isLeaf, data: nodeData });
      parentModel.addChild(nodeModel, false);

      if (Array.isArray(children)) {
        children.forEach((childNodeData) => {
          addChild(nodeModel, childNodeData);
        });
      }
    }

    arr.forEach((nodeData) => {
      addChild(this, nodeData);
    });
  }
}

TreeModel.SELECTMODE = {
  DEFAULT: 'default',
  INDEPENDENT: 'independent',
};

export default TreeModel;
