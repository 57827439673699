import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon';
import requestUtils from './request';
import FileList from './file-list';

const FILE_STATUS = {
  DONE: 'done',
  LOADING: 'loading',
  ERROR: 'error',
  REMOVE: 'remove'
};

const noop = () => {};

/**
 * @visibleName Upload 上传
 */
class Upload extends React.Component {
  static propTypes = {
    /**
     * 服务器接收到的文件名
     */
    inputName: PropTypes.string,
    /**
     * 接收文件的服务器地址
     */
    url: PropTypes.string,
    /**
     * 限制上传的文件类型
     */
    accept: PropTypes.string,
    /**
     * 限制上传文件数
     */
    limit: PropTypes.number,
    /**
     * 使用拖拽模式
     */
    dragFlag: PropTypes.bool,
    /**
     * 是否多选
     */
    multiple: PropTypes.bool,
    /**
     * 设置请求头信息
     */
    headers: PropTypes.object,
    /**
     * 设置请求的其他参数
     */
    formData: PropTypes.object,
    /**
     * 文件列表状态发生改变时，回调方法
     */
    onChange: PropTypes.func,
    /**
     * 自定义上传方法
     */
    request: PropTypes.func,
    /**
     * 文件列表
     */
    fileList: PropTypes.arrayOf(PropTypes.shape({
      /**
       * key值
       */
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * 文件名
       */
      name: PropTypes.string,
      /**
         * 文件状态 成功-done 上传中-loading 失败-error
         */
      status: PropTypes.string,
      /**
       * 文件对象
       */
      file: PropTypes.object,
      /**
       * 上传进度
       */
      progress: PropTypes.number
    })),
    /**
     * 移除文件回调 返回true移除 false时不移除
     */
    onRemove: PropTypes.func,
    /**
     * 文件列表点击回调可用于实现预览或者下载
     */
    onPreview: PropTypes.func,
    /**
     * 是否显示 fileList
     */
    showFileList: PropTypes.bool,
    /**
     * 上传请求的 http method
     */
    method: PropTypes.string,
  };

  static defaultProps = {
    inputName: 'file',
    limit: 0,
    dragFlag: false,
    multiple: false,
    headers: {},
    formData: {},
    showFileList: true,
    method: 'POST',
  };

  constructor(props) {
    super(props);
    const { fileList = [] } = this.props;
    this.state = {
      fileList
    };
  }

  static getDerivedStateFromProps(props) {
    const data = {};
    if ('fileList' in props && props.fileList) {
      data.fileList = props.fileList.map((item, idx) => {
        item.key = item.key || idx;
        return item;
      });
    }
    return data;
  }

  componentDidUpdate() {
    this.loadRequest();
  }

  loadRequest = () => {
    const { fileList } = this.state;
    const { uploadList } = this;
    if (fileList.length === 0) return;

    const { inputName, url, headers, formData, request, method } = this.props;

    for (const fileObj of fileList) {
      const { key, status, file } = fileObj;
      if (status !== FILE_STATUS.LOADING || uploadList.some(item => item.key === key)) {
        continue;
      }
      const requestProps = {
        inputName,
        method,
        url,
        headers,
        formData,
        file,
        onStart: this.onStart,
        onProgress: this.onProgress,
        onError: this.onError,
        onSuccess: this.onSuccess
      };
      const xhr = request ? request(requestProps) : requestUtils(requestProps);
      this.uploadList.push({ key, xhr });
    }
  };

  onSelectFile = () => {
    this.inputFile.click();
  };

  onStart = file => {
    this.setFileStatus(file, FILE_STATUS.LOADING);
  };

  onError = (file, response) => {
    this.setFileStatus(file, FILE_STATUS.ERROR, { response });
  };

  onSuccess = (file, response) => {
    this.setFileStatus(file, FILE_STATUS.DONE, { response });
  };

  onProgress = (file, response) => {
    const progress = response.loaded / response.total;
    this.setFileStatus(file, FILE_STATUS.LOADING, { progress });
  };

  setFileStatus = (file, status, otherParams = {}) => {
    const fileList = [...this.state.fileList];
    const index = fileList.findIndex(item => item.file === file);
    if (index === -1) {
      return;
    }
    const item = { ...fileList[index], status, ...otherParams };
    fileList.splice(index, 1, item);
    this.onChange(fileList, item);
  };

  onChange = (fileList, item) => {
    const { onChange, fileList: propFileList } = this.props;
    propFileList || this.setState({ fileList });
    onChange && onChange(fileList, item);
  };

  onInputChange = e => {
    this.addFiles(e.target.files);
    e.target.value = '';
  };

  uploadList = [];

  addFiles = files => {
    const { limit, accept } = this.props;
    const fileList = [...this.state.fileList];
    const fileLength = fileList.length;
    const newFiles = accept ? [] : files;
    if (accept) {
      const acceptList = accept.split(',');
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        for (const type of acceptList) {
          const reg = new RegExp(`${type.trim().replace('.', '\\.')
            .replace('*', '.+')}$`, 'i');
          if (reg.test(file.type) || reg.test(file.name)) {
            newFiles.push(file);
            break;
          }
        }
      }
    }
    if (newFiles.length === 0) {
      return;
    }

    for (let i = 0; i < newFiles.length; i++) {
      if (limit && fileLength + i + 1 > limit) {
        break;
      }
      const file = newFiles[i];
      const key =
        fileList.length > 0 ? Math.max.apply(null, fileList.map(item => item.key)) + 1 : 1;
      const fileObj = {
        key,
        name: file.name,
        status: FILE_STATUS.LOADING,
        file
      };
      fileList.push(fileObj);
      this.onChange(fileList, fileObj);
    }
  };

  onRemoveFile = (fileObj, idx) => {
    const { disabled, onRemove } = this.props;
    if (disabled) return;
    if (onRemove && !onRemove(fileObj)) return;

    const newFileList = [...this.state.fileList];

    newFileList.splice(idx, 1);
    this.uploadList = newFileList;
    this.onChange(newFileList, fileObj);
  };

  getFileItemStatusIcon = item => {
    switch (item.status) {
      case FILE_STATUS.LOADING:
        return <i className="loading_icon" />;
      case FILE_STATUS.DONE:
        return <Icon type="circle_tick" />;
      case FILE_STATUS.ERROR:
        return <Icon type="circle_clear" className="upload-error-icon" />;
      default:
    }
    return '';
  };

  onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  onDrap = e => {
    e.stopPropagation();
    e.preventDefault();
    this.addFiles(e.dataTransfer.files);
  };

  render() {
    const {
      className: propClassName,
      style,
      children,
      accept,
      limit,
      dragFlag,
      multiple,
      onPreview = noop,
      showFileList,
    } = this.props;
    const { fileList } = this.state;

    const prefixClassName = 'ten-upload';
    const className = classNames(propClassName, prefixClassName, {
      [`${prefixClassName}--drag`]: dragFlag
    });

    let dragProps = {};
    if (dragFlag) {
      dragProps = {
        onDragOver: this.onDragOver,
        onDrop: this.onDrap
      };
    }
    const selectContainerProps = {
      className: `${prefixClassName}__select-container`,
      onClick: this.onSelectFile,
      ...dragProps
    };

    const inputProps = {
      type: 'file',
      multiple,
      accept,
      className: `${prefixClassName}__input`,
      ref: node => {
        this.inputFile = node;
      },
      onChange: this.onInputChange
    };

    return (
      <div className={className} style={style}>
        <input {...inputProps} />
        {(!limit || fileList.length < limit) && <div {...selectContainerProps}>{children}</div>}
        {
          showFileList ? (<FileList
            list={fileList}
            onRemove={this.onRemoveFile}
            onPreview={onPreview}
          />) : null
        }
      </div>
    );
  }
}

export default Upload;
