import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../button';
import Icon from '../icon';
import Animate from '../animate';
import domHelper from '../utils/dom-helper';
import { t } from '../locale';
import { withConfig } from '../config';

function setBodyCalcSize(clear) {
  const bodyIsScroll = document.body.scrollHeight >
    (window.innerHeight || document.documentElement.clientHeight) &&
    window.innerWidth > document.body.offsetWidth;
  if (!bodyIsScroll) {
    return;
  }
  if (clear) {
    document.body.style.position = '';
    document.body.style.width = '';
    return;
  }
  const scrollBarWidth = domHelper.getBrowserScrollBarWidth();

  if (scrollBarWidth) {
    document.body.style.position = 'relative';
    document.body.style.width = `calc(100% - ${scrollBarWidth}px)`;
  }
}

/**
 * @visibleName Modal 对话框 
 */
class ModalRaw extends React.Component {

  static propTypes = {
    /**
     * 标题
     */
    title: PropTypes.string,
    /**
     * modal 框内容
     */
    content: PropTypes.node,
    /**
     * 对话框宽度
     */
    width: PropTypes.number,
    /**
     * 类型 可选 success   error   warning   info
     */
    type: PropTypes.string,
    /**
     * 自定义icon
     */
    modalIcon: PropTypes.node,
    /**
     * 自定义 footer
     */
    footer: PropTypes.node,
    /**
     * 自定义 footer 按钮，由 react node 组成的数组，可以给每一项指定 clickType 定义按钮类型（confirm|cancel|close）
     */
    footerBtns: PropTypes.arrayOf(PropTypes.node),
    /**
     * 是否显示Modal
     */
    visible: PropTypes.bool,
    /**
     * 默认显示Modal
     */
    defaultVisible: PropTypes.bool,
    /**
     * 是否显示取消按钮 
     */
    showCancelBtn: PropTypes.bool,
    /**
     * 取消按钮文字
     */
    cancelText: PropTypes.string,
    /**
     * 确认按钮文字
     */
    confirmText: PropTypes.string,
    /**
     * 关闭是否销毁Modal
     */
    destroy: PropTypes.bool,
    /**
     * 点击确认回调函数
     */
    onConfirm: PropTypes.func,
    /**
     * 点击取消回调函数
     */
    onCancel: PropTypes.func,
    /**
     * 点击关闭回调函数
     */
    onClose: PropTypes.func,
    /**
     * 关闭动画结束回调函数
     */
    onClosed: PropTypes.func,
    /**
     * 是否显示遮罩层
     */
    withMask: PropTypes.bool,
    /**
     * 点击遮罩层是否关闭 modal
     */
    maskClosable: PropTypes.bool,
  };

  static defaultProps = {
    showCancelBtn: true,
    defaultVisible: false,
    withMask: true,
    maskClosable: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    if (('visible' in nextProps) && nextProps.visible !== prevState.visible) {
      newState.visible = nextProps.visible;
    }
    if (('visible' in nextProps) && nextProps.visible) {
      newState.realVisible = true;
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.div = null;
    this.mount = true;
    this.T = 0;

    this.state = {
      visible: props.defaultVisible,
      realVisible: props.visible || props.defaultVisible
    };
    this.refConfirmBtn = React.createRef();
  }

  componentWillUnmount() {
    this.mount = false;
    this.removeDiv(true);
    clearTimeout(this.T);
    document.body.style.overflow = '';
    setBodyCalcSize(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible && !this.state.visible) {
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        this.removeDiv(this.props.destroy);
        document.body.style.overflow = '';
        setBodyCalcSize(true);
        if (this.mount) {
          this.setState({
            realVisible: false
          });
          this.props.onClosed && this.props.onClosed();
        }
      }, 300);
    }

    if (this.props.visible) {
      clearTimeout(this.T);
    }
  }

  createDiv(visible, realVisible) {
    if (!this.div) {
      const div = document.createElement('div');
      document.body.appendChild(div);
      this.div = div;
    }
    this.div.className = 'ten-modal-wrap';
    if (visible || realVisible) {
      setBodyCalcSize();
      document.body.style.overflow = 'hidden';
      this.div.style.display = 'block';
    } else {
      this.div.style.display = 'none';
    }
    return this.div;
  }

  removeDiv(destroy) {
    if (destroy) {
      this.div && document.body.removeChild(this.div);
      this.div = null;
    } else {
      this.div.style.display = 'none';
    }
  }

  onBtnClick = (type) => {
    /* eslint-disable */
    switch (type) {
      case 'close':
        this.onClose();
        break;
      case 'cancel':
        this.onCancel();
        break;
      case 'confirm':
        this.onConfirm();
        break;
    }
    /* eslint-enable */
  }

  onClose = () => {
    const {
      onClose
    } = this.props;

    if (!('visible' in this.props)) {
      this.setState({
        visible: false
      });
    }

    onClose && onClose();
  }

  onCancel() {
    const {
      onCancel,
    } = this.props;

    if (!('visible' in this.props)) {
      this.setState({
        visible: false
      });
    }

    onCancel && onCancel();
  }

  onConfirm() {
    const {
      onConfirm,
    } = this.props;

    if (!('visible' in this.props)) {
      this.setState({
        visible: false
      });
    }

    onConfirm && onConfirm();
  }

  handleMaskClick(maskClosable) {
    if (maskClosable) {
      this.onClose();
    }
  }

  renderFooter() {
    const {
      footer: propFooter,
      footerBtns: propsFooterBtns,
      showCancelBtn,
      cancelText,
      confirmText,
    } = this.props;
    let footer;

    if (typeof propFooter !== 'undefined') {
      footer = propFooter;
    } else if (propsFooterBtns) {
      footer = React.Children.map(propsFooterBtns, (item) => {
        const itemProps = item.props;
        const {
          className: childClassName,
          children: child,
          clickType,
          ...childOthers
        } = itemProps;
        let footerBtn;
        if (clickType === 'close' || !clickType) {
          footerBtn = <Button {...childOthers} className={childClassName} onClick={() => this.onBtnClick('close')}>{child}</Button>;
        }
        if (clickType === 'cancel') {
          footerBtn = <Button {...childOthers} className={childClassName} onClick={() => this.onBtnClick('cancel')}>{child}</Button>;
        }
        if (clickType === 'confirm') {
          footerBtn = <Button ref={this.refConfirmBtn} {...childOthers} className={childClassName} onClick={() => this.onBtnClick('confirm')}>{child}</Button>;
        }
        return footerBtn;
      });
    } else {
      footer = [
        <Button
          ref={this.refConfirmBtn}
          key="modalConfirmBtn"
          theme="primary"
          className="ten-modal__footer-btn ten-modal__footer-btn--confirm"
          onClick={() => this.onBtnClick('confirm')}
        >
          {!confirmText ? t('confirm') : confirmText}
        </Button>
      ];

      if (showCancelBtn) {
        footer.unshift(<Button
            key="modalCancelBtn"
            className="ten-modal__footer-btn ten-modal__footer-btn--cancel"
            onClick={() => this.onBtnClick('cancel')}
          >
            {!cancelText ? t('cancel') : cancelText}
          </Button>);
      }
    }

    return (
      footer &&
      (
        <div className="ten-modal__footer">
          {footer}
        </div>
      )
    );
  }

  renderModal() {
    const {
      type,
      visible: propVisible,
      showCancelBtn,
      onCancel,
      onConfirm,
      onClose,
      onAfterClose,
      onClosed,
      destroy,
      title,
      defaultVisible,
      type: propType,
      footer,
      footerBtns,
      modalIcon,
      className: propClassName,
      children: content,
      content: propContent,
      cancelText,
      confirmText,
      withMask,
      maskClosable,
      width,
      style = {},
      ...others
    } = this.props;
    const { visible } = this.state; 

    const iconClassName = classNames('ten-modal__header-icon', {
      [`ten-modal__header-icon--${type}`]: type
    });

    let titleIcon;
    if (type === 'warning') {
      titleIcon = <Icon className={iconClassName} type="circle_error" />;
    } else if (type === 'error') {
      titleIcon = <Icon className={iconClassName} type="circle_clear" />;
    } else if (type === 'success') {
      titleIcon = <Icon className={iconClassName} type="circle_tick" />;
    } else if (type === 'info') {
      titleIcon = <Icon className={iconClassName} type="circle_info" />;
    }

    if (modalIcon) {
      const { type: iconType, className: propsIconClassName } = modalIcon.props;
      const newIconClassName = classNames('ten-modal__header-icon', propsIconClassName, {
        [`ten-modal__header-icon--${type}`]: type
      });
      titleIcon = <Icon className={newIconClassName} type={iconType} />;
    }

    if (width) {
      style.width = width;
    }

    const modal = (
      <div className="ten-modal__wrapper">
        {
          withMask && (<Animate 
            type={{
              name: 'class',
              params: {
                classes: 'ten-fade-in'
              }
            }} 
            appear
            isShow={() => visible}
          >
            <div className="ten-modal__mask" onClick={() => this.handleMaskClick(maskClosable)} />
          </Animate>)
        }
        <Animate 
          type={{
            name: 'class',
            params: {
              classes: 'ten-flow-in'
            }
          }} 
          appear
          isShow={() => visible}
        >
          <div className={classNames('ten-modal', propClassName)} {...others} style={style}>
            {title && (
              <div className="ten-modal__header">
                {type && titleIcon}
                <div className="ten-modal__header-title">{title}</div>
                <Icon onClick={() => this.onBtnClick('close')} className="ten-modal__header-close" type="clear" />
              </div>
            )
            }
            <div className="ten-modal__content">
              {!content ? propContent : content}
            </div>
            {this.renderFooter()}
          </div>
        </Animate>
      </div>
    );

    return modal;
  }

  render() {
    const { visible, realVisible } = this.state;
    const { destroy } = this.props;

    if (!destroy || realVisible) {
      const div = this.createDiv(visible, realVisible);
      return ReactDOM.createPortal(this.renderModal(), div);
    }

    return null;
  }
}

const Modal = withConfig(ModalRaw);

const showModal = type => props => {
  const div = document.createElement('div');
  const modalProps = {
    ...props,
    type,
    destroy: true,
    defaultVisible: true,
    onClosed: () => {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    }
  };

  if (type === 'confirm' || type === 'error') {
    modalProps.showCancelBtn = true;
  }

  document.body.appendChild(div);
  ReactDOM.render(<Modal {...modalProps} />, div);
};

Modal.warning = showModal('warning');
Modal.success = showModal('success');
Modal.error = showModal('error');
Modal.info = showModal('info');
Modal.confirm = showModal('confirm');
Modal.show = showModal();

Modal.ModalRaw = ModalRaw;
export default Modal;
