import config from '../config';

function t(path = '', placement) {
  if (!path) {
    return '';
  }

  const regx = /\{\s*([\w-]+)\s*\}/g;

  const pathArr = path.split('.');
  let index = 0;
  let pattern = config.get('locale');

  while (typeof pattern === 'object' && pathArr[index]) {
    let key = pathArr[index];
    if (/^\d+$/.test(key)) {
      key = Number(key);
    }
    pattern = pattern[key] || '';
    index += 1;
  }

  // eslint-disable-next-line no-confusing-arrow
  const translated = pattern.replace(regx, (_match, key) => (placement ? placement[key] : ''));

  return translated;
}

export default { t };
export { t };
