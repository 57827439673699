import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon';

/**
 * @visibleName Tag 标签
 */
class Tag extends React.Component {

  static propTypes = {
    /**
     *  提供此参数既显示删除按钮 
     */
    onClose: PropTypes.func
  };

  render() {
    const { className: propClassName, children, onClose, ...others } = this.props;
    const className = classNames(propClassName, 'ten-tag', {
      'ten-tag--closable': onClose
    });
    return (
      <span className={className} {...others}>
        { children }
        {
          onClose ?
            <Icon className="ten-tag__close" type="clear" onClick={onClose} />
            :
            null
        }
      </span>
    );
  }
}

export default Tag;
