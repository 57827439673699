import React from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';
/**
 * @visibleName Tabs 选项卡
 */
export default class Tabs extends React.Component {

  static propTypes = {
    /**
     * tab 点击事件
     */
    onTabClick: PropTypes.func,
    /**
     * tab 删除事件
     */
    onTabDelete: PropTypes.func,
    /**
     * 当前选中的 tabKey
     */
    activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 默认选中的 tabKey
     */
    defActiveKey: PropTypes.string,
    /**
     * Tabs 主题 可选 normal、primary
     */
    type: PropTypes.string
  };
  
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.activeKey || props.defActiveKey || props.children[0].key || '',
      showScroll: false,
      scrollLeft: 0,
      nextBtnWidth: 0
    };
    this.tabNav = React.createRef();
    this.tabScroll = React.createRef();
    this.tabNextBtnWidth = React.createRef();

    this.handlePrevClick = this.handleMove.bind(this, true);
    this.handleNextClick = this.handleMove.bind(this, false);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state) {
      return {
        scrollLeft: state.scrollLeft
      };
    }
    return null;
  }

  // getSnapshotBeforeUpdate (prevProps, prevState) {
  //   console.log(prevProps);
  //   console.log(prevState);
  //   return null;
  // }

  componentDidMount () {
    this.onScrollTab();
    setTimeout(() => {
      this.onScrollTab();
    }, 200);
    window.addEventListener('resize', this.onScrollTab, false);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onScrollTab);
  }

  onScrollTab = () => {
    const tabNavWidth = this.tabNav.current.offsetWidth;
    const navScrollWidth = this.tabScroll.current.offsetWidth;
    if (tabNavWidth < navScrollWidth) {
      this.setState({ showScroll: true });

      let tabNextBtnWidth;
      if (this.tabNextBtnWidth) {
        tabNextBtnWidth = this.tabNextBtnWidth.current === null ? -1 : this.tabNextBtnWidth.current.clientWidth;
        this.setState({ showScroll: 2 });
      } else {
        tabNextBtnWidth = 0;
      }
      this.setState({ nextBtnWidth: tabNextBtnWidth === -1 ? 0 : tabNextBtnWidth });
    } else {
      this.setState({ nextBtnWidth: 0, showScroll: false, scrollLeft: 0 });
    }
  }

  onCloseTab = (e, index) => {
    const onTabDelete = this.props.onTabDelete;
    if (onTabDelete) {
      this.onScrollTab();
      onTabDelete(index);
    }
  }

  onTabClick = (e, key) => {
    const onTabClick = this.props.onTabClick;
    if (e.currentTarget === e.target) {
      this.setState({ activeIndex: key });
      if (onTabClick) {
        onTabClick(key, e);
      }
    }
    return key;
  };

  handleMove = (lr) => {
    const { type } = this.props;
    const { nextBtnWidth } = this.state;
    const tabNavWidth = this.tabNav.current.offsetWidth;
    const navScrollWidth = this.tabScroll.current.offsetWidth;
    const extraDistance = type ? 30 + (nextBtnWidth * 2) : (nextBtnWidth * 2);
    let attribute = this.state.scrollLeft + (lr ? -(tabNavWidth - extraDistance) : (tabNavWidth - extraDistance));
    if (attribute < 0) attribute = 0;
    if (attribute + tabNavWidth > navScrollWidth) attribute = navScrollWidth - tabNavWidth;

    this.setState({ scrollLeft: attribute, showScroll: attribute + tabNavWidth !== navScrollWidth });
    if (tabNavWidth >= navScrollWidth) {
      this.setState({ scrollLeft: 0 });
    }
  }

  render() {
    const contentChildren = [];
    const navChildren = [];
    const children = this.props.children;
    const { 
      onTabClick,
      onTabDelete,
      className: propClassName,
      activeKey,
      type,
      defActiveKey,
      ...others 
    } = this.props;

    const { showScroll, scrollLeft } = this.state;

    const ifIndex = activeKey || this.state.activeIndex;

    React.Children.map(children, (child, i) => {
      const childProps = child.props;
      const key = child.key;
      const {
        label,
        icon,
        closable,
        className: childPropClassName,
        information,
        children,
        ...childOthers
      } = childProps;

      const addTabpaneClassName = childPropClassName ? childPropClassName + '-tabpane' : '';

      const navClassName = classNames('ten-tabs-tab', childPropClassName, {
        'ten-tabs-tab--active': key === ifIndex,
        'ten-tabs-tab--close': closable,
        'ten-tabs-tab--icon': icon,
        'ten-tabs-tab--info': information,
      });
      const contentClassName = classNames('ten-tabs-tabpane', addTabpaneClassName, {
        'ten-tabs-tabpane--active': key === ifIndex
      });

      let tabPaneIcon = null;
      if (closable) {
        tabPaneIcon = <span className="ten-tabs-tab__icon"><Icon className="ten-tabs-tab__close" type="clear" onClick={(e) => this.onCloseTab(e, i)} /></span>;
      } else if (icon) {
        const renderIcon = typeof icon === 'string' ? <Icon type={icon} /> : icon;
        tabPaneIcon = <span className="ten-tabs-tab__icon">{renderIcon}</span>;
      }

      navChildren.push((
        <div className={navClassName} { ...childOthers } onClick={(e) => this.onTabClick(e, key)} key={key}>
          { label } 
          { tabPaneIcon }
        </div>
      ));
      contentChildren.push((
        <div className={contentClassName} key={key}>{ children }</div>
      ));
    });
    const tabsClassName = classNames('ten-tabs', propClassName, {
      [`ten-tabs--${type}`]: type,
      'ten-tabs-srcoll-container': showScroll
    });
    return (
      <div className={tabsClassName} {...others}>
        <div ref={this.tabNav} className="ten-tabs-nav">
          {scrollLeft > 0 && <div className="ten-tabs-scroll__prev"><div className="ten-tabs-scroll-btn" onClick={this.handlePrevClick}><Icon type="left" /></div></div>}
          <div ref={this.tabScroll} style={{ transform: [`translateX(${-this.state.scrollLeft}px)`] }} className="ten-tabs-nav-scroll">
            { navChildren }
          </div>
          { showScroll && <div className="ten-tabs-scroll__next" ref={this.tabNextBtnWidth}><div onClick={this.handleNextClick} className="ten-tabs-scroll-btn"><Icon type="right" /></div></div> }
        </div>
        <div className="ten-tabs-content">
          { contentChildren }
        </div>
      </div>
    );
  }
}
