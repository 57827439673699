import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../button';
import PageItem from './page-item';
import Select from '../select';
import Input from '../input';

/**
 * @visibleName Pagination 分页
 */
class Pagination extends React.Component {

  static propTypes = {
    /**
     * 禁用分页
     */
    disabled: PropTypes.bool,
    /**
     * 当前页
     */
    currentPage: PropTypes.number,
    /**
     * 默认当前页
     */
    defaultCurrentPage: PropTypes.number,
    /**
     * 数据总数
     */
    total: PropTypes.number,
    /**
     * 每页条数，传total时有效
     */
    pageSize: PropTypes.number,
    /**
     * pageSize的默认值
     */
    defaultPageSize: PropTypes.number,
    /**
     * 页码总数, pageCount 和 total设置任意
     * 一个就可以达到显示页码的功能；如果需要指定
     * 每页条数，需要设置total来支持，并使用
     * pageSizeOption 或者 pageSize指定
     */
    pageCount: PropTypes.number,
    /**
     * 最多显示页码按钮个数，大于就折叠
     */
    maxDisplayPageBtnCount: PropTypes.number,
    /**
     * 折叠时可显示页数
     */
    foldedDisplayPageBtnCount: PropTypes.number,
    /**
     * 可选每页可以显示多少条
     */
    pageSizeOption: PropTypes.arrayOf(PropTypes.number),
    /**
     * 是否显示上下页按钮
     */
    hideCtrlBtn: PropTypes.bool,
    /**
     * 是否显示快速跳转
     */
    showJumper: PropTypes.bool,
    /**
     * 页码改变的回调
     */
    onChange: PropTypes.func,
    /**
     * pageSize变化的回调
     */
    onPageSizeChange: PropTypes.func,
    /**
     * 极简模式
     * 
     * @ignore
     */
    simple: PropTypes.bool,
    /**
     * 显示总条数
     */
    showTotal: PropTypes.func
  };

  static defaultProps = {
    defaultCurrentPage: 1,
    defaultPageSize: 10,
    maxDisplayPageBtnCount: 10,
    hideCtrlBtn: false,
    foldedDisplayPageBtnCount: 5,
    showJumper: false,
    simple: false,
    disabled: false
  };

  static calPageCount(total, pageSize) {
    if (!total || !pageSize) return 0;
    return Math.ceil(total / pageSize);
  }

  static getDerivedStateFromProps(props, state) {

    const { 
      total, 
      pageSize: userPageSize, 
      pageCount, 
      currentPage,
      pageSizeOption } = props;

    const { pageSize } = state;

    let derivedState = {};

    const pageSizeTemp = userPageSize || pageSize;
    const pageCountTemp = pageCount || Pagination.calPageCount(total, pageSizeTemp);

    if ('pageCount' in props || 'total' in props) {
      derivedState = {
        pageCount: pageCountTemp,
        pageSize: pageSizeTemp
      };
    }

    if ('currentPage' in props) {
      derivedState.currentPage = currentPage;
    }

    if ('pageSizeOption' in props) {
      const arr = pageSizeOption.map(v => v);
      if (arr.indexOf(pageSizeTemp) === -1) {
        arr.push(pageSizeTemp);
      }
      derivedState.pageSizeOption = arr;
    }


    if (Object.keys(derivedState).length) {
      return derivedState;
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { 
      total, 
      pageSize, 
      defaultPageSize,
      pageCount, 
      currentPage,
      defaultCurrentPage } = props;
 
    this.state = {
      currentPage: currentPage || defaultCurrentPage,
      pageSize: pageSize || defaultPageSize,
      pageCount: pageCount || Pagination.calPageCount(total, pageSize),
      jumperInputValue: ''
    };
  }

  toPage = (pageIndex) => {
    
    const { onChange } = this.props;
    const { currentPage, pageCount } = this.state;

    if (pageIndex === currentPage) return;

    if (pageIndex < 1) {
      pageIndex = 1;
    } 

    if (pageIndex > pageCount) {
      pageIndex = pageCount;
    }

    if (!('currentPage' in this.props)) {
      this.setState({
        currentPage: pageIndex
      });
    }
    onChange && onChange(pageIndex);
  }

  prevPage = () => {
    this.toPage(this.state.currentPage - 1);
  }

  nextPage = () => {
    this.toPage(this.state.currentPage + 1);
  }

  prevMorePage = () => {
    this.toPage(this.state.currentPage - this.props.foldedDisplayPageBtnCount);
  }

  nextMorePage = () => {
    this.toPage(this.state.currentPage + this.props.foldedDisplayPageBtnCount);
  }

  onPageSizeChange = (pageSize) => {
    const { onPageSizeChange, total } = this.props;

    let currentPage = this.state.currentPage;
    const pageCount = Pagination.calPageCount(total, pageSize);

    if (currentPage > pageCount) currentPage = pageCount;

    if (!('pageSize' in this.props)) {
      const state = { pageSize };
      if (!('currentPage' in this.props)) state.currentPage = currentPage;
      this.setState(state);
    }

    onPageSizeChange && onPageSizeChange(pageSize, currentPage);
  }

  jumpToPage = () => {
    this.setState({ jumperInputValue: '' });
    const value = Math.round(parseFloat(this.state.jumperInputValue));

    if (isNaN(value)) return;

    this.toPage(value);
  }

  onJumperKeyUp = (e) => {
    switch (e.keyCode) {
      case 13:
        this.jumpToPage();
        if (this.inputElm && this.inputElm.refInputElem) {
          this.inputElm.refInputElem.blur();
        }
        break;
      default:
        break;
    }
  }

  onJumperInputChange = (e) => {

    // todo: 限制输入为数字类型
    const value = e.target.value;
    
    this.setState({ jumperInputValue: value });
  }
  
  render() {

    const { currentPage, pageCount, jumperInputValue, pageSizeOption, pageSize } = this.state;

    const {
      disabled,
      maxDisplayPageBtnCount,
      foldedDisplayPageBtnCount,
      hideCtrlBtn,
      className: propClassName,
      pageSizeOption: userPageSizeOption,
      showJumper,
      pageSize: userPageSize,
      pageCount: userPageCount,
      currentPage: userCurrentPage,
      defaultCurrentPage,
      defaultPageSize,
      onChange,
      onPageSizeChange,
      simple,
      showTotal,
      total,

      ...others } = this.props;

    const isFolded = pageCount > maxDisplayPageBtnCount;

    const curPageLeftCount = Math.ceil((foldedDisplayPageBtnCount - 1) / 2);
    const curPageRightCount = parseInt((foldedDisplayPageBtnCount - 1) / 2);
    
    const isPrevMoreShow = (2 + curPageLeftCount < currentPage);
    const isNextMoreShow = (pageCount - 1 - curPageRightCount > currentPage);

    const pageItems = [];
    let start;
    let end;

    if (isFolded) {
      if (isPrevMoreShow && isNextMoreShow) {
        start = currentPage - curPageLeftCount;
        end = currentPage + curPageRightCount;
      } else {
        start = isPrevMoreShow ? pageCount - foldedDisplayPageBtnCount + 1 : 2;
        end = isPrevMoreShow ? pageCount - 1 : foldedDisplayPageBtnCount;
      }
    } else {
      start = 1;
      end = pageCount;    
    }

    for (let i = start; i <= end; i++) {
      pageItems.push(<PageItem disabled={disabled} key={i} onPageChange={this.toPage} currentPage={currentPage} displayPageNumber={i} />);
    }

    return (
      <div className={classNames('ten-pagination', propClassName)} {...others}>

        { showTotal && <span className="ten-pagination__total">{showTotal(total || pageCount * pageSize)}</span> }

        <div className="ten-pagination__btn-wrapper">
          {
            !hideCtrlBtn &&
            (
              <Button 
                size="small"
                className="ten-pagination__btn ten-pagination__btn-prev"
                disabled={currentPage === 1 || !pageCount || disabled}
                icon="left"
                onClick={this.prevPage}
              />
            )
          }

          { 
            isFolded ? 
              (
                <React.Fragment>
                  <PageItem disabled={disabled} onPageChange={this.toPage} displayPageNumber={1} currentPage={currentPage} /> 
                  { isPrevMoreShow && <Button disabled={disabled} size="small" className="ten-pagination__btn ten-pagination__btn-prev-more" icon="more" onClick={this.prevMorePage} /> }
                  { pageItems }
                  { isNextMoreShow && <Button disabled={disabled} size="small" className="ten-pagination__btn ten-pagination__btn-next-more" icon="more" onClick={this.nextMorePage} /> }
                  <PageItem disabled={disabled} onPageChange={this.toPage} displayPageNumber={pageCount} currentPage={currentPage} />
                </React.Fragment>
              )
              :

              (
                <React.Fragment>
                  { pageItems }
                </React.Fragment>
              )
          }

          {
            !hideCtrlBtn &&
            (
              <Button 
                size="small" 
                className="ten-pagination__btn ten-pagination__btn-next" 
                disabled={currentPage === pageCount || !pageCount || disabled} 
                icon="right" 
                onClick={this.nextPage} 
              />
            )
          }
        </div>

        { userPageSizeOption && userPageSizeOption.length > 0 && 
          (
            <Select 
              size="small" 
              options={pageSizeOption}
              className="ten-pagination__page-size-selector"
              optionText={v => `${v}条/页`}
              value={pageSize} 
              onChange={this.onPageSizeChange}
              disabled={disabled}
            />
          )
        }

        {
          showJumper && 
          (
            <div className="ten-pagination__jumper">
              跳至
              <Input
                ref={el => {
                  this.inputElm = el; 
                }}
                size="small"
                disabled={disabled}
                className="ten-pagination__jumper-input"
                value={jumperInputValue}
                onKeyUp={this.onJumperKeyUp}
                onChange={this.onJumperInputChange}
                onBlur={this.jumpToPage}
              />
              页
            </div>
          )
        }
      </div>
    );
  }
}

export default Pagination;
