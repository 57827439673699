import {
  prefix
} from 'inline-style-prefixer';

const utils = {
  css(node, style) {
    if (node && node.nodeType === Node.ELEMENT_NODE) {
      const prefixedStyle = prefix(style);
      const autoPxAttrs = ['top', 'left', 'width', 'height'];
      for (const key in prefixedStyle) {
        if (Object.prototype.hasOwnProperty.call(prefixedStyle, key)) {
          if (typeof prefixedStyle[key] === 'number' && autoPxAttrs.indexOf(key) > -1) {
            node.style[key] = prefixedStyle[key] + 'px';
          } else {
            node.style[key] = prefixedStyle[key];
          }
        }
      }
    }
  },
  contains(root, n) {
    let node = n;
    while (node) {
      if (node === root) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  },
  getBrowserScrollBarWidth: (function () {
    let scrollBarWidth;
    return function () {
      if (typeof scrollBarWidth !== 'undefined') {
        return scrollBarWidth;
      }
      const innerBox = document.createElement('div');

      innerBox.style.width = '100%';
      innerBox.style.height = '200px';

      const testBox = document.createElement('div');

      testBox.style.position = 'absolute';
      testBox.style.top = 0;
      testBox.style.left = 0;
      testBox.style.pointerEvents = 'none';
      testBox.style.visibility = 'hidden';
      testBox.style.width = '200px';
      testBox.style.height = '150px';
      testBox.style.overflow = 'hidden';

      testBox.appendChild(innerBox);

      document.body.appendChild(testBox);

      const widthContaine = innerBox.offsetWidth;
      testBox.style.overflow = 'scroll';
      let widthScroll = innerBox.offsetWidth;

      if (widthContaine === widthScroll) {
        widthScroll = testBox.clientWidth;
      }

      document.body.removeChild(testBox);

      scrollBarWidth = widthContaine - widthScroll;
      return scrollBarWidth;
    };
  }()),
  isIE: (function detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }()),
  clearSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }
};

export default utils;

/* eslint-disable */
(function () {

  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;
}());
