import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Component from '../utils/component';
import Input from './input';
import InputTag from './input-tag';

export default class InputSelect extends Component {

  static propTypes = {
    multiple: PropTypes.bool,
    filterValue: PropTypes.oneOfType([PropTypes.string]),
    value: PropTypes.array,
    onFilter: PropTypes.func,
    onTagDelete: PropTypes.func,
    getSelectedText: PropTypes.func
  };

  static defaultProps = {
    getSelectedText: key => key,
    filterValue: ''
  };

  static getDerivedStateFromProps(nextProps) {
    if ('filterValue' in nextProps) {
      return { filterValue: nextProps.filterValue };
    }
    return null;
  }

  refInputComponent = null;

  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      filterValue: '',
      isSingleModeHovered: false
    };
    this.blurT = 0;
    this.isFocus = false;
  }

  onChange = (e) => {
    const { onFilter } = this.props;
    const text = e.target.value;
    this.setState({
      filterValue: text
    });
    onFilter && onFilter(text);
  }

  onFocus = (e) => {
    const { onFocus } = this.props;
    this.isFocus = true;
    this.setState({
      isFocus: true
    });
    onFocus && onFocus(e);
    clearTimeout(this.blurT);
  }

  onBlur = (e) => {
    this.isFocus = false;
    this.blurT = setTimeout(() => {
      if (this.isFocus) {
        return;
      }
      const { onBlur } = this.props;
      this.setStateSafely({
        isFocus: false
      });
      onBlur && onBlur(e);
    }, 150);
  }

  onTagDelete = (tag) => {
    const { onTagDelete } = this.props;

    onTagDelete && onTagDelete(tag.key, tag);
  }

  onSingleModeMouseEnter = () => {
    this.setState({ isSingleModeHovered: true });
  }

  onSingleModeMouseLeave = () => {
    this.setState({ isSingleModeHovered: false });
  }

  onClear = () => {
    this.props.onClear && this.props.onClear();
  }

  focusInput() {
    const inputWrapElem = ReactDOM.findDOMNode(this.refInputComponent);

    if (!inputWrapElem) {
      return;
    }
    const inputElem = inputWrapElem.querySelector('input');
    inputElem && inputElem.focus();
  }

  blurInput() {
    const inputWrapElem = ReactDOM.findDOMNode(this.refInputComponent);

    if (!inputWrapElem) {
      return;
    }
    const inputElem = inputWrapElem.querySelector('input');
    inputElem && inputElem.blur();
  }

  clearFilter() {
    const { onFilter } = this.props;
    this.setState({
      filterValue: ''
    });
    onFilter && onFilter('');
  }

  render() {
    const { className: propClassName, multiple, value, filterValue: propFilterValue, onFilter, onTagDelete, getSelectedText, placeholder, readOnly, onClear, ...others } = this.props;
    const { isFocus, filterValue, isSingleModeHovered } = this.state;
    const inputProps = {
      className: classNames(propClassName, 'ten-inputselect'),
      ref: (c) => {
        this.refInputComponent = c; 
      },
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      placeholder,
      autoComplete: 'off'
    };

    if (!onFilter) {
      inputProps.readOnly = true;
    } else {
      inputProps.readOnly = readOnly || false;
      inputProps.onChange = this.onChange;
    }

    if (multiple) {
      inputProps.tags = value.map(key => ({
        key,
        text: getSelectedText(key)
      })).filter(item => item.text);
      inputProps.getTagKey = tagData => tagData.key;
      inputProps.getTagText = tagData => tagData.text;
      inputProps.onTagDelete = this.onTagDelete;
      if (value.length > 0) {
        inputProps.placeholder = '';
      }
      inputProps.value = filterValue || '';
    } else {
      const key = value[0];
      const selectedText = typeof key !== 'undefined' ? getSelectedText(key) : '';
      if (isFocus) {
        if (onFilter && !inputProps.readOnly) {
          inputProps.placeholder = filterValue === '' ? (selectedText || inputProps.placeholder) : placeholder;
          inputProps.value = filterValue;
        } else {
          inputProps.value = selectedText || '';
        }
      } else {
        inputProps.value = selectedText || '';
      }
      if (onClear) {
        others.onMouseEnter = this.onSingleModeMouseEnter;
        others.onMouseLeave = this.onSingleModeMouseLeave;
        if (isSingleModeHovered) {
          others.onClear = this.onClear;
        }
      }
    }
    return (
      multiple ?
        <InputTag {...others} {...inputProps} /> :
        <Input {...others} {...inputProps} />
    );
  }

}
