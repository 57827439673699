import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';

/**
 * @visibleName BreadcrumbItem
 */
export default class BreadcrumbItem extends React.Component {

  static propTypes = {
    breadcrumbKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onItemClick: PropTypes.func,
    /**
     * 是否是最后一个
     */
    last: PropTypes.bool,
    /**
     * 自定义分隔符
     */
    separator: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ])
  };
  
  static defaultProps = {
    separator: <Icon type="right" />
  };

  onClick = () => {
    const { breadcrumbKey, onItemClick } = this.props;
    onItemClick(breadcrumbKey);
  }

  render() {
    const {
      children,
      className,
      breadcrumbKey,
      onItemClick,
      last,
      separator,
      ...others
    } = this.props;

    const classes = classNames(className, 'ten-breadcrumb__item', {
      'ten-breadcrumb__last': last,
    });
    const separatorElem = <span className="ten-breadcrumb__separator">{separator}</span>;
    return (
      <span className={classes} onClick={this.onClick}>
        <span
          {...others}
          className="ten-breadcrumb__item__text"
        >
          {children}
        </span>
        { last ? '' : separatorElem}
      </span>
    );
  }
}
