import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BreadcrumbItem from './breadcrumb-item';

/**
 * @visibleName Breadcrumb 面包屑
 */
export default class Breadcrumb extends React.Component {

  static propTypes = {
    /**
     * 自定义分隔符,
     */
    separator: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    /**
     * 每个 Item 点击回调, 返回对应 key 值
     */
    onItemClick: PropTypes.func
  };

  static defaultProps = {
  };

  getItem() {
    return React.Children.map(this.props.children, (child, index) => {
      if (child && child.type === BreadcrumbItem) {
        return React.cloneElement(child, {
          breadcrumbKey: child.key || index,
          onItemClick: this.onItemClick
        });
      }
      return child;
    });
  }

  onItemClick = (key) => {
    const { onItemClick } = this.props;
    onItemClick && onItemClick(key);
  }

  render() {
    const {
      children,
      className,
      onItemClick,
      ...others
    } = this.props;

    const classes = classNames(className, 'ten-breadcrumb');

    return (
      <div
        className={classes}
        {...others}
      >
        {this.getItem()}
      </div>
    );
  }
}
Breadcrumb.Item = BreadcrumbItem;
