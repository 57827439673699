/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popup from '../popup';
import Icon from '../icon';
import Button from '../button';
import Input from '../input';
import valueFormat from '../input/value-format';
import { parseToDate, dateToFormat, isSameTime, isSame, minTimeOfDate, outOfRanges } from './util';
import { t } from '../locale';

const InputFormatted = valueFormat(Input, { input: false, blur: true, enter: true });

const DEFAULT_FORMAT = {
  time: 'HH:mm:ss',
  date: 'YYYY-MM-DD',
  datetime: 'YYYY-MM-DD HH:mm:ss',
  month: 'YYYY-MM',
  year: 'YYYY'
};


class Picker extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]))]),
    format: PropTypes.string,
    valueFormat: PropTypes.string,
    /**
     * 选择类型
     * @ignore
     */
    type: PropTypes.oneOf(['time', 'date', 'datetime', 'month', 'year']),
    /**
     * 禁用
     */
    disabled: PropTypes.bool,
    /**
     * 只读
     */
    readonly: PropTypes.bool,
    /**
     * 是否错误状态
     */
    error: PropTypes.bool,
    /**
     * 显示可清楚按钮
     */
    allowClear: PropTypes.bool,
    /**
     * placeholder
     */
    placeholder: PropTypes.string,
    /**
     * 范围模式
     */
    isRange: PropTypes.bool,
    /**
     * 范围模式时的分隔符
     */
    rangeSeparator: PropTypes.string,
    /**
     * 范围模式时的起始placeholder
     */
    startPlaceholder: PropTypes.string,
    /**
     * 范围模式时的结束placeholder
     */
    endPlaceholder: PropTypes.string,
    /**
     * 后缀icon
     */
    suffixIcon: PropTypes.node,
    /**
     * 清除按钮icon
     */
    clearIcon: PropTypes.node,
    /**
     * value值变化回调
     */
    onChange: PropTypes.func,
    /**
     * 失焦回调
     */
    onBlur: PropTypes.func,
    /**
     * focus回调
     */
    onFocus: PropTypes.func,
    /**
     * 面板
     * @ignore
     */
    panel: PropTypes.func,
    /**
     * 快捷选项
     */
    shortCut: PropTypes.array,
    /**
     * 尺寸
     */
    size: PropTypes.oneOf(['default', 'small']),
    /**
     * popup的方位, 参考popup direction配置
     */
    direction: PropTypes.string,
    /**
     * 禁用日期
     */
    disabledDate: PropTypes.func,
    /**
     * 最大可选日期，只取日期部分的值
     * Date类型，或者能被new Date()解析的字符串
     */
    maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    /**
     * 最小可选日期，只取日期部分的值
     * Date类型，或者能被new Date()解析的字符串
     */
    minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    /**
     * 禁用时间
     * @ignore
     */
    disabledTime: PropTypes.func,
    /**
     * 最大可选时间，只取时间部分的值
     * @ignore
     */
    maxTime: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date))]),
    /**
     * 最小可选时间，只取时间部分的值
     * @ignore
     */
    minTime: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date))]),
    /**
     * 周起始日， 与Date getDay方法对应
     */
    firstDayOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    popupProps: PropTypes.object,
    /**
     * 在范围选择器里取消两个日期面板之间的联动
     */
    unlinkPanels: PropTypes.bool
  };

  static defaultProps = {
    disabled: false,
    readonly: false,
    isRange: false,
    allowClear: true,
    error: false,
    size: 'default',
    direction: 'bottom left',
    firstDayOfWeek: 0,
    popupProps: {},
    unlinkPanels: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { isRange, valueFormat, type, minDate, maxDate } = props;

    let minD = minDate;
    let maxD = maxDate;

    if (minD) {
      if (typeof minD === 'string') {
        const dateParams = minD.match(/(\d+)/g) || [];
        if (dateParams.length > 1) dateParams[1] -= 1;
        minD = new Date(Date.UTC(...dateParams));
        if (minD.toDateString() === 'Invalid Date') minD = null;
      }
      minD = minTimeOfDate(minD);
    }
    if (maxD) {
      if (typeof maxD === 'string') {
        const dateParams = maxD.match(/(\d+)/g) || [];
        if (dateParams.length > 1) dateParams[1] -= 1;
        maxD = new Date(Date.UTC(...dateParams));
        if (maxD.toDateString() === 'Invalid Date') maxD = null;
      }
      maxD = minTimeOfDate(maxD);
    }

    let time = [];
    let value;

    if ('value' in props) {
      value = props.value;
    } else {
      value = state.time;
    }

    if (isRange) {
      value.forEach((v, i) => {
        if (v instanceof Date) {
          time[i] = v;
        } else {
          time[i] = v === '' ? undefined : parseToDate(v, valueFormat || DEFAULT_FORMAT[type]);
        }
      });
    } else if (value instanceof Date) {
      time = value;
    } else {
      time = value === '' ? undefined : parseToDate(value, valueFormat || DEFAULT_FORMAT[type]);
    }

    return { time, minDate: minD, maxDate: maxD };
  }

  constructor(props) {
    super(props);

    this.inputTimeEle = {};

    this.state = {
      popupShow: false
    };
  }

  showPopup = () => {
    const { disabled, readonly, onFocus } = this.props;
    if (!disabled && !readonly) {
      this.state.popupShow || this.setState({ popupShow: true }, () => {
        onFocus && onFocus();
      });
    }
  }

  hidePopup = () => {
    this.setState({ popupShow: false });
  }

  pickerBlur = () => {
    this.hidePopup();
    this.props.onBlur && this.props.onBlur();
  }

  clear = (e) => {
    e.stopPropagation();
    const { isRange, allowClear } = this.props;
    if (!allowClear) return;

    if (isRange) {
      this.onChangeHandler(['', '']);
    } else {
      this.onChangeHandler('');
    }
  }

  onChangeHandler = (newTime, fromInput) => {
    const { onChange, valueFormat, isRange, type } = this.props;


    if (!('value' in this.props)) {
      this.setState({
        time: newTime,
      });
    }
    if (isRange) {
      // eslint-disable-next-line no-confusing-arrow
      onChange && onChange(valueFormat ? newTime.map(time => (time ? dateToFormat(time, valueFormat) : time)) : newTime);
    } else {
      onChange && onChange(valueFormat && newTime ? dateToFormat(newTime, valueFormat) : newTime);
    }
    if (!fromInput && type.indexOf('time') === -1) {
      this.hidePopup();
    }
  }

  onInputChange = (value, index) => {
    const { isRange } = this.props;
    let time = value;
    let isChanged = false;
    if (isRange) {
      time = this.state.time.map((v, i) => { 
        if (i === index) {
          isChanged = this.isReallyChanged(v, value);
          return value;
        }
        return v;
      });
    } else {
      isChanged = this.isReallyChanged(this.state.time, value);
    }
    if (isChanged) this.onChangeHandler(time, true);
  }

  isReallyChanged(oldValue, newValue) {
    const { type } = this.props;
    if (oldValue instanceof Date && newValue instanceof Date) {
      switch (type) {
        case 'year':
        case 'month':
        case 'date':
          return !isSame(oldValue, newValue, type);
        case 'time':
          return !isSameTime(oldValue, newValue);
        default:
          return !isSame(oldValue, newValue) || !isSameTime(oldValue, newValue);
      }
    } 
    if (oldValue === undefined && newValue === '') return false;
    return true;
  }

  onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.hidePopup();
    }
  }

  renderInput() {
    const { 
      isRange, 
      disabled, 
      readonly, 
      placeholder,
      startPlaceholder,
      endPlaceholder,
      rangeSeparator,
      size
    } = this.props;

    return isRange ? (
      <React.Fragment>
        <InputFormatted
          getInput={el => {
            this.inputTimeEle.startTimeInput = el;
          }}
          disabled={disabled}
          inputProps={{ readOnly: readonly }}
          placeholder={startPlaceholder}
          value={this.state.time[0]}
          onChange={e => {
            this.onInputChange(e, 0);
          }}
          onKeyDown={this.onInputKeyDown}
          formatter={this.inputValueFormatter}
          parser={this.inputValueParser}
          size={size}
        />
        <span className="ten-picker__input-separator">{rangeSeparator || t('datePicker.to')}</span>
        <InputFormatted
          getInput={el => {
            this.inputTimeEle.endTimeInput = el;
          }}
          disabled={disabled}
          inputProps={{ readOnly: readonly }}
          placeholder={endPlaceholder}
          value={this.state.time[1]}
          onChange={e => {
            this.onInputChange(e, 1);
          }}
          onKeyDown={this.onInputKeyDown}
          formatter={this.inputValueFormatter}
          parser={this.inputValueParser}
          size={size}
        />
      </React.Fragment>
    ) : (
      <InputFormatted
        getInput={el => {
          this.inputTimeEle = el;
        }}
        disabled={disabled}
        inputProps={{ readOnly: readonly }}
        placeholder={placeholder}
        value={this.state.time}
        onChange={this.onInputChange}
        onKeyDown={this.onInputKeyDown}
        formatter={this.inputValueFormatter}
        parser={this.inputValueParser}
        size={size}
      />
    );
  }

  inputValueParser = dateStr => {
    if (dateStr === '') return dateStr;
    const { format, type, disabledDate } = this.props;
    let value = parseToDate(dateStr, format || DEFAULT_FORMAT[type]);
    if (!value) value = new Date(dateStr);
    if (
      value.toDateString() === 'Invalid Date' ||
      (disabledDate && disabledDate(value)) || 
      outOfRanges(value, this.state.minDate, this.state.maxDate)
    ) return this.state.time;

    return value;
  }

  inputValueFormatter = date => {
    if (!date) return '';
    const { format, type } = this.props;
    return dateToFormat(date, format || DEFAULT_FORMAT[type]);
  }

  shortCutClickHandler = (value) => {
    const { isRange, valueFormat, type } = this.props;

    if (isRange) {
      if (!Array.isArray(value)) throw new Error('ten datepicker: shortCut value must be a array in range mode');

      const formatValues = value.map(v => {
        if (v instanceof Date) return v;
        if (typeof v === 'string') {
          const fv = parseToDate(v, valueFormat || DEFAULT_FORMAT[type]);
          if (!fv) throw new Error('ten datepicker: shortCut invalid date');
          return fv;
        }
        throw new Error('ten datepicker: shortCut invalid value');
      }).sort((a, b) => a.getTime() - b.getTime());

      this.onChangeHandler(formatValues);
      return;
    }
    
    if (value instanceof Date) {
      this.onChangeHandler(value);
      return;
    }
    
    if (typeof value === 'string') {
      const formatValue = parseToDate(value, valueFormat || DEFAULT_FORMAT[type]);
      if (!formatValue) throw new Error('ten datepicker: shortCut invalid value');
      this.onChangeHandler(formatValue);
      return;
    }

    throw new Error('ten datepicker: shortCut invalid value');
  }

  renderContent() {
    const { 
      shortCut, 
      panel: Panel,
      isRange,
      minDate,
      maxDate,
      
      value,
      type,
      disabled, 
      readonly,
      error, 
      format,
      placeholder,
      startPlaceholder,
      endPlaceholder,
      rangeSeparator,
      valueFormat,
      suffixIcon,
      clearIcon,
      allowClear,
      onChange,
      onBlur,
      onFocus,
      className,
      style,
      direction,
      popupProps,
      ...others } = this.props;

    const panel = (
      <Panel
        value={this.state.time}
        format={format || DEFAULT_FORMAT[type]}
        onChange={this.onChangeHandler}
        relativetimeinput={this.inputTimeEle}
        minDate={this.state.minDate}
        maxDate={this.state.maxDate}
        {...others}
      />
    );

    return (
      <div className="ten-picker__popup">
        { 
          shortCut && 
          (
            <div className="ten-picker__popup-shortcut">
              {
                shortCut.map((item, index) => (
                  <Button
                    className="ten-picker__popup-shortcut-btn"
                    type="text"
                    size="small"
                    onClick={() => {
                      this.shortCutClickHandler(item.value);
                    }}
                    key={index}
                  >
                    { item.text }
                  </Button>
                ))
              }
            </div>
          )
        }
        {panel}
      </div>
    );
  }

  render() {
    const { popupShow, time } = this.state;
    const { 
      type,
      disabled,
      readonly,
      error,
      isRange,
      allowClear,
      suffixIcon,
      clearIcon,
      className,
      style,
      size,
      direction, 
      popupProps
    } = this.props;

    const triggerClassName = classNames('ten-picker__input', `ten-picker__input--size-${size}`, 'ten-input-groupable', {
      'ten-picker__input--focused': popupShow,
      'ten-picker__input--disabled': disabled,
      'ten-picker__input--readonly': readonly,
      'ten-picker__input--error': error,
      'ten-picker__input--empty': isRange && time ? !time.filter(item => item).length : !time,
      'ten-picker__input--no-clear': !allowClear
    }, className);

    return (
      <Popup 
        triggerType="manual"
        content={this.renderContent()} 
        direction={direction}
        show={popupShow}
        onHide={this.pickerBlur}
        onShow={this.showPopup}
        className="ten-picker"
        {...popupProps}
      >
        <div
          className={triggerClassName}
          style={style}
          onClick={this.showPopup}
        >
          {this.renderInput()}

          <div className="ten-picker__input-suffixicon">
            <span className="ten-picker__input-icon ten-picker__input-icon--suffix">
              { suffixIcon || (type === 'time' ? <Icon type="line_circle_time" /> : <Icon type="calendar" />) }
            </span>
            <span 
              className="ten-picker__input-icon ten-picker__input-icon--clear"
              onClick={this.clear}
            >
              { allowClear ? (clearIcon || <Icon type="circle_clear" />) : null }
            </span>
          </div>
        </div>
      </Popup>
    );
  }
}

export default Picker;
