import domHelper from '../../utils/dom-helper';

function getTransform(direction = 'up', distance, isEnter) {
  const directionMap = {
    up: { dir: 'Y', neg: 1 },
    down: { dir: 'Y', neg: -1 },
    left: { dir: 'X', neg: 1 },
    right: { dir: 'X', neg: -1 }
  };
  const directionData = directionMap[direction];

  directionData.neg *= isEnter ? 1 : -1;

  return `translate${directionData.dir}(${distance * directionData.neg}px)`;
}

export default function () {
  let aniT;

  const timeout = function(fn, dur) {
    clearTimeout(aniT);
    aniT = setTimeout(fn, dur);
  };

  const cancel = function() {
    clearTimeout(aniT);
  };

  return ({
    defaultParams: {
      direction: 'up',
      distance: 50
    },
    onEnter({ domNode, params }) {
      const { direction, distance } = params;

      const style = {
        opacity: 0,
        transform: getTransform(direction, distance, true)
      };

      domHelper.css(domNode, style);
    },

    onEntering({ domNode, params, done }) {
      const { dur, easing } = params;

      const style = {
        opacity: 1,
        transform: 'translate(0)',
        transition: `all ${dur}ms ${easing}`
      };

      timeout(function () {
        domHelper.css(domNode, style);

        timeout(done, dur);
      }, 20);
    },

    onEntered({ domNode }) {
      const style = {
        opacity: '',
        transform: '',
        transition: ''
      };
      domHelper.css(domNode, style);
    },

    onExit({ domNode, params }) {
      const { direction, distance } = params;

      const style = {
        opacity: 1,
        transform: 'translate(0)',
        transition: getTransform(direction, distance, false),
      };

      domHelper.css(domNode, style);
    },

    onExiting({ domNode, params, done }) {
      const { dur, easing, direction, distance } = params;

      const style = {
        opacity: 0,
        transform: getTransform(direction, distance, false),
        transition: `all ${dur}ms ${easing}`
      };

      timeout(function () {
        domHelper.css(domNode, style);

        timeout(done, dur);
      }, 10);
    },

    onExited({ domNode }) {
      const style = {
        transform: '',
        transition: ''
      };
      domHelper.css(domNode, style);
    },

    onCancel() {
      cancel();
    }

  });
}
