import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';

/**
 * @visibleName Step 子步骤条
 */
class Step extends React.Component {

  static propTypes = {
    /** 
     * 是否为跳过状态
    */
    skip: PropTypes.bool,
    /** 
     * 标题
    */
    title: PropTypes.any,
    /** 
     * 描述
    */
    description: PropTypes.any,
    /** 
     * step内容额外部分
    */
    extraContent: PropTypes.node,
  };

  static defaultProps = {
    skip: false,
  };

  render() {
    const { title, description, index, len, statusClassName: sta, nextClassName, modeName, forward, extraContent } = this.props;

    let showIndex = index + 1;

    if (!forward) {
      showIndex = len - index;
    }
    const noLast = index < len - 1;

    const className = classNames(
      'ten-steps__item',
      `ten-steps__item--${sta}`,
      {
        [`ten-steps__item--next--${nextClassName}`]: nextClassName,
        [`ten-steps__item--${modeName}`]: modeName === 'vertical',
        [`ten-steps__item--vertical--next--${nextClassName}`]: modeName === 'vertical' && nextClassName,
        // 'ten-steps__item--reverse': !forward,
      }
    );

    return (
      <div className={className}>
        <div className={classNames(
          'ten-steps__icon',
          `ten-steps__icon--${sta}`,
        )}
        >
          {['wait', 'process', 'skip'].includes(sta) ? showIndex : (
            <Icon type={sta === 'finish' ? 'tick' : 'error'} />
          )}
        </div>
        <div className="ten-steps__content">
          {
            modeName === 'vertical' && noLast && (
              <div className={classNames(
                'ten-steps__tail--vertical',
                {
                  [`ten-steps__tail--next--${nextClassName}`]: nextClassName,
                  'ten-steps__tail--reverse': !forward,
                }
              )}
              />
            )
          }
          <div className={classNames(
            'ten-steps__title',
            `ten-steps__title--${sta}`,
            {
              [`ten-steps__title--next--${nextClassName}`]: nextClassName,
              [`ten-steps__title--${modeName}`]: modeName === 'vertical',
            }
          )}
          >
            {title}
          </div>
          <div className={classNames(
            'ten-steps__description',
            `ten-steps__description--${sta}`,
          )}
          >
            {description}

          </div>
          {extraContent && (
            <div className="ten-steps__extraContent">
              {extraContent}
            </div>
          )}
          
        </div>

      </div>
    );
  }
}

export default Step;
