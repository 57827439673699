import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Item from './item';

const Month = ({ months, onClick, onMouseEnter, className }) => (
  <table className={classNames('ten-date-month', className)}>
    <tbody>
      {
        months.map((month, i) => (
          <tr key={i}>
            {
              month.map((m, j) => (
                <Item 
                  className="ten-date-month__item"
                  key={j}
                  {...m}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                />
              ))
            }
          </tr>
        ))
      }
    </tbody>
  </table>
);

Month.propTypes = {
  months: PropTypes.array,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

Month.defaultProps = {
  onClick() {},
  onMouseEnter() {}
};

export default Month;