import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InputGroup from './input-group';
import Icon from '../icon';
// import domHelper from '../utils/dom-helper';

/**
 * @visibleName Input 输入框
 */
class Input extends React.Component {

  static propTypes = {
    /**
     * 是否禁用
     */
    disabled: PropTypes.bool,
    /**
     * input onChange 事件
     */
    onChange: PropTypes.func,
    /**
     * 是否错误状态
     */
    error: PropTypes.bool,
    /**
     * 尺寸
     */
    size: PropTypes.oneOf(['default', 'small']),
    /**
     * 如果提供 onClear，则会在输入不为空时显示清除图标以支持清除功能 
     */
    onClear: PropTypes.func,

    /**
     * 输入框前缀
     */
    prefix: PropTypes.node,
    /**
     * 输入框后缀
     */
    suffix: PropTypes.node,
    /**
     * 输入框前缀图标
     */
    prefixIcon: PropTypes.node,
    /**
     * 输入框后缀图标
     */
    suffixIcon: PropTypes.node,

    /**
     * @ignore
     */
    inputSuffix: PropTypes.node,
    /**
     * @ignore
     */
    inputPrefix: PropTypes.node,

    /**
     * @ignore input 元素前缀内容，不带容器，如用于 inputTag 中的 tags
     */
    inputPrefixRaw: PropTypes.node,
    /**
     * 输入框值
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 初始值
     */
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 这些 props 会被直接传入到 input
     */
    inputProps: PropTypes.object
  };

  static defaultProps = {
    disabled: false,
    error: false,
    size: 'default'
  };

  static contextTypes = { ...InputGroup.childContextTypes };

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        value: nextProps.value,
      };
    }
    return null;
  }

  refInputElem = null;

  refInputPrefix = null;

  refInputSuffix = null;

  blurT = 0;

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || props.initialValue || '',
      focused: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.blurT);
  }

  getContextVal(key) {
    return typeof this.context === 'object' ? this.context[key] : undefined;
  }

  getContextOrPropVal(key) {
    const contextValue = this.getContextVal(key);
    return contextValue || this.props[key];
  }

  onChange = (e) => {
    const { onChange } = this.props;

    if (!('value' in this.props)) {
      this.setState({
        value: e.target.value,
      });
    }

    onChange && onChange(e);
  }

  onClear = (e) => {
    e.stopPropagation();
    this.props.onClear();

    if (!('value' in this.props)) {
      this.setState({
        value: '',
      });
    }
  }

  onInputClick = () => {
    this.refInputElem.focus();
  }

  onFocus = (e) => {
    const { onFocus } = this.props;

    clearTimeout(this.blurT);
    this.isFocus = true;
    this.setState({ focused: true });
    onFocus && onFocus(e);
  }

  onBlur = (e) => {
    const { onBlur } = this.props;
    this.isFocus = false;
    e.persist();
    this.blurT = setTimeout(() => {
      if (!this.isFocus && this.state.focused) {
        this.setState({ focused: false });
      }
    }, 150);
    onBlur && onBlur(e);
  }

  render() {
    const {
      children,
      className: propClassName,
      // disabled,
      // error,
      size: propSize,
      type,
      name,
      onChange,
      onClear,

      onKeyDown,
      onKeyPress,
      onKeyUp,
      onFocus,
      onBlur,
      placeholder,
      readOnly,
      autoComplete,

      prefix,
      suffix,
      prefixIcon,
      suffixIcon: propSuffixIcon,
      inputPrefixRaw,
      inputPrefix,
      inputSuffix,
      inputProps,

      getInput,
      ...others } = this.props;
    const { value, focused } = this.state;

    const disabled = this.getContextOrPropVal('disabled');
    const error = this.getContextOrPropVal('error');
    const size = this.getContextOrPropVal('size');

    const inputPropsFromProps = {
      type,
      name,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      readOnly,
      autoComplete,
    };

    const className = classNames(
      propClassName,
      'ten-input',
      `ten-input--size-${size}`,
      {
        'ten-input--status-error': error,
        'ten-input--disabled': disabled,
        'ten-input--focused': focused,
        'ten-input--with-prefix': prefix,
        'ten-input--with-suffix': suffix
      }
    );
    let suffixIcon = propSuffixIcon;

    delete others.disabled;
    delete others.error;
    delete others.initialValue;

    if (onClear && !disabled && value) {
      suffixIcon = <Icon className="ten-input__input-close" role="button" tabIndex={0} type="circle_clear" onClick={this.onClear} />;
    }

    const inputWrapClassName = classNames('ten-input__input', {
      'ten-input__input--status-error': error,
      'ten-input__input--focused': focused,
      'ten-input__input--disabled': disabled,
      'ten-input__input--prefixicon': prefixIcon,
      'ten-input__input--suffixicon': suffixIcon,
    });

    const inputRaw = (
      <input
        type="text"
        {...inputPropsFromProps}
        {...inputProps}
        ref={el => { 
          this.refInputElem = el; 
          getInput && getInput(el);
        }}
        className="ten-input__input-input"
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={value}
      />
    );

    const input = (
      <div className={inputWrapClassName} onClick={this.onInputClick}>
        {
          prefixIcon ?
            <span className="ten-input__input-icon ten-input__input-icon--prefix">{prefixIcon}</span> :
            null
        }
        {
          inputPrefix ?
            <span ref={el => {
              this.refInputPrefix = el; 
            }} className="ten-input__input-prefix">{inputPrefix}</span> :
            null
        }
        {
          inputPrefixRaw
        }
        {inputRaw}
        {
          inputSuffix ?
            <span ref={el => {
              this.refInputSuffix = el; 
            }} className="ten-input__input-suffix">{inputSuffix}</span> :
            null
        }
        {
          suffixIcon ?
            <span className="ten-input__input-icon ten-input__input-icon--suffix">{suffixIcon}</span> :
            null
        }

      </div>
    );

    return (
      <div className={className} {...others}>
        {
          prefix ?
            <span className="ten-input__prefix">{prefix}</span> :
            null
        }

        {input}

        {
          suffix ?
            <span className="ten-input__suffix">{suffix}</span> :
            null
        }
        {children}
      </div>
    );
  }
}

export default Input;
