import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../button';
import { t } from '../../locale';

export default function Header(props) {
  const {
    onPrevOrNext,
    year,
    month,
    type,
    onTypeChange,
    disablePrevYear,
    disablePrevMonth,
    disableNextYear,
    disableNextMonth,
  } = props;

  let endYear;
  let startYear;

  if (type === 'year') {
    startYear = parseInt(year / 10) * 10;
    endYear = startYear + 9;
  }

  return (
    <div className="ten-date-header">
      <Button
        className={classNames(
          'ten-date-header__btn',
          'ten-date-header__btn--prev-year'
        )}
        onClick={() => onPrevOrNext('year', 'prev')}
        disabled={disablePrevYear}
        type="text"
        size="small"
        icon="double_left"
      />

      {!disableNextMonth ? (
        <Button
          className={classNames(
            'ten-date-header__btn',
            'ten-date-header__btn--prev-month'
          )}
          onClick={() => onPrevOrNext('month', 'prev')}
          disabled={disablePrevMonth}
          type="text"
          size="small"
          icon="left"
        />
      ) : null}

      <div className="ten-date-header__text">
        {type === 'year' && (
          <>
            <span>{startYear}</span>
            {' - '}
            <span>{endYear}</span>
          </>
        )}

        <Button
          className={classNames('ten-date-header__btn', {
            'ten-date-header__btn--disabled': type === 'year',
          })}
          type="text"
          onClick={() => {
            onTypeChange('year');
          }}
          size="small"
        >
          {t('datePicker.yearTitle', { year })}
        </Button>

        <Button
          className={classNames('ten-date-header__btn', {
            'ten-date-header__btn--disabled': type !== 'date',
          })}
          type="text"
          onClick={() => {
            onTypeChange('month');
          }}
          size="small"
        >
          {t(`datePicker.months.${month}`)}
        </Button>
      </div>

      {!disableNextMonth ? (
        <Button
          className={classNames(
            'ten-date-header__btn',
            'ten-date-header__btn--next-month'
          )}
          onClick={() => onPrevOrNext('month', 'next')}
          disabled={disableNextMonth}
          type="text"
          size="small"
          icon="right"
        />
      ) : null}

      <Button
        className={classNames(
          'ten-date-header__btn',
          'ten-date-header__btn--next-year'
        )}
        onClick={() => onPrevOrNext('year', 'next')}
        disabled={disableNextYear}
        type="text"
        size="small"
        icon="double_right"
      />
    </div>
  );
}

Header.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  type: PropTypes.oneOf(['year', 'month', 'date']),
  disableNextYear: PropTypes.bool,
  disablePrevYear: PropTypes.bool,
  disableNextMonth: PropTypes.bool,
  disablePrevMonth: PropTypes.bool,
  onPrevOrNext: PropTypes.func,
  onTypeChange: PropTypes.func,
};

Header.defaultProps = {
  type: 'date',
};
