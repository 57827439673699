import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';
import Animate from '../animate';
import Checkbox from '../checkbox';
import domHelper from '../utils/dom-helper';

class TreeNode extends React.Component {

  static propTypes = {
    // 是否禁用
    disabled: PropTypes.bool,
    nodeKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // 加载中
    loading: PropTypes.bool,
    nodeModel: PropTypes.object,
    selected: PropTypes.number,
    expanded: PropTypes.number
  };

  static defaultProps = {
    loading: false
  };

  static contextTypes = {
    tree: PropTypes.object,
    showCheckbox: PropTypes.bool,
  };

  state = {
    dragging: false,
    dragoverPos: '',
  };

  refExpandElem = null;
  refCheckboxElem = null;

  isDisabled() {
    const { nodeModel } = this.props;
    const { isNodeDisabled } = this.context.tree;
    return isNodeDisabled(nodeModel.data);
  }

  isSelected() {
    const { nodeModel, selected } = this.props;
    const { multiple, selectable } = this.context.tree.props;
    const { dataSelected } = this.context.tree.state;

    if (!selectable) {
      return false;
    }
    if (multiple) {
      return selected === 1;
    }
    return dataSelected && dataSelected.indexOf(nodeModel.key) > -1;
  }

  onNodeClick = (e) => {
    const isSelected = this.isSelected();
    const isExpanded = this.props.expanded === 1;
    if (this.isDisabled()) {
      return;
    }

    const { nodeModel } = this.props;
    const { onNodeClick } = this.context.tree;
    const { selectable, selectedMode } = this.context.tree.props;
    const { showCheckbox } = this.context;
    const nodeElem = e.target;

    if (
      domHelper.contains(this.refExpandElem, nodeElem)
      || domHelper.contains(this.refCheckboxElem, nodeElem)
    ) {
      return;
    }

    if (
      !selectable
      || showCheckbox
      || (selectedMode === 'onlyLeaf' && !nodeModel.isLeaf)
    ) {
      this.onExpand();
    } else {
      this.onSelect();
    }

    onNodeClick && onNodeClick(nodeModel.key, nodeModel.data, isSelected, isExpanded);
  }

  onExpandClick = () => {
    if (this.isDisabled()) {
      return;
    }
    this.onExpand();
  }

  onExpand() {
    const { nodeModel, expanded } = this.props;
    const { onNodeExpandChange, onNodeLoad, isRemoteNode } = this.context.tree;

    onNodeExpandChange(nodeModel.key, expanded === 1 ? 0 : 1);

    if (isRemoteNode(nodeModel)) {
      onNodeLoad(nodeModel);
    }
  }

  onSelect() {
    if (this.isDisabled()) {
      return;
    }
    const { nodeModel } = this.props;
    const { onNodeSelectChange } = this.context.tree;
    onNodeSelectChange(nodeModel.key, nodeModel.selected === 1 ? 0 : 1);
  }

  onCheckboxChange = () => {
    this.onSelect();
  }

  onDragStart = (e) => {
    const { nodeModel } = this.props;
    const { tree } = this.context;
    const sourceModelId = nodeModel.id;
    this.setState({
      dragging: true,
    });
    e.dataTransfer.setData('ten-tree-node-data', nodeModel.id);
    tree.draggingSourceModelId = sourceModelId;
    tree.onNodeDragStart(sourceModelId, e);
  }

  onDragEnter = (e) => {
    const { nodeModel } = this.props;
    const { tree } = this.context;
    const sourceModelId = tree.draggingSourceModelId;
    const targetModelId = nodeModel.id;
    if (sourceModelId === targetModelId) {
      return;
    }
    tree.onNodeDragEnter(sourceModelId, targetModelId, e);
  }

  onDragOver = (e) => {
    e.preventDefault();

    const { nodeModel } = this.props;
    const { tree } = this.context;
    const sourceModelId = tree.draggingSourceModelId;
    const targetModelId = nodeModel.id;
    if (sourceModelId === targetModelId) {
      return;
    }
    const elemHeight = e.target.offsetHeight;
    const dragY = e.nativeEvent.offsetY;
    const insertAreaPercent = 0.3;
    const insertAreaHeight = elemHeight * insertAreaPercent;

    let dragoverPos;
    if (dragY < insertAreaHeight) {
      dragoverPos = 'top';
    } else if (dragY > elemHeight - insertAreaHeight) {
      dragoverPos = 'bottom';
    } else {
      dragoverPos = 'center';
    }
    this.setState({
      dragoverPos,
    });
    tree.onNodeDragOver(sourceModelId, targetModelId, e);
  }
  onDragLeave = (e) => {
    const { nodeModel } = this.props;
    const { tree } = this.context;
    const sourceModelId = tree.draggingSourceModelId;
    const targetModelId = nodeModel.id;
    this.setState({
      dragoverPos: '',
    });
    tree.onNodeDragLeave(sourceModelId, targetModelId, e);
  }
  onDragEnd = (e) => {
    const { nodeModel } = this.props;
    const { tree } = this.context;

    this.setState({
      dragging: false,
      dragoverPos: '',
    });
    tree.onNodeDragEnd(nodeModel, e);
  }
  onDrop = (e) => {
    const { nodeModel } = this.props;
    const { tree } = this.context;

    e.preventDefault();
    this.dragging = false;

    const sourceModelId = Number(e.dataTransfer.getData('ten-tree-node-data'));
    const targetModelId = nodeModel.id;
    const { dragoverPos } = this.state;

    this.setState({
      dragging: false,
      dragoverPos: '',
    });
    if (sourceModelId === targetModelId) {
      return;
    }
    setTimeout(() => {
      if (dragoverPos !== 'center') {
        tree.insert(dragoverPos === 'top' ? 0 : 1, sourceModelId, targetModelId, e);
      } else {
        tree.append(sourceModelId, targetModelId, e);
      }
      tree.draggingSourceModelId = null;
    }, 0);
    tree.onNodeDrop(sourceModelId, targetModelId, e);
  }

  render() {
    const { nodeModel, selected, expanded, loading, renderChildren } = this.props;
    const { dragging, dragoverPos } = this.state;
    const { renderNode } = this.context.tree;
    const { selectable, draggable, expandAnimation } = this.context.tree.props;
    const { showCheckbox } = this.context;
    const isDisabled = this.isDisabled();
    const isSelected = this.isSelected();
    const isExpanded = expanded === 1;
    let checkbox = null;
    let nodeChildren = null;
    const contentClass = classNames([
      'ten-tree-node__content',
      {
        'ten-tree-node__content--disabled': isDisabled,
        'ten-tree-node__content--selected': isSelected,
        'ten-tree-node__content--showcheckbox': showCheckbox,
        'ten-tree-node__content--dragging': dragging,
        [`ten-tree-node__content--dragpos-${dragoverPos}`]: dragoverPos,
      },
    ]);
    const contentEvents = {
      onClick: this.onNodeClick,
      onDoubleClick: domHelper.clearSelection,
      onMouseDown: domHelper.clearSelection,
    };
    if (draggable) {
      contentEvents.onDragStart = this.onDragStart;
      contentEvents.onDragEnter = this.onDragEnter;
      contentEvents.onDragOver = this.onDragOver;
      contentEvents.onDragLeave = this.onDragLeave;
      contentEvents.onDragEnd = this.onDragEnd;
      contentEvents.onDrop = this.onDrop;
    }

    if (selectable && showCheckbox) {
      checkbox = (
        <div 
          className="ten-tree-node__checkbox"
          key="checkbox"
          ref={el => {
            this.refCheckboxElem = el; 
          }}
        >
          <Checkbox
            checked={selected === 1}
            indeterminate={selected === 2}
            onChange={this.onCheckboxChange}
            disabled={isDisabled}
          />
        </div>
      );
    }
    if (!dragging && expanded && !nodeModel.isLeaf) {
      nodeChildren = (
        <div className="ten-tree-node__children">
          {renderChildren(nodeModel.children)}
        </div>
      );
    }


    return (
      <div className="ten-tree-node">
        <div className={contentClass} { ...contentEvents } draggable={draggable}>
          {
            !nodeModel.isLeaf &&
            (
              <div
                key="expand"
                ref={el => {
                  this.refExpandElem = el; 
                }}
                className={classNames('ten-tree-node__expand', {
                  'ten-tree-node__expand--expanded': isExpanded,
                  'ten-tree-node__expand--loading': loading
                })}
                onClick={this.onExpandClick}
              >
                {
                  loading ?
                    <Icon type="loading_gradient" />
                    :
                    <Icon type="carret_right" />
                }
              </div>
            )
          }
          {
            checkbox
          }
          {
            renderNode(nodeModel.data, isSelected, isExpanded)
          }
        </div>
        {
          expandAnimation ? (
            <Animate type={{ name: 'slidedown', params: { dur: 200 } }}>
              { nodeChildren }
            </Animate>
          ) : nodeChildren
        }

      </div>
    );
  }
}

export default TreeNode;
