import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tree from '../tree';
import * as treeUtil from '../tree/tree-util';
import Popup from '../popup';
import InputSelect from '../input/input-select';
import utils from '../utils';
import Component from '../utils/component';

function traverse(data, getChildren, cb) {
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      traverse(data[i], getChildren, cb);
    }
  } else {
    cb(data);
    const children = getChildren(data);
    if (Array.isArray(children)) {
      traverse(children, getChildren, cb);
    }
  }
}

/**
 * @visibleName TreeSelect 树形选择
 */
class TreeSelect extends Component {

  static propTypes = {
    /**
     * 树数据
     */
    data: PropTypes.array.isRequired,
    /**
     * @ignore
     */
    size: PropTypes.oneOf(['default', 'small']),
    /**
     * 指定选中的节点 
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    /**
     * value 是否为选中的选项数据，而非选项数据 key 值
     */
    valueType: PropTypes.oneOf(['key', 'data']),
    /**
     * 是否多选
     */
    multiple: PropTypes.bool,
    /**
     * 是否禁用，如果 Boolean 类型，整个组件禁用，
     * 如果是 Function 类型，则根据函数返回值确定某个节点是否禁用，参数是节点数据
     */
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /**
     * @ignore
     * 是否在 input 框中显示选中 value
     */
    showDisabledValue: PropTypes.bool,
    /**
     * 定义选中 keys 的形式，会决定 onChange 接受到的 selectedKeys 数组的形式<br>
     * all: 选中数组包含所有选中的节点 <br>
     * onlyLeaf: 选中数组包含所有叶子的节点 <br>
     * parentFirst: 若节点被选中，则不包含该节点的子孙节点 
     */
    valueMode: PropTypes.oneOf(['all', 'onlyLeaf', 'parentFirst']),
    /**
     * 节点 key(id) 在 nodeData 中对应的键名<br/>
     */
    nodeKey: PropTypes.string,
    /**
     * 节点文本在 nodeData 中对应的键名<br/>
     * 如需另外对节点内容定制，可以指定 renderNode (可返回 react element)
     */
    nodeText: PropTypes.string,
    /**
     * 渲染节点，params：{ nodeData，isSelected，isExpanded }
     */
    renderNode: PropTypes.func,
    /**
     * 节点 children 在 nodeData 中对应的键名
     */
    nodeChildren: PropTypes.string,
    /**
     * placeholder
     */
    placeholder: PropTypes.string,
    /**
     * 如果多选各参数为数组
     * @param key 选中的 key <br>
     * @param optionData 选中 Option 的 data 
     */
    onChange: PropTypes.func,
    /**
     * 树属性，参考 Tree
     */
    treeProps: PropTypes.object,
    /**
     * @param text 筛选输入变化回调
     */
    onFilter: PropTypes.func,
    /**
     * 设置为 true 则根据 optionText 的值自动筛选<br/>
     * 设置为 false 不作过滤操作 <br/>
     * 可以是函数<br/>
     * @param filterText 筛选输入值<br/>
     * @param optionData 对应 optionData
     */
    filterData: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  };

  static defaultProps = {
    disabled: false,
    showDisabledValue: true,
    size: 'default',
    valueType: 'key',
    nodeKey: 'key',
    nodeText: 'text',
    nodeChildren: 'children'
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { nodeKey, nodeText, nodeChildren, valueType, multiple } = nextProps;
    const newState = {};
    if (nextProps.data !== prevState.data) {
      const keyDataMap = {};
      traverse(nextProps.data, (node) => node[nodeChildren], (node) => {
        keyDataMap[node[nodeKey]] = { data: node, text: node[nodeText] };
      });
      newState.filteredDatas = nextProps.data;
      newState.data = nextProps.data;
      newState.keyDataMap = keyDataMap;
    }
    /* get data keymap */
    const getNodeKey = node => node[nodeKey];
    const getNodeChildren = node => node[nodeChildren];
    const updateDataKeyMap = function () {
      newState.treeDataKeyMap = treeUtil.getDataKeyMap(
        nextProps.data,
        getNodeKey,
        getNodeChildren
      );
      newState.selectedDataKeyMap = {};
      if (valueType === 'data' && nextProps.value) {
        const value = multiple ? nextProps.value : [nextProps.value];
        value.forEach((node) => {
          const key = getNodeKey(node);
          newState.selectedDataKeyMap[key] = node;
        });
      }
      newState.dataKeyMap = { ...newState.treeDataKeyMap, ...newState.selectedDataKeyMap };
    };
    if (nextProps.data !== prevState.data) {
      updateDataKeyMap();
      newState.data = nextProps.data;
    }
    /* transform value */
    const transform = (v) => {
      let dataSelected;
      if (!multiple) {
        dataSelected = !utils.isEmpty(v) ? [v] : [];
      } else {
        dataSelected = Array.isArray(v) ? v : [];
      }
      if (valueType === 'data') {
        dataSelected = dataSelected.map((data) => {
          const key = getNodeKey(data);
          return key;
        });
      }
      return dataSelected;
    };
    if ('value' in nextProps && nextProps.value !== prevState.value) {
      prevState.value = nextProps.value;
      newState.dataValue = transform(prevState.value);
    }
    return newState;
  }

  refInputComponent = null;

  constructor(props) {
    super(props);
    const { multiple } = props;
    this.state = {
      value: multiple ? [] : null,
      dataValue: [], // tranformed 全部为 key 类型
      popupShow: false,
      selectedDataKeyMap: {},
      treeDataKeyMap: {},
      dataKeyMap: {},
      expanded: [],
    };
  }

  isFilterable() {
    const { onFilter, filterData } = this.props;
    return  onFilter || filterData;
  }

  isNodeDisabled = (nodeData) => {
    const { disabled } = this.props;
    if (typeof disabled === 'function') {
      return disabled(nodeData);
    }
    return nodeData.disabled || disabled;
  }

  onTriggerClick = () => {
    const { disabled } = this.props;
    const filterable = this.isFilterable();

    if (disabled) {
      return;
    }
    this.setState({
      popupShow: filterable ? true : !this.state.popupShow
    });
  }

  onTagDelete = (key) => {
    const newValue = this.state.value.filter(v => v !== key);
    this.update(newValue);
  }

  onFilter = text => {
    const { onFilter } = this.props;

    this.setState({
      filterText: text,
    });
    onFilter && onFilter(text);
  }

  onPopupHide = () => {
    this.refInputComponent.clearFilter();
    this.setStateSafely({ popupShow: false, filterText: '' });
    setTimeout(() => this.refInputComponent.blurInput(), 1);
  }

  onPopupShow = () => {
    this.setState({ popupShow: true });
  }

  onTreeClick = () => {
    this.refInputComponent.focusInput();
  }

  onExpand = (expanded) => {
    this.setState({
      expanded
    });
  }

  onChange = (value) => {
    const { multiple } = this.props;
    let newDataValue;
    if (multiple) {
      newDataValue = value;
    } else {
      newDataValue = utils.isEmpty(value) ? [] : [value];
      this.onPopupHide();
    }
    this.update(newDataValue);
  }

  reverse(v) {
    const { valueType, multiple } = this.props;
    let selected = v;
    if (valueType === 'data') {
      selected = v.map(key => this.getDataByKey(key));
    }
    if (!multiple) {
      selected = selected.length ? selected[0] : undefined;
    }
    return selected;
  }

  getDataByKey(key) {
    return this.state.dataKeyMap[key];
  }

  update(newDataValue) {
    const { onChange } = this.props;

    this.refInputComponent.clearFilter();
    this.setState({ filterText: '' });
    if (!('value' in this.props)) {
      this.setState({
        dataValue: newDataValue
      });
    }
    
    onChange && onChange(this.reverse(newDataValue));
  }

  renderInput() {
    const { dataValue, popupShow, filterText, keyDataMap } = this.state;
    const {
      size,
      data,
      value: propValue,
      valueType,
      valueMode,
      multiple,
      nodeKey,
      nodeText,
      renderNode,
      nodeChildren,
      treeProps: propTreeProps,
      onFilter: propOnFilter,
      filterData,

      children,
      className: propClassName,
      getSelectedText,
      disabled,
      showDisabledValue,
      ...others
    } = this.props;
    const className = classNames(propClassName, 'ten-select  ten-select-input', {
      'ten-select-input--popupshow': popupShow
    });
    const filterable = this.isFilterable();
    const inputDisabled = disabled === true;
    let inputDataValue = dataValue;

    if (!showDisabledValue) {
      inputDataValue = dataValue.filter((key) => {
        const data = keyDataMap[key];
        return data && !this.isNodeDisabled(data.data);
      });
    }

    return (
      <InputSelect
        ref={c => {
          this.refInputComponent = c; 
        }}
        multiple={multiple}
        size={size}
        className={className}
        value={inputDataValue}
        filterValue={filterText}
        onFilter={filterable ? this.onFilter : undefined}
        onClick={this.onTriggerClick}
        getSelectedText={key => keyDataMap[key].text}
        onTagDelete={this.onTagDelete}
        disabled={inputDisabled}
        {...others}
      />
    );
  }

  render() {
    const {
      data,
      size,
      multiple,
      valueMode,
      valueType,
      nodeKey,
      nodeText,
      renderNode,
      nodeChildren,
      filterData,
      onFilter: propOnFilter,
      treeProps: propTreeProps
    } = this.props;
    const { dataValue, expanded, popupShow, filterText, selectedDataKeyMap, treeDataKeyMap, dataKeyMap } = this.state;
    const className = classNames('ten-treeselect__popup', `ten-treeselect__popup--size-${size}`);

    const treeValue = multiple ? dataValue : dataValue[0];
    const treeProps = {
      data,
      multiple,
      selected: treeValue,
      selectedMode: valueMode,
      selectedType: valueType,
      nodeKey,
      nodeText,
      renderNode,
      nodeChildren,
      filterData,
      filterText,
      selectable: true,
    };

    const tree = (
      <div className="ten-treeselect">
        <Tree
          {...treeProps}
          {...propTreeProps}
          selectedType="key"
          filterData={propOnFilter ? false : filterData}
          dataKeyMapFromProps={{
            selectedDataKeyMap,
            treeDataKeyMap,
            dataKeyMap,
          }}
          expanded={expanded}
          onExpand={this.onExpand}
          onChange={this.onChange}
          onClick={this.onTreeClick}
        />
      </div>
    );

    return (
      <Popup
        className={className}
        content={tree}
        show={popupShow}
        width={el => el.offsetWidth}
        direction="bottom left"
        triggerType="manual"
        onHide={this.onPopupHide}
        onShow={this.onPopupShow}
      >
        {
          this.renderInput()
        }
      </Popup>
    );
  }
}

export default TreeSelect;
