function Upload(options) {
  this.opts = {
    ...Upload.default,
    ...options
  };

  return this.init();
}

Upload.default = {};

Upload.prototype.init = function() {
  const { inputName, file, headers, formData = {} } = this.opts;
  const form = new FormData();
  form.append(inputName, file);
  Object.keys(formData).map(key => form.append(key, formData[key]));

  this.xhr = new XMLHttpRequest();
  this.xhr.open(this.opts.method, this.opts.url, true);
  this.xhr.onload = this.uploadComplete.bind(this);
  this.xhr.onerror = this.uploadFailed.bind(this);
  this.xhr.upload.onprogress = this.uploadProgress.bind(this);
  this.xhr.upload.onloadstart = this.startUpload.bind(this);

  Object.keys(headers).map(key => this.xhr.setRequestHeader(key, headers[key]));

  this.xhr.send(form);
  return this.xhr;
};

Upload.prototype.uploadComplete = function(response) {
  const { onSuccess, file } = this.opts;
  onSuccess && onSuccess(file, response);
};

Upload.prototype.uploadFailed = function(response) {
  const { onError, file } = this.opts;
  onError && onError(file, response);
};

Upload.prototype.uploadProgress = function(response) {
  const { onProgress, file } = this.opts;
  onProgress && onProgress(file, response);
};

Upload.prototype.startUpload = function() {
  const { onStart, file } = this.opts;
  onStart && onStart(file);
};

export default options => new Upload(options);
