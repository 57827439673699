import React from 'react';
import PropTypes from 'prop-types';
import Picker from './picker';
import Time from './panel/time';
import TimeRange from './panel/time-range';
import { t } from '../locale'; 
import { withConfig } from '../config';

/**
 * @visibleName TimePicker 时间选择器
 */
class TimePicker extends React.Component {
  static propTypes = {
    disabledHour: PropTypes.func,
    disabledMin: PropTypes.func,
    disabledSec: PropTypes.func,
    startPlaceholder: PropTypes.string,
    endPlaceholder: PropTypes.string,
    placeholder: PropTypes.string,
    format: PropTypes.string
  };

  static defaultProps = {
    format: 'HH:mm:ss'
  };

  render() {
    const { isRange } = this.props;
    const startPlaceholder = this.props.startPlaceholder || t('timePicker.start');
    const endPlaceholder = this.props.endPlaceholder || t('timePicker.end');
    const placeholder = this.props.placeholder || t('timePicker.pleaseSelect');

    return (
      <Picker 
        { ...this.props }
        startPlaceholder={startPlaceholder}
        endPlaceholder={endPlaceholder}
        placeholder={placeholder}
        panel={isRange ? TimeRange : Time}
        type="time"
      />
    );
  }
}

export default withConfig(TimePicker);
