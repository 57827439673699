import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SubMenu from './sub-menu';
import MenuItem from './menu-item';
import MenuContext from './menu-context';
import Icon from '../icon';
import TreeNodeModel from './tree-node-model';

/**
 * @visibleName Menu 菜单
 */
class Menu extends React.Component {

  static propTypes = {
    /**
     * 默认选中的菜单项 index，index 必须 >= 1
     */
    defaultActive: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 选中的菜单项 index
     */
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 定义菜单垂直、水平模式
     */
    mode: PropTypes.oneOf(['vertical', 'horizontal']),
    /**
     * 自定义菜单头部
     */
    renderHeader: PropTypes.func,
    /**  
     * 垂直模式默认展开的子菜单 index
    */
    defaultExpandedIndexes: PropTypes.array,
    /**  
     * 垂直模式展开的子菜单 index
     */
    expandedIndexes: PropTypes.array,
    /**  
     * 展开时，自动收起同级展开的菜单
     */
    closeSiblings: PropTypes.bool,
    /**
     * 选中的菜单项变更回调
     */
    onChange: PropTypes.func,
    /**
     * 打开子菜单回调
     */
    onExpand: PropTypes.func,
    /** 
     * vertical 模式是否允许菜单折叠
    */
    collapsable: PropTypes.bool,
    /**
     * 默认的折叠状态
     */
    defaultCollapsed: PropTypes.bool,
    /** 
     * vertical 模式菜单是否折叠
     */
    collapsed: PropTypes.bool,
    /**
     * vertical 模式菜单折叠状态改变回调
     */
    onCollapsedChange: PropTypes.func,
  };

  static defaultProps = {
    defaultExpandedIndexes: [],
    mode: 'vertical'
  };

  static SubMenu = SubMenu;

  static Item = MenuItem;

  constructor(props) {
    super(props);
    const dataCollapsed = props.collapsed || props.defaultCollapsed;
    this.state = {
      afterCollapsed: dataCollapsed,
      dataCollapsed,
    };
    this.model = new TreeNodeModel('ten-tree-root');
    this.model.deep = -1;
    this.dataActive = props.active || props.defaultActive;
    this.dataExpandedIndexes = props.expandedIndexes || props.defaultExpandedIndexes;
  }

  componentDidMount() {
    if (typeof this.dataActive !== 'undefined') {
      this.applyActive(this.dataActive);
    }
    if (typeof this.dataExpandedIndexes !== 'undefined') {
      this.applyExpanded(this.dataExpandedIndexes);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { defaultActive, active, collapsed, defaultCollapsed, defaultExpandedIndexes, expandedIndexes } = nextProps;
    const { dataActive, dataExpandedIndexes } = this;
    const { dataCollapsed } = this.state;
    if (('collapsed' in nextProps) && collapsed !== dataCollapsed) {
      this.updateCollapsed(collapsed);
    } else if (('defaultCollapsed' in nextProps) && defaultCollapsed !== this.props.defaultCollapsed) {
      this.updateCollapsed(defaultCollapsed);
    }

    if (('active' in nextProps) && active !== dataActive) {
      this.applyActive(active);
    } else if (('defaultActive' in nextProps) && defaultActive !== this.props.defaultActive) {
      this.applyActive(defaultActive);
    }

    if (('expandedIndexes' in nextProps) && expandedIndexes !== dataExpandedIndexes) {
      this.applyExpanded(expandedIndexes);
    } else if (('defaultExpandedIndexes' in nextProps) && defaultExpandedIndexes !== this.props.defaultExpandedIndexes) {
      this.applyExpanded(defaultExpandedIndexes);
    }

    return true;
  }

  applyActive(index) {
    if (typeof index !== 'undefined') {
      const selected = [String(index)];
      this.model.setSelected(selected);
    }
    this.model.applySelected();
    this.dataActive = index;
  }

  applyExpanded(expandedIndexes) {
    if (typeof expandedIndexes !== 'undefined') {
      this.model.setExpanded(expandedIndexes, true);
    }
    this.model.applyExpanded();
    this.dataExpandedIndexes = expandedIndexes;
  }

  updateCollapsed(collapsed) {
    if (collapsed !== this.state.collapsed) {
      this.setState({
        afterCollapsed: false,
        dataCollapsed: collapsed
      });
      if (collapsed) {
        setTimeout(() => {
          this.setState({
            afterCollapsed: true
          });
        }, 300);
      }
    }
  }

  setActive = (index) => {
    const { onChange } = this.props;
    if (!('active' in this.props)) {
      this.applyActive(index);
    }
    onChange && onChange(index);
  }

  setExpanded = (index, expanded) => {
    const { onExpand } = this.props;
    if (!('expandedIndexes' in this.props)) {
      this.applyExpanded();
    }

    const expandedIndexes = this.model.getExpanded();
    onExpand && onExpand(index, expanded, expandedIndexes);
  }

  setCollapse(collapsed) {
    const { onCollapsedChange } = this.props;
    if (!('collapsed' in this.props)) {
      this.updateCollapsed(collapsed);
    }
    onCollapsedChange && onCollapsedChange(collapsed);
  }

  onTollgeCollapse = () => {
    this.setCollapse(!this.state.dataCollapsed);
  }

  renderHeader() {
    const { renderHeader } = this.props;

    if (renderHeader) {
      return (
        <header
          className="ten-menu-header"
        >
          {renderHeader(this.state.dataCollapsed)}
        </header>
      );
    }
    return '';
  }

  renderFooter() {
    const { collapsable, mode } = this.props;
    const { dataCollapsed } = this.state;
    const className = classNames('ten-menu__footer', {
      'ten-menu__footer--collapsed': dataCollapsed
    });

    if (collapsable && mode === 'vertical') {
      return (
        <div
          className={className}
          onClick={this.onTollgeCollapse}
        >
          <Icon
            className="ten-menu__footer-carret"
            type="left"
          />
        </div>
      );
    }
    return '';
  }

  renderChildren() {
    const { children } = this.props;
    return React.Children.map(children, child => {
      if (child && (child.type === SubMenu || child.type === MenuItem)) {
        return React.cloneElement(child, {
          parentModel: this.model,
          dataActive: this.dataActive,
        });
      }
      return child;
    });
  }

  render() {
    const { className: propClassName, mode, collapsable, closeSiblings, style } = this.props;
    const { dataActive, dataCollapsed, afterCollapsed } = this.state;
    const className = classNames(propClassName, 'ten-menu', {
      [`ten-menu--${mode}`]: mode,
      'ten-menu--vertical--collapsable': collapsable,
      'ten-menu--vertical--collapsed': dataCollapsed,
      'ten-menu--vertical--aftercollapsed': afterCollapsed,
    });

    const provideValue = {
      mode,
      dataActive,
      dataCollapsed,
      closeSiblings,
      setActive: this.setActive,
      setExpanded: this.setExpanded
    };

    return (
      <MenuContext.Provider value={provideValue}>
        <nav className={className} style={style}>
          { this.renderHeader() }
          <div className="ten-menu-nav">
            { this.renderChildren() }
          </div>
          { this.renderFooter() }
        </nav>
      </MenuContext.Provider>
    );
  }
}

export default Menu;
