import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './menu-item';


/**
 * @visibleName Menu.SubMenu 子菜单
 */
export default class SubMenu extends React.PureComponent {

  static propTypes = {
    /** 
     * 子菜单标题
    */
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** 
     * 唯一标识
     */
    index: PropTypes.string,
    /** 
     * 子菜单 icon
     */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }

  render() {
    const { title, children, ...others } = this.props;
    return (
      <MenuItem {...others} isMenu>
        {title}
        {children}
      </MenuItem>
    );
  }
}
