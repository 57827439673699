import PropTypes from 'prop-types';
import React from 'react';
import * as childrenUtils from './utils/children-utils';
import AnimateItem from './animate-item';

const childMapfn = childData => childData.child;

/**
 * @visibleName Animate 动画
 * @example ./animate-interval.md
 */
class Animate extends React.Component {

  // same to Animate
  static propTypes = {
    /**
     * 指定进出场类型
     */
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * 随 Animate 组件首次挂载是否展示动画
     */
    appear: PropTypes.bool,
    /**
     * 进入钩子，用于部署进入动画的准备工作
     */
    onEnter: PropTypes.func,
    /**
     * 进入钩子之后调用，用于触发进入动画
     */
    onEntering: PropTypes.func,
    /**
     * 进入动画执行完成钩子
     */
    onEnterd: PropTypes.func,
    /**
     * 离开钩子，元素将要隐藏调用
     */
    onExit: PropTypes.func,
    /**
     * 离开钩子之后调用，用于触发离开动画
     */
    onExiting: PropTypes.func,
    /**
     * 离开动画执行完成钩子
     */
    onExited: PropTypes.func,
    /**
     * 列表动画，子元素会以 interval 时间间隔依次进入消失
     */
    interval: PropTypes.number,
    /**
     * 控制子元素显示隐藏，而非直接移除
     */
    isShow: PropTypes.func
  };

  static defaultProps = {
    interval: 0,
    isShow: () => true
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      childrenData: []
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isShow, interval } = nextProps;
    const { getArrayChildren, findChildInArrayByKey } = childrenUtils;
    const currChildrenData = prevState.childrenData;
    const nextChildren = getArrayChildren(nextProps.children);
    
    let newChildrenData = [];
    
    const keysWillEnter = [];
    const keysWillExit = [];

    const nextChildrenPending = {};
    let pendingChildren = [];
    currChildrenData.forEach((childData) => {
      let show = true;
      let mount = true;

      if (!isShow(childData.child)) {
        show = false;
      } else {
        show = true;
      }

      if (findChildInArrayByKey(nextChildren, childData.key)) {
        if (pendingChildren.length) {
          nextChildrenPending[childData.child.key] = pendingChildren;
          pendingChildren = [];
        }
      } else {
        // exit
        mount = false;
        pendingChildren.push(childData);
      }

      const preIn = childData.show && childData.mount;
      const currIn = show && mount;
      if (preIn && !currIn) {
        childData.delay = 0;
        keysWillExit.push(childData.key);
      } else if (!preIn && currIn) {
        childData.delay = 0;
        keysWillEnter.push(childData.key);
      }

      childData.show = show;
      childData.mount = mount;
    });
  
    nextChildren.forEach((child) => {
      const childData = {
        child,
        key: child.key,
        mount: true,
        show: isShow(child),
        delay: 0
      };
      if (childData && Object.prototype.hasOwnProperty.call(nextChildrenPending, childData.key)) {
        newChildrenData = newChildrenData.concat(nextChildrenPending[childData.key]);
      }
      if (!findChildInArrayByKey(currChildrenData, childData.key, childMapfn)) {
        keysWillEnter.push(childData.key);
      }
      newChildrenData.push(childData);
    });
  
    newChildrenData = newChildrenData.concat(pendingChildren);

    if (interval) {
      let enterDelay = 0;
      let exitDelay = (keysWillExit.length - 1) * interval;
      newChildrenData.forEach(childData => {
        if (keysWillEnter.indexOf(childData.key) > -1) {
          childData.delay = enterDelay;
          enterDelay += interval;
        } else if (keysWillExit.indexOf(childData.key) > -1) {
          childData.delay = exitDelay;
          exitDelay -= interval;
        }
      });
    }


    return { childrenData: newChildrenData };
  }
  
  onUnmount = (component) => {
    if (component.props.mount || !this.mounted) {
      return;
    }

    const key = component.key || component.props.propKey;
    let newChildrenData = [...this.state.childrenData];

    newChildrenData = newChildrenData.filter(child => child.key !== key);

    this.setState({
      childrenData: newChildrenData,
    });
  }

  render() {

    const { childrenData } = this.state;
    const children = childrenData.map(childData => {
      const childProps = { ...this.props };
      childProps.mount = childData.mount;
      childProps.show = childData.show;
      childProps.delay = childData.delay;
      childProps.onUnmount = this.onUnmount;
      childProps.mountWithParent = !this.mounted;

      return (
        <AnimateItem key={childData.key} propKey={childData.key} { ...childProps }>{childData.child}</AnimateItem>
      );
    });

    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default Animate;
