export default {
  setTimeoutIfNeed(fn, delay) {
    if (delay === 0) {
      fn();
      return 0;
    }
    return setTimeout(fn, delay);
  },

  getValByKey(obj, key) {
    return typeof obj === 'object' ? obj[key] : undefined;
  },
  omit(obj, fields) {
    const shallowCopy = {
      ...obj,
    };
    for (let i = 0; i < fields.length; i++) {
      const key = fields[i];
      delete shallowCopy[key];
    }
    return shallowCopy;
  },
  isEmpty(value) {
    return value === null || value === undefined;
  }
};
