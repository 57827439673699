import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Pagination from '../pagination';
import BaseTable from './base';
import FixedTable from './fixed';
import Loading from '../loading';
import { addResizeListener, removeResizeListener } from '../utils/resize-observer';


const ALL_CHECK_KEY = 'all-checked';
const prefixClassName = 'ten-table';

const defaultPagination = {
  currentPage: 1,
  pageSize: 10
};

const ORDER = {
  ASC: 'asc',
  DESC: 'desc'
};

/**
 * @visibleName Table 表格
 */
class Table extends React.Component {
  static propTypes = {
    /**
     * 数据列表
     */
    data: PropTypes.arrayOf(PropTypes.object),
    /**
     * 数据列表 
     */
    emptyText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /**
     * 列信息配置
     */
    columns: PropTypes.arrayOf(PropTypes.object),
    /**
     * 表格的行key参数名
     */
    rowKey: PropTypes.string,
    /**
     * 多选项信息 可配置 keys、onChange、selectable、disabledSelectAll 属性
     */
    selector: PropTypes.object,
    /**
     * 分页配置
     */
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * 是否启用固定表头以及固定列功能，固定列需要设置表格宽度
     */
    fixed: PropTypes.bool,
    /**
     * 是否加载中
     */
    loading: PropTypes.bool,
    /**
     * 请先设置 fixed 为 true 如果当前容器宽度小于设置的宽度, 则固定列
     */
    minWidth: PropTypes.number,
    /**
     * 请先设置 fixed 为 true 如果当前表格高度大于设置的高度, 则固定表头
     */
    maxHeight: PropTypes.number,
    /**
     * 设置行属性
     */
    rowProps: PropTypes.func,
    /**
     * 设置头部行属性
     */
    headerProps: PropTypes.func
  };

  static defaultProps = {
    data: [],
    emptyText: '暂无数据',
    columns: [],
    rowKey: 'id',
    selector: {},
    pagination: {},
    fixed: false,
    loading: false,
    rowProps: () => { },
    headerProps: () => { }
  };

  static getDerivedStateFromProps(props, prevState) {
    const { columns } = props;
    const { sorter } = prevState;
    const newState = {};
    if ('pagination' in props && props.pagination) {
      newState.pagination = {
        ...defaultPagination,
        ...prevState.pagination,
        ...props.pagination
      };
    }
    if ('selector' in props && props.selector.keys) {
      newState.selectKeys = props.selector.keys;
      newState.selectable = props.selector.selectable || (() => true);
    }

    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      if (sorter && sorter.column.dataKey === column.dataKey && typeof column.order !== 'undefined') {
        newState.sorter = {
          column,
          onSort: column.onSort,
          order: column.order
        };
        break;
      }
    }

    return newState;
  }

  constructor(props) {
    super(props);

    const { pagination: propPagination = {}, selector } = props;

    const pagination = {
      ...defaultPagination,
      ...propPagination
    };

    this.state = {
      selectKeys: selector.keys || [],
      selectable: () => true,
      pagination,
      colWidth: [],
      expands: [],
      sorter: {
        order: '',
        column: {},
        onSort: null
      },
      tableContainer: {
        width: 0,
        height: 0
      }
    };

    this.refTableContainer = null;
  }

  componentDidMount() {
    this.resize();
    addResizeListener(this.refTableContainer, this.onResize);
  }

  componentWillUnmount() {
    removeResizeListener(this.refTableContainer, this.onResize);
  }

  componentDidUpdate() {
    this.onResize();
  }

  onResize = () => {
    this.resize();
  };

  resize = () => {
    const { width, height } = this.state.tableContainer;
    const { clientHeight, clientWidth } = this.tableContainer;
    if (width !== clientWidth || height !== clientHeight) {
      this.setState({
        tableContainer: {
          width: clientWidth,
          height: clientHeight
        }
      });
    }
  };

  currentPageData = [];

  onSorterHandler = propColumn => {
    const { sorter } = this.state;
    const { onSort } = propColumn;
    const orders = propColumn.sortDirections || [ORDER.ASC, ORDER.DESC];

    let order;
    if (typeof propColumn.order !== 'undefined') {
      order = propColumn.order;
    } else if (sorter.column.dataKey === propColumn.dataKey) {
      order = sorter.order;
    }

    const nextOrder = orders[orders.indexOf(order) + 1] || '';
    const sortColumn = propColumn;
    const newSorter = {
      onSort,
      column: sortColumn,
      order: 'order' in propColumn ? propColumn.order : nextOrder
    };
    this.setState({
      sorter: newSorter
    });
    onSort && onSort(nextOrder);
  };

  onChangeCheckbox = (checked, value) => {
    const { props } = this;
    const {
      rowKey,
      selector: { onChange }
    } = props;
    const { selectKeys, selectable } = this.state;
    const data = this.currentPageData;
    const allKeys = data.filter((item, index) => selectable(index, item)).map(item => item[rowKey]);
    let keys = [...selectKeys];
    if (value === ALL_CHECK_KEY && checked) {
      allKeys.map(k => keys.push(k));
      keys = [...new Set(keys)];
    } else if (value === ALL_CHECK_KEY && !checked) {
      keys = keys.filter(x => allKeys.indexOf(x) === -1);
    } else {
      const index = keys.indexOf(value);
      index !== -1 ? keys.splice(index, 1) : keys.push(value);
    }
    this.setState({
      selectKeys: keys
    });
    onChange && onChange(keys);
  };

  onExpandHandler = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    const { expands } = this.state;
    const index = expands.indexOf(key);
    index === -1 ? expands.push(key) : expands.splice(index, 1);
    this.setState({ expands });
  };

  getCurrentPageData = () => {
    const { data, pagination: propPagination } = this.props;
    const { pagination } = this.state;
    const currentPage = pagination.currentPage;
    const pageSize = pagination.pageSize;
    const minLimit = (currentPage - 1) * pageSize;
    const maxLimit = currentPage * pageSize;
    const { sorter } = this.state;
    const currentData = [...data];

    if (!sorter.onSort && sorter.column && sorter.order) {
      currentData.sort(sorter.column.sorter);
      sorter.order === ORDER.DESC && currentData.reverse();
    }
    this.currentPageData = currentData;
    // 如果props分页存在并且当前数据量大于分页数则进行分页处理
    if (propPagination !== false && currentData.length > pagination.pageSize) {
      this.currentPageData = currentData.filter((_o, index) => index >= minLimit && index < maxLimit);
    }

    return this.currentPageData;
  };

  paginationChange = pageNum => {
    const { pagination } = this.state;
    pagination.currentPage = pageNum;
    this.setState({ pagination });
  };

  paginationSizeChange = pageSize => {
    const { pagination } = this.state;
    pagination.pageSize = pageSize;
    pagination.currentPage = 1;
    this.setState({ pagination });
  };

  render() {
    const { props, state } = this;
    const {
      style,
      className: propClassName,
      data,
      fixed,
      pagination: propPagination,
      loading,
      minWidth,
      maxHeight,
      ...other
    } = props;
    const { expands, selectKeys, colWidth, sorter, pagination, tableContainer, selectable } = state;
    const { onExpandHandler, onChangeCheckbox, onSorterHandler } = this;

    this.getCurrentPageData();

    const className = classNames(propClassName, prefixClassName);

    const tableContainerClassName = `${prefixClassName}-container`;

    const paginationProps = {
      total: data.length,
      onChange: this.paginationChange, // 页码改变的回调
      onPageSizeChange: this.paginationSizeChange, // pageSize变化的回调
      ...pagination
    };
    const showPagination =
      propPagination && (paginationProps.total > 0 || paginationProps.pageCount > 0);

    const fixedWidth = tableContainer.width && tableContainer.width < minWidth ? minWidth : 0;
    const fixedHeight = (fixed && maxHeight) ? maxHeight : 0;
    const useFixed = fixed && (fixedWidth || fixedHeight);

    const TableProps = {
      ...other,
      width: fixedWidth,
      height: fixedHeight,
      data: this.currentPageData,
      prefixClassName,
      selectKeys,
      selectable,
      expands,
      onChangeCheckbox,
      colWidth,
      sorter,
      onSorterHandler,
      onExpandHandler,
      ALL_CHECK_KEY
    };

    return (
      <Loading loading={loading}>
        <div className={className} style={style} ref={el => {
          this.refTableContainer = el; 
        }}>
          <div
            className={tableContainerClassName}
            ref={node => {
              this.tableContainer = node;
            }}
          >
            {useFixed ? <FixedTable {...TableProps} /> : <BaseTable {...TableProps} />}
          </div>
          {showPagination && (
            <div className={`${prefixClassName}_page`}>
              <Pagination {...paginationProps} />
            </div>
          )}
        </div>
      </Loading>
    );
  }
}

export default Table;
