import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @visibleName Badge 徽标数
 */
class Badge extends React.Component {
  static propTypes = {
    /**
     * 是否只展示小红点，不展示数值
     */
    dot: PropTypes.bool,
    /**
     * 是否设置为圆角型徽标
     */
    round: PropTypes.bool,
    /**
     * 是否展示在右上角，dot 设置为 true 时默认 corner 也为 true
     */
    corner: PropTypes.bool,
    /**
     * 展示的数值
     */
    count: PropTypes.number,
    /**
     * 展示的最大数值，大于该数值时显示最大值且后面添加符号'+'，为 0 时隐藏
     */
    maxCount: PropTypes.number,
    /**
     * 自定义 Badge 的背景颜色,当只传入 backgroundColor，不传 color 时，color 默认为 '#FFFFFF'
     */
    backgroundColor: PropTypes.string,
    /**
     * 自定义 Badge 的字体颜色,当只传入 color，不传 backgroundColor 时，backgroundColor 默认为 '#FF3E00'
     */
    color: PropTypes.string,
    /**
     * 设置 Badge 主题,当传入 backgroundColor / color 时，theme 无效
     */
    theme: PropTypes.oneOf(['info', 'success', 'error', 'warning', 'disabled']),
    /**
     * 自定义 Badge 的内容
     */
    text: PropTypes.string,
    /**
     * 设置状态点的位置偏移，格式为 [x, y], 仅在 dot 开启下有效
     */
    offset: PropTypes.array
  };

  static defaultProps = {
    dot: false,
    corner: true,
    round: true,
    maxCount: 99,
    theme: 'error'
  };

  renderBadge = () => {
    const {
      dot,
      round,
      corner: propCorner,
      count,
      text: propText = '',
      maxCount,
      color,
      backgroundColor,
      offset
    } = this.props;
    const text = dot ? '' : propText;
    const corner = dot ? true : propCorner;
    const style = {};

    if (backgroundColor) {
      style.backgroundColor = backgroundColor;
    }

    if (color) {
      style.color = color;
    }

    if (corner && offset) {
      style.marginLeft = -parseInt(offset.x) + 'px';
      style.marginTop = -parseInt(offset.y) + 'px';
    }

    const badgeClass = classNames([
      'ten-badge__badge',
      {
        'ten-badge__badge--dot': dot,
        'ten-badge__badge--round': round
      }
    ]);

    let badgeText;
    if (dot) {
      badgeText = '';
    } else if (typeof count !== 'undefined') {
      badgeText = text + ' ' + Math.min(count, maxCount) + (count > maxCount ? '+' : '');
    } else {
      badgeText = text || '';
    }

    return (
      <span style={style} className={badgeClass}>
        {badgeText.trim()}
      </span>
    );
  };

  render() {
    const {
      children,
      className,
      theme,
      dot,
      corner,
      round,
      maxCount,
      color,
      backgroundColor,
      ...others
    } = this.props;

    const classes = classNames(className, 'ten-badge', `ten-badge--theme-${theme}`, {
      'ten-badge--pos-corner': dot || corner,
      'ten-badge--pos-normal': !(dot || corner),
      'ten-badge--dot': dot,
      'ten-badge--round': round
    });

    return (
      <span {...others} className={classes}>
        {children}
        {this.renderBadge()}
      </span>
    );
  }
}

export default Badge;
