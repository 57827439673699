/* eslint no-underscore-dangle: 0 */
export default class TreeNodeModel {
  constructor(key, isLeaf, data, onApply, dataActive) {
    this.children = [];
    this.isLeaf = isLeaf;
    this.selected = dataActive === key ? 1 : 0; // 0 unSelected 1 Selected 2 halfSelected
    this.expanded = 0; // 0 unExpanded 1 Expanded
    this.key = key;
    this.data = data;
    this.onApply = onApply;
    this.deep = 0;

    this.status = {
      selected: this.selected,
      expanded: this.expanded
    };
    this.applyT = 0;
  }

  setStatus(status) {
    this.status = status;
    this.selected = status.selected;
    this.expanded = status.expanded;
  }

  apply() {
    this.children.forEach(child => child.apply());
    if (
      this.status.selected !== this.selected &&
      this.status.expanded !== this.expanded
    ) {
      this.status.selected = this.selected;
      this.status.expanded = this.expanded;
      this.fireApply();
    }
  }

  applySelected() {
    this.children.forEach(child => child.applySelected());
    if (this.status.selected !== this.selected) {
      this.status.selected = this.selected;
      this.fireApply();
    }
  }

  applyExpanded() {
    this.children.forEach(child => child.applyExpanded());
    if (this.status.expanded !== this.expanded) {
      this.status.expanded = this.expanded;
      this.fireApply();
    }
  }

  fireApply() {
    if (this.onApply) {
      clearTimeout(this.applyT);
      this.applyT = setTimeout(() => { 
        this.onApply && this.onApply();
      });
    }
  }

  mount(parent) {
    parent.addChild(this);
    this.parent = parent;
    this.deep = this.parent.deep + 1;
    if (this.parent.selected === 1) {
      this.selected = 1;
    }
    if (this.parent.status.selected === 1) {
      this.status.selected = 1;
    }
  }

  unMount() {
    if (parent) {
      this.parent.removeChild(this);
      this.parent = null;
    }
  }

  addChild(child) {
    if (!this.isLeaf && !this.children.find(item => item === child)) {
      this.children.push(child);
      child.parent = this;
    }
  }

  addChildren(children) {
    if (Array.isArray(children)) {
      children.forEach(child => this.addChild(child));
    }
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    if (index > -1) {
      this.children.splice(index, 1);
    }
  }

  walk(fn) {
    const stop = fn(this);
    !stop && this.children && this.children.forEach(child => child.walk(fn));
  }

  setSelected(selectedKeys) {
    this.walk(function(node) {
      node.select(0);
    });
    this.walk(function(node) {
      if (selectedKeys.indexOf(node.key) > -1) {
        node.select(1);
        return true;
      }
      return undefined;
    });
  }

  setExpanded(expandedKeys, expandParent) {
    this.walk(function(node) {
      if (expandedKeys.indexOf(node.key) > -1) {
        node.expand(1, expandParent);
      } else {
        node.expand(0);
      }
    });
  }

  /**
   * @param {string} mode 'all', 'onlyLeaf', 'parentFirst'
   */
  getSelected(mode = 'all') {
    const arr = [];

    /* eslint-disable */
    function collect(node) {
      switch (mode) {
        case 'all':
          if (node.selected === 1) {
            arr.push(node.key);
          }
          break;
        case 'parentFirst':
          if (node.selected === 1) {
            arr.push(node.key);
            return true;
          }
          return node.selected !== 2;
        case 'onlyLeaf':
          if (node.isLeaf) {
            if (node.selected === 1) {
              arr.push(node.key);
            }
          }
      }
    }
    /* eslint-enable */

    this.walk(collect);
    return arr;
  }

  getExpanded() {
    const expandedKeys = [];
    this.walk(function(node) {
      if (node.expanded) {
        expandedKeys.push(node.key);
      }
    });
    return expandedKeys;
  }

  toggleSelect() {
    this.select(this.status.selected === 1 ? 0 : 1);
  }

  select(selected, propagation = true) {
    this.selected = selected; // 0 or 1
    if (propagation) {
      this.parent && this.parent.updateSelected();
    }
    if (!this.isLeaf && (selected === 1 || selected === 0)) {
      this.children.forEach(child => child.select(selected, false));
    }
  }

  updateSelected() {
    if (!this.isLeaf) {
      let allSelectedCount = 0;
      let selectedCount = 0;
      this.children.forEach(child => {
        if (child.selected === 1 || child.selected === 2) {
          selectedCount += 1;
          if (child.selected === 1) {
            allSelectedCount += 1;
          }
        }
      });

      /* eslint-disable */
      const selected = allSelectedCount === this.children.length ? 1 : (selectedCount > 0 ? 2 : 0);
      /* eslint-enable */

      this.selected = selected;
      this.parent && this.parent.updateSelected();
    }
  }

  toggleExpand(expandParent, closeSiblings) {
    this.expand(this.status.expanded === 1 ? 0 : 1, expandParent, closeSiblings);
  }

  expand(expanded, expandParent = false, closeSiblings) {
    if (expanded && closeSiblings) {
      this.parent && this.parent.expandChildren(0);
    }
    this.expanded = expanded;
    if (expanded && expandParent) {
      this.parent && this.parent.expand(1, expandParent);
    }
  }

  expandChildren(expanded) {
    this.children.forEach(child => child.expand(expanded));
  }
}
