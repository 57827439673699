import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NP from 'number-precision';
import Input from './input';
import valueFormat from './value-format';

const InputFormatNumber = valueFormat(Input);

function getValidNum(val = '', trigger = 'input') {
  let value = String(val);

  /* eslint-disable */
  value = value
    .replace(/^-/, '*').replace('-', '').replace('*', '-')
    .replace(/[^-\d\.]/g, '').replace(/\d*\.(?=\d*\.)/g, function (dotStr) {
    return dotStr.replace('.', '');
  });
  /* eslint-enable */

  if (trigger === 'input') {
    return value;
  }
  if (value !== '' && !isNaN(Number(value))) {
    value = Number(value);
  }

  return value === '' ? undefined : value;
}

class InputNumber extends React.Component {
  static propTypes = {
    /**
     * change事件
     */
    onChange: PropTypes.func,
    /**
     * 步长
     */
    step: PropTypes.number,
    /**
     * 最大值
     */
    max: PropTypes.number,
    /**
     * 最小值
     */
    min: PropTypes.number,
    /**
     * 值
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 初始值
     */
    initialValue: PropTypes.number,
    /**
     * 与formatter配合使用
     */
    parser: PropTypes.func,
    /**
     * 配合parser使用，格式化显示
     */
    formatter: PropTypes.func,
  };

  static defaultProps = {
    step: 1
  };

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      
      return { value: getValidNum(nextProps.value) };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = { value: getValidNum(props.value || props.initialValue) };
  }

  onChange = (value) => {
    const { onChange } = this.props;

    if (!('value' in this.props)) {
      this.setState({ value });
    }

    onChange && onChange(value);
  }

  onKeyDown = (e) => {
    const { onKeyDown, disabled } = this.props;

    if (disabled) {
      return;
    }
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.keyCode === 38 ? this.onUpClick() : this.onDownClick();
      e.preventDefault();
      return;
    } 

    onKeyDown && onKeyDown(e);
  }

  onUpClick = () => {
    this.stepChange(1);
  }

  onDownClick = () => {
    this.stepChange(-1);
  }

  onUpKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.stepChange(1);
    }
  }

  onDownKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.stepChange(-1);
    }
  }

  stepChange(neg = 1) {
    const { step, disabled } = this.props;
    const { value: stateValue } = this.state;

    if (disabled) {
      return;
    }

    let value = stateValue;

    value = Number(value);
    value = isNaN(value) ? 0 : value;
    value = NP.plus(value, step * neg);
    this.onChange(this.checkRange(value));
  }

  checkRange = (value) => {
    const { max, min } = this.props;

    // eslint-disable-next-line no-empty
    if (value === '') {
    } else if (typeof min === 'number' && value < min) {
      value = min;
    } else if (typeof max === 'number' && value > max) {
      value = max;
    }
    return value;
  }

  render() {

    const {
      value
    } = this.state;


    const {

      onChange,
      value: propValue,
      initialValue,
      inputSuffix,
      formatter,
      parser,
      min,
      max,
      ...others

    } = this.props;

    const upDisabled = max <= value;
    const downDisabled = min >= value && value;

    const action = (
      <div className="ten-input-number__action">
        <span
          role="button"
          tabIndex={0}
          disabled={upDisabled}
          className={classNames('ten-input-number__action-up', {
            'ten-input-number__action--disabled': upDisabled
          })}
          onClick={this.onUpClick}
          onKeyDown={this.onUpKeyDown}
        />
        <span
          role="button"
          tabIndex={0}
          disabled={downDisabled}
          className={classNames('ten-input-number__action-down', {
            'ten-input-number__action--disabled': downDisabled
          })}
          onClick={this.onDownClick}
          onKeyDown={this.onDownKeyDown}
        />
      </div>
    );

    return (
      <InputFormatNumber
        className="ten-input-number"
        type="text"
        value={value}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        formatter={formatter}
        parser={[
          {
            parser
          },
          { 
            parser: getValidNum,
            options: { input: true, blur: false, enter: false }
          },
          {
            parser: this.checkRange,
            options: { input: false, blur: true, enter: true }
          }
        ]}
        inputSuffix={
          action
        }
        {...others}
      />
    );
  }
}

export default InputNumber;
