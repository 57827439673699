import React from 'react';
import classNames from 'classnames';

export default function status(props) {
  const { type, className: propClassName, icon, message, ...others } = props;
  const className = classNames(propClassName, 'ten-status', {
    [`ten-status--type-${type}`]: type
  });

  return (
    <div className={className} {...others}>
      {
        icon && 
        <div className="ten-status__icon">{ icon }</div>
      }
      { 
        message && 
        <div className="ten-status__message">{ message }</div>
      }
    </div>
  );
}
