import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { getAnimateTypeAndParams } from './animate-types';
import utils from '../utils';
import domHelper from '../utils/dom-helper';

const emptyFn = () => { };

class AnimateItem extends React.Component {
  static propTypes = {
    // todo
    delay: PropTypes.number,
    appear: PropTypes.bool,
    mount: PropTypes.bool,
    show: PropTypes.bool,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    params: PropTypes.object,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    onExited: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    // enterDelay: 0,
    appear: false,
    params: {},
    onEnter: emptyFn,
    onEntering: emptyFn,
    onEntered: emptyFn,
    onExit: emptyFn,
    onExiting: emptyFn,
    onExited: emptyFn,
    onCancel: emptyFn
  };

  constructor(props) {
    super(props);
    const { appear, mountWithParent, show, delay } = props;
    // const showAndMount = show && mount;
    const initialStatus = !appear && mountWithParent && show ? 'entered' : 'exited';
    const state = {
      status: initialStatus,
    };

    if (props.type) {
      // { enter: { type, params }, exit: { type, params } }
      state.animateType = getAnimateTypeAndParams(props.type);
    }

    this.state = state;
    this.delayT = 0;

    this.firstMount = true;
    this.waitToEnter = initialStatus === 'exited' && delay > 0;
    this.hide = !props.show;
    this.mounted = true;
  }

  componentDidMount() {
    this.update(this.props, this.state);
  }

  componentDidUpdate(/* prevProps, prevState */) {
    this.update(this.props, this.state);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.fireHook('onCancel');
  }

  setStateSafely(...args) {
    if (this.mounted) {
      this.setState(...args);
    }
  }

  update(props, state) {
    const { show, mount } = props;
    const showAndMount = show && mount;

    if (this.firstMount && !show) {
      this.firstMount = false;
      const domNode = ReactDOM.findDOMNode(this);
      domHelper.css(domNode, { 'display': 'none' });
      this.setState({ status: 'exited' });
      this.hide = true;
      return;
    }

    if (showAndMount && state.status.indexOf('enter') === -1) {
      this.enter();
    } else if (!showAndMount && state.status.indexOf('exit') === -1) {
      this.exit();
    }

    this.firstMount = false;
  }

  fireHook(hookName, options) {
    const { animateType } = this.state;
    const step = hookName.indexOf('enter') > -1 ? 'enter' : 'exit';

    if (animateType && typeof animateType[step].type[hookName] === 'function') {
      const typeOptions = { ...options, params: animateType[step].params };
      animateType[step].type[hookName](typeOptions);
    }
    this.props[hookName](options);
  }

  getChildren(status, children) {
    const { animateType } = this.state;
    const step = status.indexOf('enter') > -1 ? 'enter' : 'exit';
    if (animateType && typeof animateType[step].type.getChildren === 'function') {
      return animateType[step].type.getChildren(children, status);
    }
    return children;
  }

  /* eslint-disable */
  enter() {
    const { delay } = this.props;

    this.fireHook('onCancel');
    this.setStateSafely({ status: 'beforeenter' });
    this.waitToEnter = delay > 0;

    clearTimeout(this.delayT);
    this.delayT = utils.setTimeoutIfNeed(() => {
      this.waitToEnter = false;
      this.setStateSafely({ status: 'enter' }, () => {
        const domNode = ReactDOM.findDOMNode(this);

        if (this.hide) {
          this.hide = false;
          domHelper.css(domNode, { 'display': '' });
        }
        this.fireHook('onEnter', { domNode, component: this });

        this.setStateSafely({ status: 'entering' });

        this.fireHook('onEntering', {
          domNode,
          component: this,
          done: () => {
            this.setStateSafely({ status: 'entered' }, () => {
              const domNode = ReactDOM.findDOMNode(this);

              this.fireHook('onEntered', { domNode, component: this });
            });
          }
        });
      });
    }, delay);
  }

  exit() {
    const { delay } = this.props;

    this.fireHook('onCancel');
    this.setState({ status: 'beforeexit' });
    this.waitToEnter = false;

    clearTimeout(this.delayT);
    this.delayT = utils.setTimeoutIfNeed(() => {
      this.setState({ status: 'exit' }, () => {
        const domNode = ReactDOM.findDOMNode(this);
        this.fireHook('onExit', { domNode, component: this });

        this.setState({ status: 'exiting' });

        this.fireHook('onExiting', {
          domNode,
          component: this,
          done: () => {
            this.setState({ status: 'exited' }, () => {
              const { mount, show } = this.props;
              const domNode = ReactDOM.findDOMNode(this);
              this.fireHook('onExited', { domNode, component: this });

              const hideAfterExit = mount && !show;
              if (hideAfterExit) {
                domHelper.css(domNode, { 'display': 'none' });
                this.hide = true;
              } else {
                this.props.onUnmount(this);
              }
            });
          }
        });
      });
    }, delay);
  }
  /* eslint-enable */

  render() {
    const { status } = this.state;
    const children = this.getChildren(status, this.props.children);

    return this.waitToEnter && !this.hide ? null : children;
  }
}

export default AnimateItem;
