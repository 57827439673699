// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement, forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M7.5 12V6.5h1V12h-1zM8.6 4H7.4v1.2h1.2V4z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M1 8a7 7 0 1014 0A7 7 0 001 8zm1 0a6 6 0 1112 0A6 6 0 012 8z","fillOpacity":0.9}}]};

const InfoCircleIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'info-circle',
    ref,
    icon: element,
  },
));

InfoCircleIcon.displayName = 'InfoCircleIcon';

export default InfoCircleIcon;
