import chunk from 'lodash/chunk';
import dateUtil from '../utils/date';
import { range } from '../utils/numbers';
import { t } from '../locale';

function getFirstDayOfMonth({ year, month }) {
  return new Date(year, month, 1);
}

function getDaysInMonth({ year, month }) {
  return new Date(year, month + 1, 0).getDate();
}

function getLastDayOfMonth({ year, month }) {
  return new Date(year, month, getDaysInMonth({ year, month }));
}

function isSameYear(date1, date2) {
  return date1.getFullYear() === date2.getFullYear();
}

function isSameMonth(date1, date2) {
  return isSameYear(date1, date2) && date1.getMonth() === date2.getMonth();
}

function isSameDate(date1, date2) {
  return isSameMonth(date1, date2) && date1.getDate() === date2.getDate();
}

/**
 * 设置日期对象的时间部分
 * @param {Date} d 日期
 * @param {Number} hour 小时
 * @param {Number} min 分钟
 * @param {Number} sec 秒
 * @returns {Date} 一个新的date
 */
export function setDateTime(d, hour, min, sec) {
  const { year, month, date } = getDateObj(d);
  return new Date(year, month, date, hour, min, sec, 0);
}

function compareAsc(date1, date2) {
  const d1 = date1.getTime();
  const d2 = date2.getTime();

  if (d1 < d2) return -1;
  if (d1 > d2) return 1;
  return 0;
}

function isSameTimeHour(time1, time2) {
  return time1.getHours() === time2.getHours();
}

function isSameTimeMins(time1, time2) {
  return isSameTimeHour(time1, time2) && time1.getMinutes() === time2.getMinutes();
}

function isSameTimeSecs(time1, time2) {
  return isSameTimeMins(time1, time2) && time1.getSeconds() === time2.getSeconds();
}

function isBetween(value, { start, end }) {
  const date = new Date(value.getFullYear(), value.getMonth(), value.getDate());

  const startTime = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  const endTime = new Date(end.getFullYear(), end.getMonth(), end.getDate());

  return startTime <= date && endTime >= date;
}

export function firstUpperCase(str) {
  if (!str) return str;
  return str[0].toUpperCase().concat(str.substring(1, str.length));
}

export function isSameTime(time1, time2, type = 'secs') {
  const func = {
    isSameTimeHour,
    isSameTimeMins,
    isSameTimeSecs
  };
  return func[`isSameTime${firstUpperCase(type)}`](time1, time2);
}

export function isSame(date1, date2, type = 'date') {
  const func = {
    isSameYear,
    isSameMonth,
    isSameDate
  };
  return func[`isSame${firstUpperCase(type)}`](date1, date2);
}

export function getToday() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getDateObj(date) {
  if (!(date instanceof Date)) {
    date = getToday();
  }
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    meridiem: date.getHours() > 11 ? 'PM' : 'AM'
  };
}

export function minTimeOfDate(d) {
  const { year, month, date } = getDateObj(d);
  return new Date(year, month, date, 0, 0, 0);
}

export function padZero(num) {
  return num >= 10 ? num.toString() : `0${num}`;
}

export function outOfRanges(d, min, max) {
  return (min && compareAsc(d, min) === -1) || (max && compareAsc(d, max) === 1);
}

export function getWeeks(
  { year, month },
  {
    firstDayOfWeek,
    disabledDate = () => false,
    minDate,
    maxDate
  } = {}
) {

  const prependDay = getFirstDayOfMonth({ year, month });

  const appendDay = getLastDayOfMonth({ year, month });

  const maxDays = getDaysInMonth({ year, month });

  const daysArr = [];
  let i = 1;

  const today = getToday();

  for (i; i <= maxDays; i++) {

    const currentDay = new Date(year, month, i);

    daysArr.push({
      text: String(i),
      active: false,
      value: currentDay,
      disabled: disabledDate(currentDay) || outOfRanges(currentDay, minDate, maxDate),
      now: isSame(today, currentDay),
      firstDayOfMonth: i === 1,
      lastDayOfMonth: i === maxDays
    });
  }

  if (prependDay.getDay() !== firstDayOfWeek) {
    prependDay.setDate(0); // 上一月

    while (true) { // eslint-disable-line
      daysArr.unshift({
        text: prependDay.getDate().toString(),
        active: false,
        value: new Date(prependDay),
        disabled: disabledDate(prependDay) || outOfRanges(prependDay, minDate, maxDate),
        additional: true // 非当前月
      });
      prependDay.setDate(prependDay.getDate() - 1);
      if (prependDay.getDay() === Math.abs(firstDayOfWeek + 6) % 7) break;
    }
  }

  const LEN = 42; // 显示6周

  while (daysArr.length < LEN) {
    appendDay.setDate(appendDay.getDate() + 1);
    daysArr.push({
      text: appendDay.getDate().toString(),
      active: false,
      value: new Date(appendDay),
      disabled: disabledDate(appendDay) || outOfRanges(appendDay, minDate, maxDate),
      additional: true // 非当前月
    });
  }

  return chunk(daysArr, 7);
}

export function getYears(
  year,
  {
    disabledDate = () => false,
    minDate,
    maxDate
  } = {}
) {
  const startYear = parseInt(year / 10) * 10;
  const endYear = startYear + 9;

  const yearArr = [];

  const today = getToday();

  for (let i = startYear; i <= endYear; i++) {

    const date = new Date(i, 1);

    let disabledMonth = 0;
    let outOfRangeMonth = 0;

    for (let j = 0; j < 12; j++) {
      const d = new Date(i, j);
      if (disabledDate(d)) disabledMonth += 1;
      if (outOfRanges(d, minDate, maxDate)) outOfRangeMonth += 1;
    }

    yearArr.push({
      value: date,
      now: isSame(date, today, 'year'),
      disabled: disabledMonth === 12 || outOfRangeMonth === 12,
      active: false,
      text: date.getFullYear() + ''
    });
  }

  return chunk(yearArr, 3);
}

export function getMonths(year, { disabledDate = () => false, minDate, maxDate } = {}) {

  const MonthArr = [];

  const today = getToday();

  for (let i = 0; i <= 11; i++) {

    const date = new Date(year, i);

    let disabledDay = 0;
    let outOfRangeDay = 0;

    const daysInMonth = getDaysInMonth({ year, month: i });

    for (let j = 1; j <= daysInMonth; j++) {
      const d = new Date(year, i, j);
      if (disabledDate(d)) disabledDay += 1;
      if (outOfRanges(d, minDate, maxDate)) outOfRangeDay += 1;
    }

    MonthArr.push({
      value: date,
      now: isSame(date, today, 'month'),
      disabled: disabledDay === daysInMonth || outOfRangeDay === daysInMonth,
      active: false,
      text: t(`datePicker.months.${date.getMonth()}`)
    });
  }

  return chunk(MonthArr, 3);
}

export function flagActive(data, { ...args }) {
  const { start, end, type = 'date' } = args;

  if (!end) {
    return data.map(row => row.map(item => {
      item.active = isSame(item.value, start, type);
      return item;
    }));
  }

  return data.map(row => row.map(item => {
    const date = item.value;
    const isStart = isSame(start, date, type);
    const isEnd = isSame(end, date, type);
    item.active = isStart || isEnd;
    item.highlight = isBetween(date, { start, end });
    item.startOfRange = isStart;
    item.endOfRange = isEnd;
    return item;
  }));
}

export function subtractMonth(date, num) {
  const day = date.getDate();
  const newDate = new Date(date);

  // eslint-disable-next-line no-plusplus
  while (num--) {
    newDate.setDate(0);
  }
  newDate.setDate(day);
  return newDate;
}

export function addMonth(date, num) {
  if (num < 0) num = 0;
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + num);
  return newDate;
}

export function isSiblingMonth(start, end) {
  if (!end) return true;
  return isSame(addMonth(start, 1), end, 'month');
}

export function easeout(A, B, rate, callback) {
  if (A === B || typeof A !== 'number') {
    return;
  }
  B = B || 0;
  rate = rate || 2;

  const step = function () {
    A += (B - A) / rate;

    if (Math.abs(A - B) < 1) {
      callback(B, true);
      return;
    }
    callback(A, false);
    requestAnimationFrame(step);
  };
  step();
}

export function getFormatInfo(format) {
  const hourFormat = format.match(/HH|H|hh|h/) || [];
  const minFormat = format.match(/mm|m/) || [];
  const secFormat = format.match(/ss|s/) || [];
  const meridiemFormat = format.match(/a|A/) || [];

  return { hourFormat, minFormat, secFormat, meridiemFormat };
}

export function cloneTime(time1, time2) {
  const { hours, minutes, seconds } = getDateObj(time1);
  const { year, month, date } = getDateObj(time2);
  return new Date(year, month, date, hours, minutes, seconds);
}

export function maxTimeOfDate(d) {
  const { year, month, date } = getDateObj(d);
  return new Date(year, month, date, 23, 59, 59);
}

export function parseToDate(dirtyDate, fmt) {
  let date;
  if (typeof dirtyDate === 'string') {
    date = dateUtil.parse(dirtyDate, fmt);
  } else if (dirtyDate instanceof Date) {
    date = dirtyDate;
  }
  return date;
}

export function compareTime(time1, time2) {
  return compareAsc(time1, cloneTime(time2, time1));
}

export function dateToFormat(date, fmt) {
  return dateUtil.format(date, fmt);
}

export function hour12To24(hour, meridiem) {
  meridiem = meridiem.toUpperCase();
  if (meridiem === 'AM') {
    if (hour === 12) return 0;
    return hour;
  }

  if (hour === 12) return 12;
  return hour + 12;
}

export function hour24To12(hour) {
  if (hour === 0) return 12;
  if (hour >= 1 && hour <= 12) return hour;
  return hour - 12;
}

export function getHours({ hourType, meridiem }, options, selectedValue) {
  const { 
    disabledHour = () => false,
    minH } = options;

  if (hourType === 'h' || hourType === 'hh') {
    return range(11, 0).map(i => {
      const text = i === 0 ? 12 : i;
      const hour24 = hour12To24(text, meridiem);
      return {
        value: text,
        text: padZero(text),
        disabled: disabledHour(hour24) || minH > hour24,
        selected: hour24To12(selectedValue) === text
      };
    });
  }

  // 默认24小时制
  return range(23, 0).map(i => ({ 
    value: i,
    text: padZero(i),
    disabled: disabledHour(i) || minH > i,
    selected: selectedValue === i
  }));
}

export function getMintues(hour, options, selectedValue) {
  const { 
    disabledMin = () => false,
    minH,
    minM } = options;

  return range(59, 0).map(i => ({
    value: i,
    text: padZero(i),
    disabled: disabledMin(hour, i) || (hour === minH && minM > i),
    selected: selectedValue === i
  }));
}

export function getSeconds({ hours, minutes }, options, selectedValue) {
  const { 
    disabledSec = () => false,
    minH,
    minM,
    minS } = options;

  return range(59, 0).map(i => ({
    value: i,
    text: padZero(i),
    disabled: disabledSec(hours, minutes, i) || (hours === minH && minutes === minM && minS > i),
    selected: selectedValue === i
  }));
}

export function getMeridiem(meridiemType, options, selectedValue) {
  const { 
    disabledHour = () => false } = options;

  let amDisabled = true;
  let pmDisabled = true;

  for (let i = 0; i < 12; i++) {
    if (!disabledHour(i)) amDisabled = false;
  }

  for (let i = 12; i < 24; i++) {
    if (!disabledHour(i)) pmDisabled = false;
  }

  if (meridiemType === 'a') {
    return ['am', 'pm'].map(i => ({
      value: i.toUpperCase(),
      text: i,
      selected: i === selectedValue.toLowerCase(),
      disabled: i === 'am' ? amDisabled : pmDisabled 
    }));
  }

  return ['AM', 'PM'].map(i => ({
    value: i,
    text: i,
    selected: i === selectedValue,
    disabled: i === 'AM' ? amDisabled : pmDisabled
  }));
}
