import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const prefixClassName = 'ten-form';

const LAYOUT = {
  INLINE: 'inline'
};

/**
 * @visibleName Form 表单
 */
class Form extends React.Component {
  static propTypes = {
    /**
     * 标签宽度
     */
    labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * 标签对其方式
     */
    labelAlign: PropTypes.oneOf(['right']),
    /**
     * 布局方式
     */
    layout: PropTypes.oneOf(['inline'])
  };

  static defaultProps = {};

  getChildContext() {
    const { labelWidth, labelAlign } = this.props;
    return {
      labelWidth,
      labelAlign
    };
  }

  static childContextTypes = {
    labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelAlign: PropTypes.oneOf(['right'])
  };

  render() {
    const { props } = this;
    const { style, className: propClassName, children, layout, labelWidth, labelAlign, ...other } = props;
    const className = classNames(propClassName, prefixClassName, {
      [`${prefixClassName}--inline`]: layout === LAYOUT.INLINE
    });

    return (
      <form className={className} style={style} {...other}>
        {children}
      </form>
    );
  }
}

export default Form;
