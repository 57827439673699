/**
 * @author chazzhou
 */

import Animate from './animate';
import { addAnimateType } from './animate-types';

Animate.addAnimateType = addAnimateType;

export default Animate;
