import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/**
 * @visibleName Textarea 输入框
 */
class Textarea extends React.Component {
  static propTypes = {
    /**
     * 自适应高度
     */
    autosize: PropTypes.bool,
    /**
     * 字数限制
     */ 
    maxLength: PropTypes.number,
    /**
     * 显示字数限制
     */
    showLimit: PropTypes.bool,
    /**
     * 是否错误状态
     */
    error: PropTypes.bool,
  };

  static defaultProps = {
    error: false,
    autosize: false,
    showLimit: false
  };

  constructor(props) {
    super(props);
    this.refTextareaElem = React.createRef();
    this.state = {
      value: String(props.value || '')
    };
    this.setSizeT;
  }

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return { value: String(nextProps.value || '') };
    }
    return null;
  }

  componentDidUpdate() {
    const { autosize } = this.props;

    if (autosize) {
      const textareaElem = this.refTextareaElem.current;

      if (textareaElem) {
        textareaElem.style.height = 'auto';
        textareaElem.style.height = textareaElem.scrollHeight + 'px';
      }

      clearTimeout(this.setSizeT);
      this.setSizeT = setTimeout(() => {
        textareaElem.style.height = textareaElem.scrollHeight + 'px';
      }, 10);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setSizeT);
  }

  onChange = (e) => {
    const { onChange, maxLength } = this.props;
    if (maxLength && e.target.value.length > maxLength) {
      e.target.value = e.target.value.substr(0, maxLength);
    }
    
    if (!('value' in this.props)) {
      this.setState({
        value: e.target.value,
      });
    }

    onChange && onChange(e);
  }

  render() {
    const { className: propClassName, error, disabled, onChange, showLimit, autosize, ...others } = this.props;
    const { value } = this.state;
    const className = classNames(propClassName, 'ten-input ten-input-textarea', {
      'ten-input--status-error': error,
      'ten-input--disabled': disabled
    });
    const inputClassName = classNames(propClassName, 'ten-input__input', {
      'ten-input__input--status-error': error,
      'ten-input__input--disabled': disabled
    });

    return (
      <div className={className}>
        <div className={inputClassName}>
          <textarea
            ref={this.refTextareaElem}
            className="ten-input__input-input"
            value={value}
            onChange={this.onChange}
            disabled={disabled}
            {...others}
          />
          {
            showLimit && this.props.maxLength &&
            (
              <span className="ten-input-textarea__limit">
                {`${value.length}/${this.props.maxLength}`}
              </span>
            )
          }
        </div>
      </div>
    );
  }
}

export default Textarea;
