import React from 'react';
import classNames from 'classnames';
import Datepanel from './date';
import Time from './time';
import DateRange from './date-range';
import TimeRange from './time-range';
import Button from '../../button';

import { dateToFormat, getToday } from '../util';
import { t } from '../../locale'; 

class DateTime extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showTime: false
    };
  }
 
  onToggleBtn = () => {
    this.setState(state => ({ showTime: !state.showTime }));
  }

  onChangeHandler = (value) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  }

  render() {
    const { showTime } = this.state;

    const { value } = this.props;

    const isRange = Array.isArray(value);
    let DatePanel = Datepanel;
    let TimePanel = Time;

    if (isRange) {
      DatePanel = DateRange;
      TimePanel = TimeRange;
    } 

    const today = getToday();
    
    return (
      <div className={classNames('ten-date-time', {
        'ten-date-time-range': isRange
      })}
      >
        <div className="ten-date-time__time" style={{ display: showTime ? 'block' : 'none' }}>
          <div className="ten-date-time__time-header">
            <div className="ten-date-time__time-header-date">{dateToFormat((isRange ? value[0] : value) || today, t('datePicker.dateTimeFormatter'))}</div>
            { isRange && <div className="ten-date-time__time-header-date">{dateToFormat(value[1] || today, t('datePicker.dateTimeFormatter'))}</div> }
          </div>
          <TimePanel 
            {...this.props}  
            onChange={this.onChangeHandler}
            isLinkage={isRange ? false : null}
          />
        </div>
        <div className="ten-date-time__date" style={{ display: showTime ? 'none' : 'block' }}>
          <DatePanel 
            {...this.props}
            mode="date"
            onChange={this.onChangeHandler} 
          />
        </div>
        <div className="ten-date-time__footer">
          <Button className="ten-date-time__footer-btn" type="text" onClick={this.onToggleBtn}>{showTime ? t('datePicker.selectDate') : t('datePicker.selectTime') }</Button>
        </div>
      </div>
    );
  }
}

export default DateTime;
