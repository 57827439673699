// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement, forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M8 15A7 7 0 108 1a7 7 0 000 14zM7.5 5h1v2.97l2.85 2.86-.7.7L7.5 8.4V5z","fillOpacity":0.9}}]};

const TimeFilledIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'time-filled',
    ref,
    icon: element,
  },
));

TimeFilledIcon.displayName = 'TimeFilledIcon';

export default TimeFilledIcon;
