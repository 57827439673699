import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import domHelper from '../utils/dom-helper';
import Animate from '../animate';

function getDirMatch(direction) {
  /* eslint-disable */
  let dirMatch = direction.match(/((top|bottom|center|left|right)[\s-]*)/gi);
  dirMatch = !dirMatch ? ['top', 'center'] : dirMatch.map(dir => dir.replace(/[\s-]/g, ''));
  /* eslint-enable */

  if (dirMatch.length === 1) {
    dirMatch[1] = 'center';
  }

  if (
    (dirMatch[0] === 'left' && dirMatch[1] === 'right') ||
    (dirMatch[0] === 'right' && dirMatch[1] === 'left') ||
    (dirMatch[0] === 'top' && dirMatch[1] === 'bottom') ||
    (dirMatch[0] === 'bottom' && dirMatch[1] === 'top')
  ) {
    dirMatch[1] = 'center';
  }

  if (dirMatch[0] === 'center') {
    if (dirMatch[1] === 'center') {
      dirMatch = ['top', 'center'];
    }
    dirMatch.reverse();
  } else if (dirMatch[0] === dirMatch[1]) {
    dirMatch[1] = 'center';
  }
  dirMatch[0] = dirMatch[0].trim();
  dirMatch[1] = dirMatch[1].trim();
  return dirMatch;
}

/**
 * @visibleName Popup 悬浮框
 */
class Popup extends React.Component {

  static propTypes = {
    /**
     * 弹窗方向
     */
    direction: PropTypes.string,
    type: PropTypes.oneOf(['default', 'bubble']),
    /**
     * 触发方式
     */
    triggerType: PropTypes.oneOf(['hover', 'click', 'manual']),
    /**
     * 弹出延迟 ms
     */
    delay: PropTypes.number,
    /**
     * 弹窗内容
     */
    content: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]).isRequired,
    /**
     * 手动控制显示
     */
    show: PropTypes.bool,
    /**
     * 显示回调
     */
    onShow: PropTypes.func,
    /**
     * 隐藏回调
     */
    onHide: PropTypes.func,
    /**
     * 弹窗显示时，点击弹窗之外区域的回调
     */
    onOutsideClick: PropTypes.func,
    /**
     * 获取弹窗内容宽度
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    /**
     * @ignore
     */
    padding: PropTypes.number
  };

  static defaultProps = {
    type: 'default',
    triggerType: 'hover',
    direction: 'top center',
    padding: 0
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if ('show' in nextProps && nextProps.show !== prevState.show) {
      return { show: nextProps.show };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.globalEventBinded = false;
    this.delayT = 0;
    this.dirMatch = getDirMatch(props.direction); // cal from direction
    this.popupContainer = null;
    this.refElemPopup = React.createRef();

    this.state = {
      show: props.show || false
    };

    this.debouceSetPopupPostion = debounce(this.setPopupPosition, 100);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  update() {
    const { show } = this.state;
    const { direction } = this.props;

    if (show) {
      try {
        const event = new CustomEvent('popupshow', {
          bubbles: true,
        });

        setTimeout(() => {
          this.popupContainer.dispatchEvent(event);
        }, 10);
      } catch (e) {
        console.error(e); 
      }
      this.bindGlobalEvent();
      setTimeout(this.setPopupPosition);
      // this.setPopupPosition();
      this.dirMatch = getDirMatch(direction);
    } else {
      this.unbindGlobalEvent();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayT);
    this.unbindGlobalEvent();
    this.removeContainer();
  }

  bindGlobalEvent() {
    if (!this.globalEventBinded) {
      this.globalEventBinded = true;
      window.addEventListener('resize', this.debouceSetPopupPostion);
      document.addEventListener('scroll', this.debouceSetPopupPostion, true);
      document.addEventListener('click', this.onOutsideClick, true);
    }
  }

  unbindGlobalEvent() {
    window.removeEventListener('resize', this.debouceSetPopupPostion);
    document.removeEventListener('scroll', this.debouceSetPopupPostion, true);
    document.removeEventListener('click', this.onOutsideClick, true);
    this.globalEventBinded = false;
  }

  createContainer = () => {
    if (!this.popupContainer) {
      this.popupContainer = document.createElement('div');
      document.body.appendChild(this.popupContainer);
    }
  }

  removeContainer = () => {
    if (this.popupContainer) {
      document.body.removeChild(this.popupContainer);
      this.popupContainer = null;
    }
  }

  setVisible(show) {
    this.setState({
      show
    });
  }

  showPopup = (delay = 0) => {
    if ('show' in this.props) {
      this.props.onShow && this.props.onShow();
      return;
    }
    clearTimeout(this.delayT);
    if (delay === 0) {
      this.setVisible(true);
    } else {
      this.delayT = setTimeout(() => {
        this.setVisible(true);
      }, delay);
    }
  }

  hidePopup = (delay = 50) => {
    if ('show' in this.props) {
      this.props.onHide && this.props.onHide();
      return;
    }
    clearTimeout(this.delayT);
    this.delayT = setTimeout(() => {
      this.setVisible(false);
    }, delay);
  }

  setPopupPosition = () => {
    const padding = this.props.type === 'bubble' ? 16 : 0;
    const { show } = this.state;
    const dirMatch = [...this.dirMatch];
    const childElem = ReactDOM.findDOMNode(this);
    const popupElem = this.refElemPopup.current;

    if (!show || !childElem || !popupElem) {
      return;
    }

    const winHeight = window.innerHeight;
    const winWidth = window.innerWidth;
    if ('width' in this.props) {
      const { width } = this.props;
      let w;
      if (typeof width === 'function') {
        w = width(childElem);
      } else {
        w = width;
      }
      popupElem.style.width = w + (padding * 2) + 'px';
    }
    const childElemRect = childElem.getBoundingClientRect();
    const popupElemRect = popupElem.getBoundingClientRect();
    const popupStyle = {};

    const popupElemRectWidth = popupElemRect.width;

    // If there is not enough space in the specified direction and opposite direction is enough, set it to the opposite direction
    const isNotEnoughSpaceTop = popupElemRect.height > childElemRect.top;
    const isNotEnoughSpaceBottom = popupElemRect.height > winHeight - childElemRect.top - childElemRect.height;
    const isNotEnoughSpaceLeft = popupElemRectWidth > childElemRect.left;
    const isNotEnoughSpaceRight = popupElemRectWidth > winWidth - childElemRect.left - childElemRect.width;
    if (dirMatch[0] === 'top' && isNotEnoughSpaceTop && !isNotEnoughSpaceBottom) {
      dirMatch[0] = 'bottom';
    } else if (dirMatch[0] === 'bottom' && isNotEnoughSpaceBottom && !isNotEnoughSpaceTop) {
      dirMatch[0] = 'top';
    }
    if (dirMatch[0] === 'left' && isNotEnoughSpaceLeft && !isNotEnoughSpaceRight) {
      dirMatch[0] = 'right';
    } else if (dirMatch[0] === 'right' && isNotEnoughSpaceRight && !isNotEnoughSpaceLeft) {
      dirMatch[0] = 'left';
    }

    if (dirMatch[1] === 'top' && popupElemRect.height > winHeight - childElemRect.top) {
      dirMatch[1] = 'bottom';
    } else if (dirMatch[1] === 'bottom' && popupElemRect.height > childElemRect.top + childElemRect.height) {
      dirMatch[1] = 'top';
    }
    if (dirMatch[1] === 'left' && popupElemRectWidth > winWidth - childElemRect.left) {
      dirMatch[1] = 'right';
    } else if (dirMatch[1] === 'right' && popupElemRectWidth > childElemRect.left + childElemRect.width) {
      dirMatch[1] = 'left';
    }
    if (dirMatch[0] === 'bottom' || dirMatch[0] === 'top') {
      if (dirMatch[1] === 'center' && popupElemRectWidth) {
        const leftSpaceRemain = childElemRect.left + (childElemRect.width / 2);
        const rightSpaceRemain = winWidth - leftSpaceRemain;
        if (popupElemRectWidth / 2 > leftSpaceRemain && popupElemRectWidth / 2 < rightSpaceRemain) {
          dirMatch[1] = 'left';
        } else if (popupElemRectWidth / 2 > rightSpaceRemain && popupElemRectWidth / 2 < leftSpaceRemain) {
          dirMatch[1] = 'right';
        }
      }
    }

    // style
    if (dirMatch[0] === 'top') {
      popupStyle.top = childElemRect.top - popupElemRect.height;
    } else if (dirMatch[0] === 'bottom') {
      popupStyle.top = childElemRect.top + childElemRect.height;
    } else if (dirMatch[0] === 'left') {
      popupStyle.left = childElemRect.left - popupElemRectWidth;
    } else if (dirMatch[0] === 'right') {
      popupStyle.left = childElemRect.left + childElemRect.width;
    }

    if (dirMatch[1] === 'top') {
      popupStyle.top = childElemRect.top - padding;
    } else if (dirMatch[1] === 'bottom') {
      popupStyle.top = childElemRect.top + childElemRect.height - popupElemRect.height + padding;
    } else if (dirMatch[1] === 'left') {
      popupStyle.left = childElemRect.left - padding;
    } else if (dirMatch[1] === 'right') {
      popupStyle.left = childElemRect.left + childElemRect.width - popupElemRectWidth + padding;
    } else {
      /* eslint-disable */
      // center
      if (dirMatch[0] === 'top' || dirMatch[0] === 'bottom') {
        popupStyle.left = childElemRect.left - (popupElemRectWidth - childElemRect.width) / 2;
      } else {
        popupStyle.top = childElemRect.top - (popupElemRect.height - childElemRect.height) / 2;

      }
      /* eslint-enable */
    }

    if (dirMatch[0] === 'top') {
      popupStyle.top = childElemRect.top - popupElemRect.height;
    } else if (dirMatch[0] === 'bottom') {
      popupStyle.top = childElemRect.top + childElemRect.height;
    } else if (dirMatch[0] === 'center') {
      popupStyle.top = childElemRect.top - ((popupElemRect.height - childElemRect.height) / 2);
    }

    popupStyle.top += window.scrollY || window.pageYOffset;
    popupStyle.left += window.scrollX || window.pageXOffset;

    domHelper.css(popupElem, popupStyle);

    // class for style arrow
    popupElem.className = popupElem.className.replace(/ten-popup--p-[\w-]+/g, '').replace(/\s+/g, ' ');

    popupElem.classList.add(`ten-popup--p-${dirMatch[0]}-${dirMatch[1]}`);
  }

  onOutsideClick = (e) => {
    const { onOutsideClick } = this.props;
    const { show } = this.state;
    const childElem = ReactDOM.findDOMNode(this);
    if (
      !show ||
      domHelper.contains(this.refElemPopup.current, e.target) || domHelper.contains(childElem, e.target)
    ) {
      return;
    }
    onOutsideClick && onOutsideClick(e);
    this.hidePopup(e);
  }

  onMouseEnter = (e) => {
    const { children, delay: propDelay } = this.props;
    const delay = this.refElemPopup.current && domHelper.contains(this.refElemPopup.current, e.target) ? 0 : propDelay;
    this.showPopup(delay);
    children.props.onMouseEnter && children.props.onMouseEnter(e);
  }

  onMouseLeave = (e) => {
    const { children } = this.props;
    this.hidePopup();
    children.props.onMouseLeave && children.props.onMouseLeave(e);
  }

  onClick = (e) => {
    const { children, delay } = this.props;
    this.showPopup(delay);
    children.props.onClick && children.props.onClick(e);
  }

  renderPopup() {
    const { type, content, className: propClassName, triggerType, dark, show, width, onShow, onHide, onOutsideClick, ...others } = this.props;
    const event = {};
    const classes = classNames('ten-popup', `ten-popup--type-${type}`, propClassName, {
      'ten-popup--dark': dark
    });

    delete others.direction;
    delete others.triggerType;
    delete others.delay;
    delete others.content;

    if (triggerType === 'hover') {
      event.onMouseEnter = this.onMouseEnter;
      event.onMouseLeave = this.onMouseLeave;
    }

    const popup = (
      <div
        style={{ top: -500 }}
        className={classes}
        ref={this.refElemPopup}
        {...event}
        {...others}
      >
        {typeof content === 'function' ? content() : content}
      </div>
    );

    this.createContainer();
    return (
      ReactDOM.createPortal(popup, this.popupContainer)
    );
  }

  render() {
    const { children, triggerType } = this.props;
    const { show } = this.state;
    const childProps = {};

    if (triggerType === 'hover') {
      childProps.onMouseEnter = this.onMouseEnter;
      childProps.onMouseLeave = this.onMouseLeave;
    } else if (triggerType === 'click') {
      childProps.onClick = this.onClick;
    }
    // console.log('popup render', popup);
    return (
      <React.Fragment>
        {
          React.cloneElement(React.Children.only(children), childProps)
        }
        <Animate
          type={{
            name: 'class',
            params: {
              classes: 'ten-flow-in__popup'
            }
          }}
          onExited={this.removeContainer}
          appear
        >
          {
            show && this.renderPopup()
          }
        </Animate>
      </React.Fragment>
    );
  }
}

export default Popup;
