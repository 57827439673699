/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon';
import Tooltip from '../tooltip';

const prefixClassName = 'ten-form-item';
const STATUS = {
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success',
  WARNING: 'warning'
};

/**
 * @visibleName Form Item
 */
class Item extends React.Component {
  static propTypes = {
    /**
     * 标签名
     */
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node
    ]),
    /**
     * 标签状态
     */
    status: PropTypes.oneOf(['error', 'loading', 'success', 'warning', '']),
    /**
     * 错误信息
     */
    error: PropTypes.string,
    /**
     * 必填提示
     */
    required: PropTypes.bool,
    /**
     * 是否显示状态icon
     */
    statusIcon: PropTypes.bool,
    /**
     * 提示信息
     */
    tip: PropTypes.string
  };

  static defaultProps = {
    statusIcon: true
  };

  static contextTypes = {
    labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelAlign: PropTypes.string
  };

  getIcon = () => {
    const { status } = this.props;
    switch (status) {
      case STATUS.ERROR:
        return <Icon type="circle_clear" />;
      case STATUS.SUCCESS:
        return <Icon type="circle_tick" />;
      case STATUS.WARNING:
        return <Icon type="circle_error" />;
      case STATUS.LOADING:
        return <Icon type="loading_gradient" />;
      default:
        return null;
    }
  };

  render() {
    const { props, context } = this;
    const {
      style,
      className: propClassName,
      label,
      children,
      required,
      status,
      error,
      statusIcon,
      tip
    } = props;
    const { labelWidth, labelAlign } = context;

    const className = classNames(propClassName, prefixClassName, {
      [`${prefixClassName}--required`]: required,
      [`${prefixClassName}--error`]: status === STATUS.ERROR || error,
      [`${prefixClassName}--loading`]: status === STATUS.LOADING,
      [`${prefixClassName}--success`]: status === STATUS.SUCCESS,
      [`${prefixClassName}--warning`]: status === STATUS.WARNING,
      [`${prefixClassName}--icon`]: statusIcon && (tip || status || error)
    });

    const labelStyles = {
      ...(labelWidth && { width: labelWidth }),
      ...(labelAlign && { textAlign: labelAlign })
    };

    return (
      <div className={className} style={style}>
        {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
        {label && (
          <label className={`${prefixClassName}-label`} style={labelStyles}>
            {
              typeof label === 'function' ? label() : label
            }
          </label>
        )}

        <div className={`${prefixClassName}-control`}>
          {children}
          <div className={`${prefixClassName}-err`}>{error}</div>
        </div>
        <div className={`${prefixClassName}-icon`}>
          {tip && !status && (
            <div className={`${prefixClassName}-tip`}>
              <Tooltip message={tip}>
                <Icon type="line_circle_help" />
              </Tooltip>
            </div>
          )}
          {this.getIcon(status)}
        </div>
      </div>
    );
  }
}

export default Item;
