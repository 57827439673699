import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Step from './step';


/**
 * @visibleName Steps 步骤条
 */
class Steps extends React.Component {

  static propTypes = {
    /** 
     *  当前进度
    */
    current: PropTypes.number.isRequired,
    /** 
     * 指定当前步骤状态
    */
    status: PropTypes.oneOf(['process', 'wait', 'finish', 'error']),
    /** 
     * 方向
    */
    direction: PropTypes.oneOf(['vertical', 'horizontal']),
    /** 
     * 垂直方向是正序还是倒序
    */
    forward: PropTypes.bool,
  };

  static defaultProps = {
    direction: 'horizontal',
    status: 'process',
    forward: true,
  };

  static Step = Step

  render() {
    const { children, ...restProps } = this.props;
    const { direction, current, status, forward } = restProps;
    const len = children.length;
    const statusClassNames = Array(len).fill('wait');
    let nextClassNames = [];
   
    children.forEach((child, index) => {
      let s = 'wait';
      if (index < current) {
        s = 'finish';
      } else if (index === current) {
        s = status;
      }
      if (child.props.skip) {
        s = 'skip';
      }
      statusClassNames[index] = s;
    });
    
    const modeName = direction === 'vertical' ? 'vertical' : 'horizontal';
    const n = React.Children.toArray(children);
    nextClassNames = statusClassNames.slice(1);
    if (!forward) {
      n.reverse();
      statusClassNames.reverse();
      nextClassNames.reverse();
    }
   
    const newChildren = React.Children.map(n, (child, index) => {
      const statusClassName = statusClassNames[index];
      const nextClassName = nextClassNames[index];
      return React.cloneElement(child, { ...restProps, index, len, statusClassName, nextClassName, modeName, forward });
    });

    const className = classNames(
      'ten-steps',
      `ten-steps--${modeName}`,
      {
        'ten-steps--reverse': !forward,
      },
    );

    return (
      <div className={className}>
        {newChildren}
      </div>
    );
  }
}

export default Steps;
