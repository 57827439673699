import React from 'react';
import TreeNode from './tree-node';

function renderChildren(nodeModels) {
  return nodeModels.map(nodeModel => (
    <TreeNode 
      nodeModel={nodeModel} 
      key={nodeModel.key} 
      nodeKey={nodeModel.key}
      selected={nodeModel.selected}
      expanded={nodeModel.expanded}
      loading={nodeModel.loading}
      renderChildren={renderChildren}
    />
  ));
}

export default renderChildren;
